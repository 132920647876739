const cityData = [
    {
        fid: 1,
        children: [{fid: 2, name: "北京", id: 52, type: 2}],
        name: "北京",
        id: 2,
        type: 1
    }, {
        fid: 1,
        children: [
            {fid: 3, name: "安庆", id: 36, type: 2}, {fid: 3, name: "蚌埠", id: 37, type: 2}, {fid: 3, name: "巢湖", id: 38, type: 2}, {
            fid: 3,
            name: "池州",
            id: 39,
            type: 2
        }, {fid: 3, name: "滁州", id: 40, type: 2}, {fid: 3, name: "阜阳", id: 41, type: 2}, {fid: 3, name: "淮北", id: 42, type: 2}, {
            fid: 3,
            name: "淮南",
            id: 43,
            type: 2
        }, {fid: 3, name: "黄山", id: 44, type: 2}, {fid: 3, name: "六安", id: 45, type: 2}, {fid: 3, name: "马鞍山", id: 46, type: 2}, {
            fid: 3,
            name: "宿州",
            id: 47,
            type: 2
        }, {fid: 3, name: "铜陵", id: 48, type: 2}, {fid: 3, name: "芜湖", id: 49, type: 2}, {fid: 3, name: "宣城", id: 50, type: 2}, {
            fid: 3,
            name: "亳州",
            id: 51,
            type: 2
        }, {fid: 3, name: "合肥", id: 3401, type: 2}
        ],
        name: "安徽",
        id: 3,
        type: 1
}, {
    fid: 1,
    children: [{fid: 4, name: "福州", id: 53, type: 2}, {fid: 4, name: "龙岩", id: 54, type: 2}, {fid: 4, name: "南平", id: 55, type: 2}, {
        fid: 4,
        name: "宁德",
        id: 56,
        type: 2
    }, {fid: 4, name: "莆田", id: 57, type: 2}, {fid: 4, name: "泉州", id: 58, type: 2}, {fid: 4, name: "三明", id: 59, type: 2}, {
        fid: 4,
        name: "厦门",
        id: 60,
        type: 2
    }, {fid: 4, name: "漳州", id: 61, type: 2}],
    name: "福建",
    id: 4,
    type: 1
}, {
    fid: 1,
    children: [{fid: 5, name: "兰州", id: 62, type: 2}, {fid: 5, name: "白银", id: 63, type: 2}, {fid: 5, name: "定西", id: 64, type: 2}, {
        fid: 5,
        name: "甘南",
        id: 65,
        type: 2
    }, {fid: 5, name: "嘉峪关", id: 66, type: 2}, {fid: 5, name: "金昌", id: 67, type: 2}, {fid: 5, name: "酒泉", id: 68, type: 2}, {
        fid: 5,
        name: "临夏",
        id: 69,
        type: 2
    }, {fid: 5, name: "陇南", id: 70, type: 2}, {fid: 5, name: "平凉", id: 71, type: 2}, {fid: 5, name: "庆阳", id: 72, type: 2}, {
        fid: 5,
        name: "天水",
        id: 73,
        type: 2
    }, {fid: 5, name: "武威", id: 74, type: 2}, {fid: 5, name: "张掖", id: 75, type: 2}],
    name: "甘肃",
    id: 5,
    type: 1
}, {
    fid: 1,
    children: [{fid: 6, name: "广州", id: 76, type: 2}, {fid: 6, name: "深圳", id: 77, type: 2}, {fid: 6, name: "潮州", id: 78, type: 2}, {
        fid: 6,
        name: "东莞",
        id: 79,
        type: 2
    }, {fid: 6, name: "佛山", id: 80, type: 2}, {fid: 6, name: "河源", id: 81, type: 2}, {fid: 6, name: "惠州", id: 82, type: 2}, {
        fid: 6,
        name: "江门",
        id: 83,
        type: 2
    }, {fid: 6, name: "揭阳", id: 84, type: 2}, {fid: 6, name: "茂名", id: 85, type: 2}, {fid: 6, name: "梅州", id: 86, type: 2}, {
        fid: 6,
        name: "清远",
        id: 87,
        type: 2
    }, {fid: 6, name: "汕头", id: 88, type: 2}, {fid: 6, name: "汕尾", id: 89, type: 2}, {fid: 6, name: "韶关", id: 90, type: 2}, {
        fid: 6,
        name: "阳江",
        id: 91,
        type: 2
    }, {fid: 6, name: "云浮", id: 92, type: 2}, {fid: 6, name: "湛江", id: 93, type: 2}, {fid: 6, name: "肇庆", id: 94, type: 2}, {
        fid: 6,
        name: "中山",
        id: 95,
        type: 2
    }, {fid: 6, name: "珠海", id: 96, type: 2}],
    name: "广东",
    id: 6,
    type: 1
}, {
    fid: 1,
    children: [{fid: 7, name: "南宁", id: 97, type: 2}, {fid: 7, name: "桂林", id: 98, type: 2}, {fid: 7, name: "百色", id: 99, type: 2}, {
        fid: 7,
        name: "北海",
        id: 100,
        type: 2
    }, {fid: 7, name: "崇左", id: 101, type: 2}, {fid: 7, name: "防城港", id: 102, type: 2}, {fid: 7, name: "贵港", id: 103, type: 2}, {
        fid: 7,
        name: "河池",
        id: 104,
        type: 2
    }, {fid: 7, name: "贺州", id: 105, type: 2}, {fid: 7, name: "来宾", id: 106, type: 2}, {fid: 7, name: "柳州", id: 107, type: 2}, {
        fid: 7,
        name: "钦州",
        id: 108,
        type: 2
    }, {fid: 7, name: "梧州", id: 109, type: 2}, {fid: 7, name: "玉林", id: 110, type: 2}],
    name: "广西",
    id: 7,
    type: 1
}, {
    fid: 1,
    children: [{fid: 8, name: "贵阳", id: 111, type: 2}, {fid: 8, name: "安顺", id: 112, type: 2}, {fid: 8, name: "毕节", id: 113, type: 2}, {
        fid: 8,
        name: "六盘水",
        id: 114,
        type: 2
    }, {fid: 8, name: "黔东南", id: 115, type: 2}, {fid: 8, name: "黔南", id: 116, type: 2}, {fid: 8, name: "黔西南", id: 117, type: 2}, {
        fid: 8,
        name: "铜仁",
        id: 118,
        type: 2
    }, {fid: 8, name: "遵义", id: 119, type: 2}],
    name: "贵州",
    id: 8,
    type: 1
}, {
    fid: 1,
    children: [{fid: 9, name: "海口", id: 120, type: 2}, {fid: 9, name: "三亚", id: 121, type: 2}, {fid: 9, name: "白沙", id: 122, type: 2}, {
        fid: 9,
        name: "保亭",
        id: 123,
        type: 2
    }, {fid: 9, name: "昌江", id: 124, type: 2}, {fid: 9, name: "澄迈县", id: 125, type: 2}, {fid: 9, name: "定安县", id: 126, type: 2}, {
        fid: 9,
        name: "东方",
        id: 127,
        type: 2
    }, {fid: 9, name: "乐东", id: 128, type: 2}, {fid: 9, name: "临高县", id: 129, type: 2}, {fid: 9, name: "陵水", id: 130, type: 2}, {
        fid: 9,
        name: "琼海",
        id: 131,
        type: 2
    }, {fid: 9, name: "琼中", id: 132, type: 2}, {fid: 9, name: "屯昌县", id: 133, type: 2}, {fid: 9, name: "万宁", id: 134, type: 2}, {
        fid: 9,
        name: "文昌",
        id: 135,
        type: 2
    }, {fid: 9, name: "五指山", id: 136, type: 2}, {fid: 9, name: "儋州", id: 137, type: 2}],
    name: "海南",
    id: 9,
    type: 1
}, {
    fid: 1,
    children: [{fid: 10, name: "石家庄", id: 138, type: 2}, {fid: 10, name: "保定", id: 139, type: 2}, {fid: 10, name: "沧州", id: 140, type: 2}, {
        fid: 10,
        name: "承德",
        id: 141,
        type: 2
    }, {fid: 10, name: "邯郸", id: 142, type: 2}, {fid: 10, name: "衡水", id: 143, type: 2}, {fid: 10, name: "廊坊", id: 144, type: 2}, {
        fid: 10,
        name: "秦皇岛",
        id: 145,
        type: 2
    }, {fid: 10, name: "唐山", id: 146, type: 2}, {fid: 10, name: "邢台", id: 147, type: 2}, {fid: 10, name: "张家口", id: 148, type: 2}],
    name: "河北",
    id: 10,
    type: 1
}, {
    fid: 1,
    children: [{fid: 11, name: "郑州", id: 149, type: 2}, {fid: 11, name: "洛阳", id: 150, type: 2}, {fid: 11, name: "开封", id: 151, type: 2}, {
        fid: 11,
        name: "安阳",
        id: 152,
        type: 2
    }, {fid: 11, name: "鹤壁", id: 153, type: 2}, {fid: 11, name: "济源", id: 154, type: 2}, {fid: 11, name: "焦作", id: 155, type: 2}, {
        fid: 11,
        name: "南阳",
        id: 156,
        type: 2
    }, {fid: 11, name: "平顶山", id: 157, type: 2}, {fid: 11, name: "三门峡", id: 158, type: 2}, {fid: 11, name: "商丘", id: 159, type: 2}, {
        fid: 11,
        name: "新乡",
        id: 160,
        type: 2
    }, {fid: 11, name: "信阳", id: 161, type: 2}, {fid: 11, name: "许昌", id: 162, type: 2}, {fid: 11, name: "周口", id: 163, type: 2}, {
        fid: 11,
        name: "驻马店",
        id: 164,
        type: 2
    }, {fid: 11, name: "漯河", id: 165, type: 2}, {fid: 11, name: "濮阳", id: 166, type: 2}],
    name: "河南",
    id: 11,
    type: 1
}, {
    fid: 1,
    children: [{fid: 12, name: "哈尔滨", id: 167, type: 2}, {fid: 12, name: "大庆", id: 168, type: 2}, {fid: 12, name: "大兴安岭", id: 169, type: 2}, {
        fid: 12,
        name: "鹤岗",
        id: 170,
        type: 2
    }, {fid: 12, name: "黑河", id: 171, type: 2}, {fid: 12, name: "鸡西", id: 172, type: 2}, {fid: 12, name: "佳木斯", id: 173, type: 2}, {
        fid: 12,
        name: "牡丹江",
        id: 174,
        type: 2
    }, {fid: 12, name: "七台河", id: 175, type: 2}, {fid: 12, name: "齐齐哈尔", id: 176, type: 2}, {fid: 12, name: "双鸭山", id: 177, type: 2}, {
        fid: 12,
        name: "绥化",
        id: 178,
        type: 2
    }, {fid: 12, name: "伊春", id: 179, type: 2}],
    name: "黑龙江",
    id: 12,
    type: 1
}, {
    fid: 1,
    children: [{fid: 13, name: "武汉", id: 180, type: 2}, {fid: 13, name: "仙桃", id: 181, type: 2}, {fid: 13, name: "鄂州", id: 182, type: 2}, {
        fid: 13,
        name: "黄冈",
        id: 183,
        type: 2
    }, {fid: 13, name: "黄石", id: 184, type: 2}, {fid: 13, name: "荆门", id: 185, type: 2}, {fid: 13, name: "荆州", id: 186, type: 2}, {
        fid: 13,
        name: "潜江",
        id: 187,
        type: 2
    }, {fid: 13, name: "神农架林区", id: 188, type: 2}, {fid: 13, name: "十堰", id: 189, type: 2}, {fid: 13, name: "随州", id: 190, type: 2}, {
        fid: 13,
        name: "天门",
        id: 191,
        type: 2
    }, {fid: 13, name: "咸宁", id: 192, type: 2}, {fid: 13, name: "襄樊", id: 193, type: 2}, {fid: 13, name: "孝感", id: 194, type: 2}, {
        fid: 13,
        name: "宜昌",
        id: 195,
        type: 2
    }, {fid: 13, name: "恩施", id: 196, type: 2}],
    name: "湖北",
    id: 13,
    type: 1
}, {
    fid: 1,
    children: [{fid: 14, name: "长沙", id: 197, type: 2}, {fid: 14, name: "张家界", id: 198, type: 2}, {fid: 14, name: "常德", id: 199, type: 2}, {
        fid: 14,
        name: "郴州",
        id: 200,
        type: 2
    }, {fid: 14, name: "衡阳", id: 201, type: 2}, {fid: 14, name: "怀化", id: 202, type: 2}, {fid: 14, name: "娄底", id: 203, type: 2}, {
        fid: 14,
        name: "邵阳",
        id: 204,
        type: 2
    }, {fid: 14, name: "湘潭", id: 205, type: 2}, {fid: 14, name: "湘西", id: 206, type: 2}, {fid: 14, name: "益阳", id: 207, type: 2}, {
        fid: 14,
        name: "永州",
        id: 208,
        type: 2
    }, {fid: 14, name: "岳阳", id: 209, type: 2}, {fid: 14, name: "株洲", id: 210, type: 2}],
    name: "湖南",
    id: 14,
    type: 1
}, {
    fid: 1,
    children: [{fid: 15, name: "长春", id: 211, type: 2}, {fid: 15, name: "吉林", id: 212, type: 2}, {fid: 15, name: "白城", id: 213, type: 2}, {
        fid: 15,
        name: "白山",
        id: 214,
        type: 2
    }, {fid: 15, name: "辽源", id: 215, type: 2}, {fid: 15, name: "四平", id: 216, type: 2}, {fid: 15, name: "松原", id: 217, type: 2}, {
        fid: 15,
        name: "通化",
        id: 218,
        type: 2
    }, {fid: 15, name: "延边", id: 219, type: 2}],
    name: "吉林",
    id: 15,
    type: 1
}, {
    fid: 1,
    children: [{fid: 16, name: "南京", id: 220, type: 2}, {fid: 16, name: "苏州", id: 221, type: 2}, {fid: 16, name: "无锡", id: 222, type: 2}, {
        fid: 16,
        name: "常州",
        id: 223,
        type: 2
    }, {fid: 16, name: "淮安", id: 224, type: 2}, {fid: 16, name: "连云港", id: 225, type: 2}, {fid: 16, name: "南通", id: 226, type: 2}, {
        fid: 16,
        name: "宿迁",
        id: 227,
        type: 2
    }, {fid: 16, name: "泰州", id: 228, type: 2}, {fid: 16, name: "徐州", id: 229, type: 2}, {fid: 16, name: "盐城", id: 230, type: 2}, {
        fid: 16,
        name: "扬州",
        id: 231,
        type: 2
    }, {fid: 16, name: "镇江", id: 232, type: 2}],
    name: "江苏",
    id: 16,
    type: 1
}, {
    fid: 1,
    children: [{fid: 17, name: "南昌", id: 233, type: 2}, {fid: 17, name: "抚州", id: 234, type: 2}, {fid: 17, name: "赣州", id: 235, type: 2}, {
        fid: 17,
        name: "吉安",
        id: 236,
        type: 2
    }, {fid: 17, name: "景德镇", id: 237, type: 2}, {fid: 17, name: "九江", id: 238, type: 2}, {fid: 17, name: "萍乡", id: 239, type: 2}, {
        fid: 17,
        name: "上饶",
        id: 240,
        type: 2
    }, {fid: 17, name: "新余", id: 241, type: 2}, {fid: 17, name: "宜春", id: 242, type: 2}, {fid: 17, name: "鹰潭", id: 243, type: 2}],
    name: "江西",
    id: 17,
    type: 1
}, {
    fid: 1,
    children: [{fid: 18, name: "沈阳", id: 244, type: 2}, {fid: 18, name: "大连", id: 245, type: 2}, {fid: 18, name: "鞍山", id: 246, type: 2}, {
        fid: 18,
        name: "本溪",
        id: 247,
        type: 2
    }, {fid: 18, name: "朝阳", id: 248, type: 2}, {fid: 18, name: "丹东", id: 249, type: 2}, {fid: 18, name: "抚顺", id: 250, type: 2}, {
        fid: 18,
        name: "阜新",
        id: 251,
        type: 2
    }, {fid: 18, name: "葫芦岛", id: 252, type: 2}, {fid: 18, name: "锦州", id: 253, type: 2}, {fid: 18, name: "辽阳", id: 254, type: 2}, {
        fid: 18,
        name: "盘锦",
        id: 255,
        type: 2
    }, {fid: 18, name: "铁岭", id: 256, type: 2}, {fid: 18, name: "营口", id: 257, type: 2}],
    name: "辽宁",
    id: 18,
    type: 1
}, {
    fid: 1,
    children: [{fid: 19, name: "呼和浩特", id: 258, type: 2}, {fid: 19, name: "阿拉善盟", id: 259, type: 2}, {fid: 19, name: "巴彦淖尔盟", id: 260, type: 2}, {
        fid: 19,
        name: "包头",
        id: 261,
        type: 2
    }, {fid: 19, name: "赤峰", id: 262, type: 2}, {fid: 19, name: "鄂尔多斯", id: 263, type: 2}, {fid: 19, name: "呼伦贝尔", id: 264, type: 2}, {
        fid: 19,
        name: "通辽",
        id: 265,
        type: 2
    }, {fid: 19, name: "乌海", id: 266, type: 2}, {fid: 19, name: "乌兰察布市", id: 267, type: 2}, {fid: 19, name: "锡林郭勒盟", id: 268, type: 2}, {
        fid: 19,
        name: "兴安盟",
        id: 269,
        type: 2
    }],
    name: "内蒙古",
    id: 19,
    type: 1
}, {
    fid: 1,
    children: [{fid: 20, name: "银川", id: 270, type: 2}, {fid: 20, name: "固原", id: 271, type: 2}, {fid: 20, name: "石嘴山", id: 272, type: 2}, {
        fid: 20,
        name: "吴忠",
        id: 273,
        type: 2
    }, {fid: 20, name: "中卫", id: 274, type: 2}],
    name: "宁夏",
    id: 20,
    type: 1
}, {
    fid: 1,
    children: [{fid: 21, name: "西宁", id: 275, type: 2}, {fid: 21, name: "果洛", id: 276, type: 2}, {fid: 21, name: "海北", id: 277, type: 2}, {
        fid: 21,
        name: "海东",
        id: 278,
        type: 2
    }, {fid: 21, name: "海南", id: 279, type: 2}, {fid: 21, name: "海西", id: 280, type: 2}, {fid: 21, name: "黄南", id: 281, type: 2}, {
        fid: 21,
        name: "玉树",
        id: 282,
        type: 2
    }],
    name: "青海",
    id: 21,
    type: 1
}, {
    fid: 1,
    children: [{fid: 22, name: "济南", id: 283, type: 2}, {fid: 22, name: "青岛", id: 284, type: 2}, {fid: 22, name: "滨州", id: 285, type: 2}, {
        fid: 22,
        name: "德州",
        id: 286,
        type: 2
    }, {fid: 22, name: "东营", id: 287, type: 2}, {fid: 22, name: "菏泽", id: 288, type: 2}, {fid: 22, name: "济宁", id: 289, type: 2}, {
        fid: 22,
        name: "莱芜",
        id: 290,
        type: 2
    }, {fid: 22, name: "聊城", id: 291, type: 2}, {fid: 22, name: "临沂", id: 292, type: 2}, {fid: 22, name: "日照", id: 293, type: 2}, {
        fid: 22,
        name: "泰安",
        id: 294,
        type: 2
    }, {fid: 22, name: "威海", id: 295, type: 2}, {fid: 22, name: "潍坊", id: 296, type: 2}, {fid: 22, name: "烟台", id: 297, type: 2}, {
        fid: 22,
        name: "枣庄",
        id: 298,
        type: 2
    }, {fid: 22, name: "淄博", id: 299, type: 2}],
    name: "山东",
    id: 22,
    type: 1
}, {
    fid: 1,
    children: [{fid: 23, name: "太原", id: 300, type: 2}, {fid: 23, name: "长治", id: 301, type: 2}, {fid: 23, name: "大同", id: 302, type: 2}, {
        fid: 23,
        name: "晋城",
        id: 303,
        type: 2
    }, {fid: 23, name: "晋中", id: 304, type: 2}, {fid: 23, name: "临汾", id: 305, type: 2}, {fid: 23, name: "吕梁", id: 306, type: 2}, {
        fid: 23,
        name: "朔州",
        id: 307,
        type: 2
    }, {fid: 23, name: "忻州", id: 308, type: 2}, {fid: 23, name: "阳泉", id: 309, type: 2}, {fid: 23, name: "运城", id: 310, type: 2}],
    name: "山西",
    id: 23,
    type: 1
}, {
    fid: 1,
    children: [{fid: 24, name: "西安", id: 311, type: 2}, {fid: 24, name: "安康", id: 312, type: 2}, {fid: 24, name: "宝鸡", id: 313, type: 2}, {
        fid: 24,
        name: "汉中",
        id: 314,
        type: 2
    }, {fid: 24, name: "商洛", id: 315, type: 2}, {fid: 24, name: "铜川", id: 316, type: 2}, {fid: 24, name: "渭南", id: 317, type: 2}, {
        fid: 24,
        name: "咸阳",
        id: 318,
        type: 2
    }, {fid: 24, name: "延安", id: 319, type: 2}, {fid: 24, name: "榆林", id: 320, type: 2}],
    name: "陕西",
    id: 24,
    type: 1
}, {fid: 1, children: [{fid: 25, name: "上海", id: 321, type: 2}], name: "上海", id: 25, type: 1}, {
    fid: 1,
    children: [{fid: 26, name: "成都", id: 322, type: 2}, {fid: 26, name: "绵阳", id: 323, type: 2}, {fid: 26, name: "阿坝", id: 324, type: 2}, {
        fid: 26,
        name: "巴中",
        id: 325,
        type: 2
    }, {fid: 26, name: "达州", id: 326, type: 2}, {fid: 26, name: "德阳", id: 327, type: 2}, {fid: 26, name: "甘孜", id: 328, type: 2}, {
        fid: 26,
        name: "广安",
        id: 329,
        type: 2
    }, {fid: 26, name: "广元", id: 330, type: 2}, {fid: 26, name: "乐山", id: 331, type: 2}, {fid: 26, name: "凉山", id: 332, type: 2}, {
        fid: 26,
        name: "眉山",
        id: 333,
        type: 2
    }, {fid: 26, name: "南充", id: 334, type: 2}, {fid: 26, name: "内江", id: 335, type: 2}, {fid: 26, name: "攀枝花", id: 336, type: 2}, {
        fid: 26,
        name: "遂宁",
        id: 337,
        type: 2
    }, {fid: 26, name: "雅安", id: 338, type: 2}, {fid: 26, name: "宜宾", id: 339, type: 2}, {fid: 26, name: "资阳", id: 340, type: 2}, {
        fid: 26,
        name: "自贡",
        id: 341,
        type: 2
    }, {fid: 26, name: "泸州", id: 342, type: 2}],
    name: "四川",
    id: 26,
    type: 1
}, {fid: 1, children: [{fid: 27, name: "天津", id: 343, type: 2}], name: "天津", id: 27, type: 1}, {
    fid: 1,
    children: [{fid: 28, name: "拉萨", id: 344, type: 2}, {fid: 28, name: "阿里", id: 345, type: 2}, {fid: 28, name: "昌都", id: 346, type: 2}, {
        fid: 28,
        name: "林芝",
        id: 347,
        type: 2
    }, {fid: 28, name: "那曲", id: 348, type: 2}, {fid: 28, name: "日喀则", id: 349, type: 2}, {fid: 28, name: "山南", id: 350, type: 2}],
    name: "西藏",
    id: 28,
    type: 1
}, {
    fid: 1,
    children: [{fid: 29, name: "乌鲁木齐", id: 351, type: 2}, {fid: 29, name: "阿克苏", id: 352, type: 2}, {fid: 29, name: "阿拉尔", id: 353, type: 2}, {
        fid: 29,
        name: "巴音郭楞",
        id: 354,
        type: 2
    }, {fid: 29, name: "博尔塔拉", id: 355, type: 2}, {fid: 29, name: "昌吉", id: 356, type: 2}, {fid: 29, name: "哈密", id: 357, type: 2}, {
        fid: 29,
        name: "和田",
        id: 358,
        type: 2
    }, {fid: 29, name: "喀什", id: 359, type: 2}, {fid: 29, name: "克拉玛依", id: 360, type: 2}, {fid: 29, name: "克孜勒苏", id: 361, type: 2}, {
        fid: 29,
        name: "石河子",
        id: 362,
        type: 2
    }, {fid: 29, name: "图木舒克", id: 363, type: 2}, {fid: 29, name: "吐鲁番", id: 364, type: 2}, {fid: 29, name: "五家渠", id: 365, type: 2}, {
        fid: 29,
        name: "伊犁",
        id: 366,
        type: 2
    }],
    name: "新疆",
    id: 29,
    type: 1
}, {
    fid: 1,
    children: [{fid: 30, name: "昆明", id: 367, type: 2}, {fid: 30, name: "怒江", id: 368, type: 2}, {fid: 30, name: "普洱", id: 369, type: 2}, {
        fid: 30,
        name: "丽江",
        id: 370,
        type: 2
    }, {fid: 30, name: "保山", id: 371, type: 2}, {fid: 30, name: "楚雄", id: 372, type: 2}, {fid: 30, name: "大理", id: 373, type: 2}, {
        fid: 30,
        name: "德宏",
        id: 374,
        type: 2
    }, {fid: 30, name: "迪庆", id: 375, type: 2}, {fid: 30, name: "红河", id: 376, type: 2}, {fid: 30, name: "临沧", id: 377, type: 2}, {
        fid: 30,
        name: "曲靖",
        id: 378,
        type: 2
    }, {fid: 30, name: "文山", id: 379, type: 2}, {fid: 30, name: "西双版纳", id: 380, type: 2}, {fid: 30, name: "玉溪", id: 381, type: 2}, {
        fid: 30,
        name: "昭通",
        id: 382,
        type: 2
    }],
    name: "云南",
    id: 30,
    type: 1
}, {
    fid: 1,
    children: [{fid: 31, name: "杭州", id: 383, type: 2}, {fid: 31, name: "湖州", id: 384, type: 2}, {fid: 31, name: "嘉兴", id: 385, type: 2}, {
        fid: 31,
        name: "金华",
        id: 386,
        type: 2
    }, {fid: 31, name: "丽水", id: 387, type: 2}, {fid: 31, name: "宁波", id: 388, type: 2}, {fid: 31, name: "绍兴", id: 389, type: 2}, {
        fid: 31,
        name: "台州",
        id: 390,
        type: 2
    }, {fid: 31, name: "温州", id: 391, type: 2}, {fid: 31, name: "舟山", id: 392, type: 2}, {fid: 31, name: "衢州", id: 393, type: 2}],
    name: "浙江",
    id: 31,
    type: 1
}, {
    fid: 1,
    children: [
        {fid: 32, name: "重庆", id: 394, type: 2}],
    name: "重庆",
    id: 32,
    type: 1
}, {
    fid: 1,
    children: [{fid: 33, name: "香港", id: 395, type: 2}],
    name: "香港",
    id: 33,
    type: 1
}, {fid: 1, children: [{fid: 34, name: "澳门", id: 396, type: 2}], name: "澳门", id: 34, type: 1}, {
    fid: 1,
    children: [{fid: 35, name: "台湾", id: 397, type: 2}],
    name: "台湾",
    id: 35,
    type: 1
}]

export default cityData;
