const provinceData = [
    {
        fid: 1,
        name: "北京",
        id: 2,
        type: 1
    }, {
        fid: 1,
        name: "安徽",
        id: 3,
        type: 1
    }, {
        fid: 1,
        name: "福建",
        id: 4,
        type: 1
    }, {
        fid: 1,
        name: "甘肃",
        id: 5,
        type: 1
    }, {
        fid: 1,
        name: "广东",
        id: 6,
        type: 1
    }, {
        fid: 1,
        name: "广西",
        id: 7,
        type: 1
    }, {
        fid: 1,
        name: "贵州",
        id: 8,
        type: 1
    }, {
        fid: 1,
        name: "海南",
        id: 9,
        type: 1
    }, {
        fid: 1,
        name: "河北",
        id: 10,
        type: 1
    }, {
        fid: 1,
        name: "河南",
        id: 11,
        type: 1
    }, {
        fid: 1,
        name: "黑龙江",
        id: 12,
        type: 1
    }, {
        fid: 1,
        name: "湖北",
        id: 13,
        type: 1
    }, {
        fid: 1,
        name: "湖南",
        id: 14,
        type: 1
    }, {
        fid: 1,
        name: "吉林",
        id: 15,
        type: 1
    }, {
        fid: 1,
        name: "江苏",
        id: 16,
        type: 1
    }, {
        fid: 1,
        name: "江西",
        id: 17,
        type: 1
    }, {
        fid: 1,
        name: "辽宁",
        id: 18,
        type: 1
    }, {
        fid: 1,
        name: "内蒙古",
        id: 19,
        type: 1
    }, {
        fid: 1,
        name: "宁夏",
        id: 20,
        type: 1
    }, {
        fid: 1,
        name: "青海",
        id: 21,
        type: 1
    }, {
        fid: 1,
        name: "山东",
        id: 22,
        type: 1
    }, {
        fid: 1,
        name: "山西",
        id: 23,
        type: 1
    }, {
        fid: 1,
        name: "陕西",
        id: 24,
        type: 1
    }, {
        fid: 1,
        name: "上海",
        id: 25,
        type: 1
    }, {
        fid: 1,
        name: "四川",
        id: 26,
        type: 1
    }, {
        fid: 1,
        name: "天津",
        id: 27,
        type: 1
    }, {
        fid: 1,
        name: "西藏",
        id: 28,
        type: 1
    }, {
        fid: 1,
        name: "新疆",
        id: 29,
        type: 1
    }, {
        fid: 1,
        name: "云南",
        id: 30,
        type: 1
    }, {
        fid: 1,
        name: "浙江",
        id: 31,
        type: 1
    }, {
        fid: 1,
        name: "重庆",
        id: 32,
        type: 1
    }, {
        fid: 1,
        name: "香港",
        id: 33,
        type: 1
    }, {fid: 1,
        name: "澳门",
        id: 34,
        type: 1
    }, {
        fid: 1,
        name: "台湾",
        id: 35,
        type: 1
    }]

export default provinceData;
