<template>
	<div>
		<div style="display: flex;width: 100%;">
			<el-cascader
				v-model="address.cityId"
				:options="addressData"
				:props="addressProps"
				:size="size"
				clearable
				filterable
				collapse-tags
				ref="citySelect"
				placeholder="请选择城市"
				@change="handleChange"
			>
			</el-cascader>
			
		</div>
		<div style="width: 100%;margin-top: 10px" v-if="info===true">
			<el-input
				:size="size"
				autosize
				placeholder="请输入详细地址"
				v-model="address.address"
				prefix-icon="el-icon-location-information"
				clearable>
			</el-input>
		</div>
	</div>
</template>

<script>
import addressData from "@/utils/city/addressData.js";
import provinceData from "@/utils/city/provinceData.js";
import cityData from "@/utils/city/cityData";


export default {
	props: {
		size: {
			type: String,
			default: "medium",
		},
		lv: {
			type: Number,
			default: 1,
		},
		info: {
			type: Boolean,
			default: false,
		},
		multiple: {
			type: Boolean,
			default: false,
		},
		address: {
			type: Object ,
			default:function () {
				return{
					cityId : [],
					cityName : [],
					address:"",
				}
			},
		},
	},
	data() {
		return {
			addressData: [],
			addressProps: {
				multiple:this.multiple,
				value: "id",
				label: "name",
			},
		};
	},
	mounted() {
		if (this.lv===1){
			this.addressData = provinceData
		}else if (this.lv===2){
			this.addressData =cityData
		}else {
			this.addressData =addressData
		}
	},
	methods: {
		handleChange() {
			const checkedNode =  this.$refs["citySelect"].getCheckedNodes();
			if (checkedNode.length===0){
				this.address.cityName = []
			}else {
				if (this.multiple){
					this.address.cityName = []
					for (let i = 0; i < checkedNode.length; i++) {
						if (checkedNode[i].isLeaf){
							this.address.cityName.push(checkedNode[i].pathLabels);
						}
					}
				}else {
					this.address.cityName = checkedNode[checkedNode.length-1].pathLabels;
				}
			}
			// 触发地址变更事件，传递选中的地址给父组件
			this.$emit("change", this.address);
		},
	},
};
</script>
