const addressData = [
    {
    fid: 1,
    children: [{
        fid: 2,
        children: [{fid: 52, name: "东城区", id: 500, type: 3}, {fid: 52, name: "西城区", id: 501, type: 3}, {fid: 52, name: "海淀区", id: 502, type: 3}, {
            fid: 52,
            name: "朝阳区",
            id: 503,
            type: 3
        }, {fid: 52, name: "崇文区", id: 504, type: 3}, {fid: 52, name: "宣武区", id: 505, type: 3}, {fid: 52, name: "丰台区", id: 506, type: 3}, {
            fid: 52,
            name: "石景山区",
            id: 507,
            type: 3
        }, {fid: 52, name: "房山区", id: 508, type: 3}, {fid: 52, name: "门头沟区", id: 509, type: 3}, {fid: 52, name: "通州区", id: 510, type: 3}, {
            fid: 52,
            name: "顺义区",
            id: 511,
            type: 3
        }, {fid: 52, name: "昌平区", id: 512, type: 3}, {fid: 52, name: "怀柔区", id: 513, type: 3}, {fid: 52, name: "平谷区", id: 514, type: 3}, {
            fid: 52,
            name: "大兴区",
            id: 515,
            type: 3
        }, {fid: 52, name: "密云县", id: 516, type: 3}, {fid: 52, name: "延庆县", id: 517, type: 3}],
        name: "北京",
        id: 52,
        type: 2
    }],
    name: "北京",
    id: 2,
    type: 1
}, {
    fid: 1,
    children: [
        {
        fid: 3,
        children: [
            {fid: 36, name: "迎江区", id: 398, type: 3}, {fid: 36, name: "大观区", id: 399, type: 3}, {fid: 36, name: "宜秀区", id: 400, type: 3}, {
            fid: 36,
            name: "桐城市",
            id: 401,
            type: 3
        }, {fid: 36, name: "怀宁县", id: 402, type: 3}, {fid: 36, name: "枞阳县", id: 403, type: 3}, {fid: 36, name: "潜山县", id: 404, type: 3}, {
            fid: 36,
            name: "太湖县",
            id: 405,
            type: 3
        }, {fid: 36, name: "宿松县", id: 406, type: 3}, {fid: 36, name: "望江县", id: 407, type: 3}, {fid: 36, name: "岳西县", id: 408, type: 3}],
        name: "安庆",
        id: 36,
        type: 2
    }, {
        fid: 3,
        children: [{fid: 37, name: "中市区", id: 409, type: 3}, {fid: 37, name: "东市区", id: 410, type: 3}, {fid: 37, name: "西市区", id: 411, type: 3}, {
            fid: 37,
            name: "郊区",
            id: 412,
            type: 3
        }, {fid: 37, name: "怀远县", id: 413, type: 3}, {fid: 37, name: "五河县", id: 414, type: 3}, {fid: 37, name: "固镇县", id: 415, type: 3}],
        name: "蚌埠",
        id: 37,
        type: 2
    }, {
        fid: 3,
        children: [{fid: 38, name: "居巢区", id: 416, type: 3}, {fid: 38, name: "庐江县", id: 417, type: 3}, {fid: 38, name: "无为县", id: 418, type: 3}, {
            fid: 38,
            name: "含山县",
            id: 419,
            type: 3
        }, {fid: 38, name: "和县", id: 420, type: 3}],
        name: "巢湖",
        id: 38,
        type: 2
    }, {
        fid: 3,
        children: [{fid: 39, name: "贵池区", id: 421, type: 3}, {fid: 39, name: "东至县", id: 422, type: 3}, {fid: 39, name: "石台县", id: 423, type: 3}, {
            fid: 39,
            name: "青阳县",
            id: 424,
            type: 3
        }],
        name: "池州",
        id: 39,
        type: 2
    }, {
        fid: 3,
        children: [{fid: 40, name: "琅琊区", id: 425, type: 3}, {fid: 40, name: "南谯区", id: 426, type: 3}, {fid: 40, name: "天长市", id: 427, type: 3}, {
            fid: 40,
            name: "明光市",
            id: 428,
            type: 3
        }, {fid: 40, name: "来安县", id: 429, type: 3}, {fid: 40, name: "全椒县", id: 430, type: 3}, {fid: 40, name: "定远县", id: 431, type: 3}, {
            fid: 40,
            name: "凤阳县",
            id: 432,
            type: 3
        }],
        name: "滁州",
        id: 40,
        type: 2
    }, {
        fid: 3,
        children: [{fid: 41, name: "蚌山区", id: 433, type: 3}, {fid: 41, name: "龙子湖区", id: 434, type: 3}, {fid: 41, name: "禹会区", id: 435, type: 3}, {
            fid: 41,
            name: "淮上区",
            id: 436,
            type: 3
        }, {fid: 41, name: "颍州区", id: 437, type: 3}, {fid: 41, name: "颍东区", id: 438, type: 3}, {fid: 41, name: "颍泉区", id: 439, type: 3}, {
            fid: 41,
            name: "界首市",
            id: 440,
            type: 3
        }, {fid: 41, name: "临泉县", id: 441, type: 3}, {fid: 41, name: "太和县", id: 442, type: 3}, {fid: 41, name: "阜南县", id: 443, type: 3}, {
            fid: 41,
            name: "颖上县",
            id: 444,
            type: 3
        }],
        name: "阜阳",
        id: 41,
        type: 2
    }, {
        fid: 3,
        children: [{fid: 42, name: "相山区", id: 445, type: 3}, {fid: 42, name: "杜集区", id: 446, type: 3}, {fid: 42, name: "烈山区", id: 447, type: 3}, {
            fid: 42,
            name: "濉溪县",
            id: 448,
            type: 3
        }],
        name: "淮北",
        id: 42,
        type: 2
    }, {
        fid: 3,
        children: [{fid: 43, name: "田家庵区", id: 449, type: 3}, {fid: 43, name: "大通区", id: 450, type: 3}, {fid: 43, name: "谢家集区", id: 451, type: 3}, {
            fid: 43,
            name: "八公山区",
            id: 452,
            type: 3
        }, {fid: 43, name: "潘集区", id: 453, type: 3}, {fid: 43, name: "凤台县", id: 454, type: 3}],
        name: "淮南",
        id: 43,
        type: 2
    }, {
        fid: 3,
        children: [{fid: 44, name: "屯溪区", id: 455, type: 3}, {fid: 44, name: "黄山区", id: 456, type: 3}, {fid: 44, name: "徽州区", id: 457, type: 3}, {
            fid: 44,
            name: "歙县",
            id: 458,
            type: 3
        }, {fid: 44, name: "休宁县", id: 459, type: 3}, {fid: 44, name: "黟县", id: 460, type: 3}, {fid: 44, name: "祁门县", id: 461, type: 3}],
        name: "黄山",
        id: 44,
        type: 2
    }, {
        fid: 3,
        children: [{fid: 45, name: "金安区", id: 462, type: 3}, {fid: 45, name: "裕安区", id: 463, type: 3}, {fid: 45, name: "寿县", id: 464, type: 3}, {
            fid: 45,
            name: "霍邱县",
            id: 465,
            type: 3
        }, {fid: 45, name: "舒城县", id: 466, type: 3}, {fid: 45, name: "金寨县", id: 467, type: 3}, {fid: 45, name: "霍山县", id: 468, type: 3}],
        name: "六安",
        id: 45,
        type: 2
    }, {
        fid: 3,
        children: [{fid: 46, name: "雨山区", id: 469, type: 3}, {fid: 46, name: "花山区", id: 470, type: 3}, {fid: 46, name: "金家庄区", id: 471, type: 3}, {
            fid: 46,
            name: "当涂县",
            id: 472,
            type: 3
        }],
        name: "马鞍山",
        id: 46,
        type: 2
    }, {
        fid: 3,
        children: [{fid: 47, name: "埇桥区", id: 473, type: 3}, {fid: 47, name: "砀山县", id: 474, type: 3}, {fid: 47, name: "萧县", id: 475, type: 3}, {
            fid: 47,
            name: "灵璧县",
            id: 476,
            type: 3
        }, {fid: 47, name: "泗县", id: 477, type: 3}],
        name: "宿州",
        id: 47,
        type: 2
    }, {
        fid: 3,
        children: [{fid: 48, name: "铜官山区", id: 478, type: 3}, {fid: 48, name: "狮子山区", id: 479, type: 3}, {fid: 48, name: "郊区", id: 480, type: 3}, {
            fid: 48,
            name: "铜陵县",
            id: 481,
            type: 3
        }],
        name: "铜陵",
        id: 48,
        type: 2
    }, {
        fid: 3,
        children: [{fid: 49, name: "镜湖区", id: 482, type: 3}, {fid: 49, name: "弋江区", id: 483, type: 3}, {fid: 49, name: "鸠江区", id: 484, type: 3}, {
            fid: 49,
            name: "三山区",
            id: 485,
            type: 3
        }, {fid: 49, name: "芜湖县", id: 486, type: 3}, {fid: 49, name: "繁昌县", id: 487, type: 3}, {fid: 49, name: "南陵县", id: 488, type: 3}],
        name: "芜湖",
        id: 49,
        type: 2
    }, {
        fid: 3,
        children: [{fid: 50, name: "宣州区", id: 489, type: 3}, {fid: 50, name: "宁国市", id: 490, type: 3}, {fid: 50, name: "郎溪县", id: 491, type: 3}, {
            fid: 50,
            name: "广德县",
            id: 492,
            type: 3
        }, {fid: 50, name: "泾县", id: 493, type: 3}, {fid: 50, name: "绩溪县", id: 494, type: 3}, {fid: 50, name: "旌德县", id: 495, type: 3}],
        name: "宣城",
        id: 50,
        type: 2
    }, {
        fid: 3,
        children: [{fid: 51, name: "涡阳县", id: 496, type: 3}, {fid: 51, name: "蒙城县", id: 497, type: 3}, {fid: 51, name: "利辛县", id: 498, type: 3}, {
            fid: 51,
            name: "谯城区",
            id: 499,
            type: 3
        }],
        name: "亳州",
        id: 51,
        type: 2
    }, {
        fid: 3,
        children: [{fid: 3401, name: "庐阳区", id: 3402, type: 3}, {fid: 3401, name: "瑶海区", id: 3403, type: 3}, {fid: 3401, name: "蜀山区", id: 3404, type: 3}, {
            fid: 3401,
            name: "包河区",
            id: 3405,
            type: 3
        }, {fid: 3401, name: "长丰县", id: 3406, type: 3}, {fid: 3401, name: "肥东县", id: 3407, type: 3}, {fid: 3401, name: "肥西县", id: 3408, type: 3}],
        name: "合肥",
        id: 3401,
        type: 2
    }],
    name: "安徽",
    id: 3,
    type: 1
}, {
    fid: 1,
    children: [{
        fid: 4,
        children: [{fid: 53, name: "鼓楼区", id: 518, type: 3}, {fid: 53, name: "台江区", id: 519, type: 3}, {fid: 53, name: "仓山区", id: 520, type: 3}, {
            fid: 53,
            name: "马尾区",
            id: 521,
            type: 3
        }, {fid: 53, name: "晋安区", id: 522, type: 3}, {fid: 53, name: "福清市", id: 523, type: 3}, {fid: 53, name: "长乐市", id: 524, type: 3}, {
            fid: 53,
            name: "闽侯县",
            id: 525,
            type: 3
        }, {fid: 53, name: "连江县", id: 526, type: 3}, {fid: 53, name: "罗源县", id: 527, type: 3}, {fid: 53, name: "闽清县", id: 528, type: 3}, {
            fid: 53,
            name: "永泰县",
            id: 529,
            type: 3
        }, {fid: 53, name: "平潭县", id: 530, type: 3}],
        name: "福州",
        id: 53,
        type: 2
    }, {
        fid: 4,
        children: [{fid: 54, name: "新罗区", id: 531, type: 3}, {fid: 54, name: "漳平市", id: 532, type: 3}, {fid: 54, name: "长汀县", id: 533, type: 3}, {
            fid: 54,
            name: "永定县",
            id: 534,
            type: 3
        }, {fid: 54, name: "上杭县", id: 535, type: 3}, {fid: 54, name: "武平县", id: 536, type: 3}, {fid: 54, name: "连城县", id: 537, type: 3}],
        name: "龙岩",
        id: 54,
        type: 2
    }, {
        fid: 4,
        children: [{fid: 55, name: "延平区", id: 538, type: 3}, {fid: 55, name: "邵武市", id: 539, type: 3}, {fid: 55, name: "武夷山市", id: 540, type: 3}, {
            fid: 55,
            name: "建瓯市",
            id: 541,
            type: 3
        }, {fid: 55, name: "建阳市", id: 542, type: 3}, {fid: 55, name: "顺昌县", id: 543, type: 3}, {fid: 55, name: "浦城县", id: 544, type: 3}, {
            fid: 55,
            name: "光泽县",
            id: 545,
            type: 3
        }, {fid: 55, name: "松溪县", id: 546, type: 3}, {fid: 55, name: "政和县", id: 547, type: 3}],
        name: "南平",
        id: 55,
        type: 2
    }, {
        fid: 4,
        children: [{fid: 56, name: "蕉城区", id: 548, type: 3}, {fid: 56, name: "福安市", id: 549, type: 3}, {fid: 56, name: "福鼎市", id: 550, type: 3}, {
            fid: 56,
            name: "霞浦县",
            id: 551,
            type: 3
        }, {fid: 56, name: "古田县", id: 552, type: 3}, {fid: 56, name: "屏南县", id: 553, type: 3}, {fid: 56, name: "寿宁县", id: 554, type: 3}, {
            fid: 56,
            name: "周宁县",
            id: 555,
            type: 3
        }, {fid: 56, name: "柘荣县", id: 556, type: 3}],
        name: "宁德",
        id: 56,
        type: 2
    }, {
        fid: 4,
        children: [{fid: 57, name: "城厢区", id: 557, type: 3}, {fid: 57, name: "涵江区", id: 558, type: 3}, {fid: 57, name: "荔城区", id: 559, type: 3}, {
            fid: 57,
            name: "秀屿区",
            id: 560,
            type: 3
        }, {fid: 57, name: "仙游县", id: 561, type: 3}],
        name: "莆田",
        id: 57,
        type: 2
    }, {
        fid: 4,
        children: [{fid: 58, name: "鲤城区", id: 562, type: 3}, {fid: 58, name: "丰泽区", id: 563, type: 3}, {fid: 58, name: "洛江区", id: 564, type: 3}, {
            fid: 58,
            name: "清濛开发区",
            id: 565,
            type: 3
        }, {fid: 58, name: "泉港区", id: 566, type: 3}, {fid: 58, name: "石狮市", id: 567, type: 3}, {fid: 58, name: "晋江市", id: 568, type: 3}, {
            fid: 58,
            name: "南安市",
            id: 569,
            type: 3
        }, {fid: 58, name: "惠安县", id: 570, type: 3}, {fid: 58, name: "安溪县", id: 571, type: 3}, {fid: 58, name: "永春县", id: 572, type: 3}, {
            fid: 58,
            name: "德化县",
            id: 573,
            type: 3
        }, {fid: 58, name: "金门县", id: 574, type: 3}],
        name: "泉州",
        id: 58,
        type: 2
    }, {
        fid: 4,
        children: [{fid: 59, name: "梅列区", id: 575, type: 3}, {fid: 59, name: "三元区", id: 576, type: 3}, {fid: 59, name: "永安市", id: 577, type: 3}, {
            fid: 59,
            name: "明溪县",
            id: 578,
            type: 3
        }, {fid: 59, name: "清流县", id: 579, type: 3}, {fid: 59, name: "宁化县", id: 580, type: 3}, {fid: 59, name: "大田县", id: 581, type: 3}, {
            fid: 59,
            name: "尤溪县",
            id: 582,
            type: 3
        }, {fid: 59, name: "沙县", id: 583, type: 3}, {fid: 59, name: "将乐县", id: 584, type: 3}, {fid: 59, name: "泰宁县", id: 585, type: 3}, {
            fid: 59,
            name: "建宁县",
            id: 586,
            type: 3
        }],
        name: "三明",
        id: 59,
        type: 2
    }, {
        fid: 4,
        children: [{fid: 60, name: "思明区", id: 587, type: 3}, {fid: 60, name: "海沧区", id: 588, type: 3}, {fid: 60, name: "湖里区", id: 589, type: 3}, {
            fid: 60,
            name: "集美区",
            id: 590,
            type: 3
        }, {fid: 60, name: "同安区", id: 591, type: 3}, {fid: 60, name: "翔安区", id: 592, type: 3}],
        name: "厦门",
        id: 60,
        type: 2
    }, {
        fid: 4,
        children: [{fid: 61, name: "芗城区", id: 593, type: 3}, {fid: 61, name: "龙文区", id: 594, type: 3}, {fid: 61, name: "龙海市", id: 595, type: 3}, {
            fid: 61,
            name: "云霄县",
            id: 596,
            type: 3
        }, {fid: 61, name: "漳浦县", id: 597, type: 3}, {fid: 61, name: "诏安县", id: 598, type: 3}, {fid: 61, name: "长泰县", id: 599, type: 3}, {
            fid: 61,
            name: "东山县",
            id: 600,
            type: 3
        }, {fid: 61, name: "南靖县", id: 601, type: 3}, {fid: 61, name: "平和县", id: 602, type: 3}, {fid: 61, name: "华安县", id: 603, type: 3}],
        name: "漳州",
        id: 61,
        type: 2
    }],
    name: "福建",
    id: 4,
    type: 1
}, {
    fid: 1,
    children: [{
        fid: 5,
        children: [{fid: 62, name: "皋兰县", id: 604, type: 3}, {fid: 62, name: "城关区", id: 605, type: 3}, {fid: 62, name: "七里河区", id: 606, type: 3}, {
            fid: 62,
            name: "西固区",
            id: 607,
            type: 3
        }, {fid: 62, name: "安宁区", id: 608, type: 3}, {fid: 62, name: "红古区", id: 609, type: 3}, {fid: 62, name: "永登县", id: 610, type: 3}, {
            fid: 62,
            name: "榆中县",
            id: 611,
            type: 3
        }],
        name: "兰州",
        id: 62,
        type: 2
    }, {
        fid: 5,
        children: [{fid: 63, name: "白银区", id: 612, type: 3}, {fid: 63, name: "平川区", id: 613, type: 3}, {fid: 63, name: "会宁县", id: 614, type: 3}, {
            fid: 63,
            name: "景泰县",
            id: 615,
            type: 3
        }, {fid: 63, name: "靖远县", id: 616, type: 3}],
        name: "白银",
        id: 63,
        type: 2
    }, {
        fid: 5,
        children: [{fid: 64, name: "临洮县", id: 617, type: 3}, {fid: 64, name: "陇西县", id: 618, type: 3}, {fid: 64, name: "通渭县", id: 619, type: 3}, {
            fid: 64,
            name: "渭源县",
            id: 620,
            type: 3
        }, {fid: 64, name: "漳县", id: 621, type: 3}, {fid: 64, name: "岷县", id: 622, type: 3}, {fid: 64, name: "安定区", id: 623, type: 3}, {
            fid: 64,
            name: "安定区",
            id: 624,
            type: 3
        }],
        name: "定西",
        id: 64,
        type: 2
    }, {
        fid: 5,
        children: [{fid: 65, name: "合作市", id: 625, type: 3}, {fid: 65, name: "临潭县", id: 626, type: 3}, {fid: 65, name: "卓尼县", id: 627, type: 3}, {
            fid: 65,
            name: "舟曲县",
            id: 628,
            type: 3
        }, {fid: 65, name: "迭部县", id: 629, type: 3}, {fid: 65, name: "玛曲县", id: 630, type: 3}, {fid: 65, name: "碌曲县", id: 631, type: 3}, {
            fid: 65,
            name: "夏河县",
            id: 632,
            type: 3
        }],
        name: "甘南",
        id: 65,
        type: 2
    }, {fid: 5, children: [{fid: 66, name: "嘉峪关市", id: 633, type: 3}], name: "嘉峪关", id: 66, type: 2}, {
        fid: 5,
        children: [{fid: 67, name: "金川区", id: 634, type: 3}, {fid: 67, name: "永昌县", id: 635, type: 3}],
        name: "金昌",
        id: 67,
        type: 2
    }, {
        fid: 5,
        children: [{fid: 68, name: "肃州区", id: 636, type: 3}, {fid: 68, name: "玉门市", id: 637, type: 3}, {fid: 68, name: "敦煌市", id: 638, type: 3}, {
            fid: 68,
            name: "金塔县",
            id: 639,
            type: 3
        }, {fid: 68, name: "瓜州县", id: 640, type: 3}, {fid: 68, name: "肃北", id: 641, type: 3}, {fid: 68, name: "阿克塞", id: 642, type: 3}],
        name: "酒泉",
        id: 68,
        type: 2
    }, {
        fid: 5,
        children: [{fid: 69, name: "临夏市", id: 643, type: 3}, {fid: 69, name: "临夏县", id: 644, type: 3}, {fid: 69, name: "康乐县", id: 645, type: 3}, {
            fid: 69,
            name: "永靖县",
            id: 646,
            type: 3
        }, {fid: 69, name: "广河县", id: 647, type: 3}, {fid: 69, name: "和政县", id: 648, type: 3}, {fid: 69, name: "东乡族自治县", id: 649, type: 3}, {
            fid: 69,
            name: "积石山",
            id: 650,
            type: 3
        }],
        name: "临夏",
        id: 69,
        type: 2
    }, {
        fid: 5,
        children: [{fid: 70, name: "成县", id: 651, type: 3}, {fid: 70, name: "徽县", id: 652, type: 3}, {fid: 70, name: "康县", id: 653, type: 3}, {
            fid: 70,
            name: "礼县",
            id: 654,
            type: 3
        }, {fid: 70, name: "两当县", id: 655, type: 3}, {fid: 70, name: "文县", id: 656, type: 3}, {fid: 70, name: "西和县", id: 657, type: 3}, {
            fid: 70,
            name: "宕昌县",
            id: 658,
            type: 3
        }, {fid: 70, name: "武都区", id: 659, type: 3}],
        name: "陇南",
        id: 70,
        type: 2
    }, {
        fid: 5,
        children: [{fid: 71, name: "崇信县", id: 660, type: 3}, {fid: 71, name: "华亭县", id: 661, type: 3}, {fid: 71, name: "静宁县", id: 662, type: 3}, {
            fid: 71,
            name: "灵台县",
            id: 663,
            type: 3
        }, {fid: 71, name: "崆峒区", id: 664, type: 3}, {fid: 71, name: "庄浪县", id: 665, type: 3}, {fid: 71, name: "泾川县", id: 666, type: 3}],
        name: "平凉",
        id: 71,
        type: 2
    }, {
        fid: 5,
        children: [{fid: 72, name: "合水县", id: 667, type: 3}, {fid: 72, name: "华池县", id: 668, type: 3}, {fid: 72, name: "环县", id: 669, type: 3}, {
            fid: 72,
            name: "宁县",
            id: 670,
            type: 3
        }, {fid: 72, name: "庆城县", id: 671, type: 3}, {fid: 72, name: "西峰区", id: 672, type: 3}, {fid: 72, name: "镇原县", id: 673, type: 3}, {
            fid: 72,
            name: "正宁县",
            id: 674,
            type: 3
        }],
        name: "庆阳",
        id: 72,
        type: 2
    }, {
        fid: 5,
        children: [{fid: 73, name: "甘谷县", id: 675, type: 3}, {fid: 73, name: "秦安县", id: 676, type: 3}, {fid: 73, name: "清水县", id: 677, type: 3}, {
            fid: 73,
            name: "秦州区",
            id: 678,
            type: 3
        }, {fid: 73, name: "麦积区", id: 679, type: 3}, {fid: 73, name: "武山县", id: 680, type: 3}, {fid: 73, name: "张家川", id: 681, type: 3}],
        name: "天水",
        id: 73,
        type: 2
    }, {
        fid: 5,
        children: [{fid: 74, name: "古浪县", id: 682, type: 3}, {fid: 74, name: "民勤县", id: 683, type: 3}, {fid: 74, name: "天祝", id: 684, type: 3}, {
            fid: 74,
            name: "凉州区",
            id: 685,
            type: 3
        }],
        name: "武威",
        id: 74,
        type: 2
    }, {
        fid: 5,
        children: [{fid: 75, name: "高台县", id: 686, type: 3}, {fid: 75, name: "临泽县", id: 687, type: 3}, {fid: 75, name: "民乐县", id: 688, type: 3}, {
            fid: 75,
            name: "山丹县",
            id: 689,
            type: 3
        }, {fid: 75, name: "肃南", id: 690, type: 3}, {fid: 75, name: "甘州区", id: 691, type: 3}],
        name: "张掖",
        id: 75,
        type: 2
    }],
    name: "甘肃",
    id: 5,
    type: 1
}, {
    fid: 1,
    children: [{
        fid: 6,
        children: [{fid: 76, name: "从化市", id: 692, type: 3}, {fid: 76, name: "天河区", id: 693, type: 3}, {fid: 76, name: "东山区", id: 694, type: 3}, {
            fid: 76,
            name: "白云区",
            id: 695,
            type: 3
        }, {fid: 76, name: "海珠区", id: 696, type: 3}, {fid: 76, name: "荔湾区", id: 697, type: 3}, {fid: 76, name: "越秀区", id: 698, type: 3}, {
            fid: 76,
            name: "黄埔区",
            id: 699,
            type: 3
        }, {fid: 76, name: "番禺区", id: 700, type: 3}, {fid: 76, name: "花都区", id: 701, type: 3}, {fid: 76, name: "增城区", id: 702, type: 3}, {
            fid: 76,
            name: "从化区",
            id: 703,
            type: 3
        }, {fid: 76, name: "市郊", id: 704, type: 3}],
        name: "广州",
        id: 76,
        type: 2
    }, {
        fid: 6,
        children: [{fid: 77, name: "福田区", id: 705, type: 3}, {fid: 77, name: "罗湖区", id: 706, type: 3}, {fid: 77, name: "南山区", id: 707, type: 3}, {
            fid: 77,
            name: "宝安区",
            id: 708,
            type: 3
        }, {fid: 77, name: "龙岗区", id: 709, type: 3}, {fid: 77, name: "盐田区", id: 710, type: 3}],
        name: "深圳",
        id: 77,
        type: 2
    }, {
        fid: 6,
        children: [{fid: 78, name: "湘桥区", id: 711, type: 3}, {fid: 78, name: "潮安县", id: 712, type: 3}, {fid: 78, name: "饶平县", id: 713, type: 3}],
        name: "潮州",
        id: 78,
        type: 2
    }, {
        fid: 6,
        children: [{fid: 79, name: "南城区", id: 714, type: 3}, {fid: 79, name: "东城区", id: 715, type: 3}, {fid: 79, name: "万江区", id: 716, type: 3}, {
            fid: 79,
            name: "莞城区",
            id: 717,
            type: 3
        }, {fid: 79, name: "石龙镇", id: 718, type: 3}, {fid: 79, name: "虎门镇", id: 719, type: 3}, {fid: 79, name: "麻涌镇", id: 720, type: 3}, {
            fid: 79,
            name: "道滘镇",
            id: 721,
            type: 3
        }, {fid: 79, name: "石碣镇", id: 722, type: 3}, {fid: 79, name: "沙田镇", id: 723, type: 3}, {fid: 79, name: "望牛墩镇", id: 724, type: 3}, {
            fid: 79,
            name: "洪梅镇",
            id: 725,
            type: 3
        }, {fid: 79, name: "茶山镇", id: 726, type: 3}, {fid: 79, name: "寮步镇", id: 727, type: 3}, {fid: 79, name: "大岭山镇", id: 728, type: 3}, {
            fid: 79,
            name: "大朗镇",
            id: 729,
            type: 3
        }, {fid: 79, name: "黄江镇", id: 730, type: 3}, {fid: 79, name: "樟木头", id: 731, type: 3}, {fid: 79, name: "凤岗镇", id: 732, type: 3}, {
            fid: 79,
            name: "塘厦镇",
            id: 733,
            type: 3
        }, {fid: 79, name: "谢岗镇", id: 734, type: 3}, {fid: 79, name: "厚街镇", id: 735, type: 3}, {fid: 79, name: "清溪镇", id: 736, type: 3}, {
            fid: 79,
            name: "常平镇",
            id: 737,
            type: 3
        }, {fid: 79, name: "桥头镇", id: 738, type: 3}, {fid: 79, name: "横沥镇", id: 739, type: 3}, {fid: 79, name: "东坑镇", id: 740, type: 3}, {
            fid: 79,
            name: "企石镇",
            id: 741,
            type: 3
        }, {fid: 79, name: "石排镇", id: 742, type: 3}, {fid: 79, name: "长安镇", id: 743, type: 3}, {fid: 79, name: "中堂镇", id: 744, type: 3}, {
            fid: 79,
            name: "高埗镇",
            id: 745,
            type: 3
        }],
        name: "东莞",
        id: 79,
        type: 2
    }, {
        fid: 6,
        children: [{fid: 80, name: "禅城区", id: 746, type: 3}, {fid: 80, name: "南海区", id: 747, type: 3}, {fid: 80, name: "顺德区", id: 748, type: 3}, {
            fid: 80,
            name: "三水区",
            id: 749,
            type: 3
        }, {fid: 80, name: "高明区", id: 750, type: 3}],
        name: "佛山",
        id: 80,
        type: 2
    }, {
        fid: 6,
        children: [{fid: 81, name: "东源县", id: 751, type: 3}, {fid: 81, name: "和平县", id: 752, type: 3}, {fid: 81, name: "源城区", id: 753, type: 3}, {
            fid: 81,
            name: "连平县",
            id: 754,
            type: 3
        }, {fid: 81, name: "龙川县", id: 755, type: 3}, {fid: 81, name: "紫金县", id: 756, type: 3}],
        name: "河源",
        id: 81,
        type: 2
    }, {
        fid: 6,
        children: [{fid: 82, name: "惠阳区", id: 757, type: 3}, {fid: 82, name: "惠城区", id: 758, type: 3}, {fid: 82, name: "大亚湾", id: 759, type: 3}, {
            fid: 82,
            name: "博罗县",
            id: 760,
            type: 3
        }, {fid: 82, name: "惠东县", id: 761, type: 3}, {fid: 82, name: "龙门县", id: 762, type: 3}],
        name: "惠州",
        id: 82,
        type: 2
    }, {
        fid: 6,
        children: [{fid: 83, name: "江海区", id: 763, type: 3}, {fid: 83, name: "蓬江区", id: 764, type: 3}, {fid: 83, name: "新会区", id: 765, type: 3}, {
            fid: 83,
            name: "台山市",
            id: 766,
            type: 3
        }, {fid: 83, name: "开平市", id: 767, type: 3}, {fid: 83, name: "鹤山市", id: 768, type: 3}, {fid: 83, name: "恩平市", id: 769, type: 3}],
        name: "江门",
        id: 83,
        type: 2
    }, {
        fid: 6,
        children: [{fid: 84, name: "榕城区", id: 770, type: 3}, {fid: 84, name: "普宁市", id: 771, type: 3}, {fid: 84, name: "揭东县", id: 772, type: 3}, {
            fid: 84,
            name: "揭西县",
            id: 773,
            type: 3
        }, {fid: 84, name: "惠来县", id: 774, type: 3}],
        name: "揭阳",
        id: 84,
        type: 2
    }, {
        fid: 6,
        children: [{fid: 85, name: "茂南区", id: 775, type: 3}, {fid: 85, name: "茂港区", id: 776, type: 3}, {fid: 85, name: "高州市", id: 777, type: 3}, {
            fid: 85,
            name: "化州市",
            id: 778,
            type: 3
        }, {fid: 85, name: "信宜市", id: 779, type: 3}, {fid: 85, name: "电白县", id: 780, type: 3}],
        name: "茂名",
        id: 85,
        type: 2
    }, {
        fid: 6,
        children: [{fid: 86, name: "梅县", id: 781, type: 3}, {fid: 86, name: "梅江区", id: 782, type: 3}, {fid: 86, name: "兴宁市", id: 783, type: 3}, {
            fid: 86,
            name: "大埔县",
            id: 784,
            type: 3
        }, {fid: 86, name: "丰顺县", id: 785, type: 3}, {fid: 86, name: "五华县", id: 786, type: 3}, {fid: 86, name: "平远县", id: 787, type: 3}, {
            fid: 86,
            name: "蕉岭县",
            id: 788,
            type: 3
        }],
        name: "梅州",
        id: 86,
        type: 2
    }, {
        fid: 6,
        children: [{fid: 87, name: "清城区", id: 789, type: 3}, {fid: 87, name: "英德市", id: 790, type: 3}, {fid: 87, name: "连州市", id: 791, type: 3}, {
            fid: 87,
            name: "佛冈县",
            id: 792,
            type: 3
        }, {fid: 87, name: "阳山县", id: 793, type: 3}, {fid: 87, name: "清新县", id: 794, type: 3}, {fid: 87, name: "连山", id: 795, type: 3}, {
            fid: 87,
            name: "连南",
            id: 796,
            type: 3
        }],
        name: "清远",
        id: 87,
        type: 2
    }, {
        fid: 6,
        children: [{fid: 88, name: "南澳县", id: 797, type: 3}, {fid: 88, name: "潮阳区", id: 798, type: 3}, {fid: 88, name: "澄海区", id: 799, type: 3}, {
            fid: 88,
            name: "龙湖区",
            id: 800,
            type: 3
        }, {fid: 88, name: "金平区", id: 801, type: 3}, {fid: 88, name: "濠江区", id: 802, type: 3}, {fid: 88, name: "潮南区", id: 803, type: 3}],
        name: "汕头",
        id: 88,
        type: 2
    }, {
        fid: 6,
        children: [{fid: 89, name: "城区", id: 804, type: 3}, {fid: 89, name: "陆丰市", id: 805, type: 3}, {fid: 89, name: "海丰县", id: 806, type: 3}, {
            fid: 89,
            name: "陆河县",
            id: 807,
            type: 3
        }],
        name: "汕尾",
        id: 89,
        type: 2
    }, {
        fid: 6,
        children: [{fid: 90, name: "曲江县", id: 808, type: 3}, {fid: 90, name: "浈江区", id: 809, type: 3}, {fid: 90, name: "武江区", id: 810, type: 3}, {
            fid: 90,
            name: "曲江区",
            id: 811,
            type: 3
        }, {fid: 90, name: "乐昌市", id: 812, type: 3}, {fid: 90, name: "南雄市", id: 813, type: 3}, {fid: 90, name: "始兴县", id: 814, type: 3}, {
            fid: 90,
            name: "仁化县",
            id: 815,
            type: 3
        }, {fid: 90, name: "翁源县", id: 816, type: 3}, {fid: 90, name: "新丰县", id: 817, type: 3}, {fid: 90, name: "乳源", id: 818, type: 3}],
        name: "韶关",
        id: 90,
        type: 2
    }, {
        fid: 6,
        children: [{fid: 91, name: "江城区", id: 819, type: 3}, {fid: 91, name: "阳春市", id: 820, type: 3}, {fid: 91, name: "阳西县", id: 821, type: 3}, {
            fid: 91,
            name: "阳东县",
            id: 822,
            type: 3
        }],
        name: "阳江",
        id: 91,
        type: 2
    }, {
        fid: 6,
        children: [{fid: 92, name: "云城区", id: 823, type: 3}, {fid: 92, name: "罗定市", id: 824, type: 3}, {fid: 92, name: "新兴县", id: 825, type: 3}, {
            fid: 92,
            name: "郁南县",
            id: 826,
            type: 3
        }, {fid: 92, name: "云安县", id: 827, type: 3}],
        name: "云浮",
        id: 92,
        type: 2
    }, {
        fid: 6,
        children: [{fid: 93, name: "赤坎区", id: 828, type: 3}, {fid: 93, name: "霞山区", id: 829, type: 3}, {fid: 93, name: "坡头区", id: 830, type: 3}, {
            fid: 93,
            name: "麻章区",
            id: 831,
            type: 3
        }, {fid: 93, name: "廉江市", id: 832, type: 3}, {fid: 93, name: "雷州市", id: 833, type: 3}, {fid: 93, name: "吴川市", id: 834, type: 3}, {
            fid: 93,
            name: "遂溪县",
            id: 835,
            type: 3
        }, {fid: 93, name: "徐闻县", id: 836, type: 3}],
        name: "湛江",
        id: 93,
        type: 2
    }, {
        fid: 6,
        children: [{fid: 94, name: "肇庆市", id: 837, type: 3}, {fid: 94, name: "高要市", id: 838, type: 3}, {fid: 94, name: "四会市", id: 839, type: 3}, {
            fid: 94,
            name: "广宁县",
            id: 840,
            type: 3
        }, {fid: 94, name: "怀集县", id: 841, type: 3}, {fid: 94, name: "封开县", id: 842, type: 3}, {fid: 94, name: "德庆县", id: 843, type: 3}],
        name: "肇庆",
        id: 94,
        type: 2
    }, {
        fid: 6,
        children: [{fid: 95, name: "石岐街道", id: 844, type: 3}, {fid: 95, name: "东区街道", id: 845, type: 3}, {fid: 95, name: "西区街道", id: 846, type: 3}, {
            fid: 95,
            name: "环城街道",
            id: 847,
            type: 3
        }, {fid: 95, name: "中山港街道", id: 848, type: 3}, {fid: 95, name: "五桂山街道", id: 849, type: 3}],
        name: "中山",
        id: 95,
        type: 2
    }, {
        fid: 6,
        children: [{fid: 96, name: "香洲区", id: 850, type: 3}, {fid: 96, name: "斗门区", id: 851, type: 3}, {fid: 96, name: "金湾区", id: 852, type: 3}],
        name: "珠海",
        id: 96,
        type: 2
    }],
    name: "广东",
    id: 6,
    type: 1
}, {
    fid: 1,
    children: [{
        fid: 7,
        children: [{fid: 97, name: "邕宁区", id: 853, type: 3}, {fid: 97, name: "青秀区", id: 854, type: 3}, {fid: 97, name: "兴宁区", id: 855, type: 3}, {
            fid: 97,
            name: "良庆区",
            id: 856,
            type: 3
        }, {fid: 97, name: "西乡塘区", id: 857, type: 3}, {fid: 97, name: "江南区", id: 858, type: 3}, {fid: 97, name: "武鸣县", id: 859, type: 3}, {
            fid: 97,
            name: "隆安县",
            id: 860,
            type: 3
        }, {fid: 97, name: "马山县", id: 861, type: 3}, {fid: 97, name: "上林县", id: 862, type: 3}, {fid: 97, name: "宾阳县", id: 863, type: 3}, {
            fid: 97,
            name: "横县",
            id: 864,
            type: 3
        }],
        name: "南宁",
        id: 97,
        type: 2
    }, {
        fid: 7,
        children: [{fid: 98, name: "秀峰区", id: 865, type: 3}, {fid: 98, name: "叠彩区", id: 866, type: 3}, {fid: 98, name: "象山区", id: 867, type: 3}, {
            fid: 98,
            name: "七星区",
            id: 868,
            type: 3
        }, {fid: 98, name: "雁山区", id: 869, type: 3}, {fid: 98, name: "阳朔县", id: 870, type: 3}, {fid: 98, name: "临桂县", id: 871, type: 3}, {
            fid: 98,
            name: "灵川县",
            id: 872,
            type: 3
        }, {fid: 98, name: "全州县", id: 873, type: 3}, {fid: 98, name: "平乐县", id: 874, type: 3}, {fid: 98, name: "兴安县", id: 875, type: 3}, {
            fid: 98,
            name: "灌阳县",
            id: 876,
            type: 3
        }, {fid: 98, name: "荔浦县", id: 877, type: 3}, {fid: 98, name: "资源县", id: 878, type: 3}, {fid: 98, name: "永福县", id: 879, type: 3}, {
            fid: 98,
            name: "龙胜",
            id: 880,
            type: 3
        }, {fid: 98, name: "恭城", id: 881, type: 3}],
        name: "桂林",
        id: 98,
        type: 2
    }, {
        fid: 7,
        children: [{fid: 99, name: "右江区", id: 882, type: 3}, {fid: 99, name: "凌云县", id: 883, type: 3}, {fid: 99, name: "平果县", id: 884, type: 3}, {
            fid: 99,
            name: "西林县",
            id: 885,
            type: 3
        }, {fid: 99, name: "乐业县", id: 886, type: 3}, {fid: 99, name: "德保县", id: 887, type: 3}, {fid: 99, name: "田林县", id: 888, type: 3}, {
            fid: 99,
            name: "田阳县",
            id: 889,
            type: 3
        }, {fid: 99, name: "靖西县", id: 890, type: 3}, {fid: 99, name: "田东县", id: 891, type: 3}, {fid: 99, name: "那坡县", id: 892, type: 3}, {
            fid: 99,
            name: "隆林",
            id: 893,
            type: 3
        }],
        name: "百色",
        id: 99,
        type: 2
    }, {
        fid: 7,
        children: [{fid: 100, name: "海城区", id: 894, type: 3}, {fid: 100, name: "银海区", id: 895, type: 3}, {fid: 100, name: "铁山港区", id: 896, type: 3}, {
            fid: 100,
            name: "合浦县",
            id: 897,
            type: 3
        }],
        name: "北海",
        id: 100,
        type: 2
    }, {
        fid: 7,
        children: [{fid: 101, name: "江州区", id: 898, type: 3}, {fid: 101, name: "凭祥市", id: 899, type: 3}, {fid: 101, name: "宁明县", id: 900, type: 3}, {
            fid: 101,
            name: "扶绥县",
            id: 901,
            type: 3
        }, {fid: 101, name: "龙州县", id: 902, type: 3}, {fid: 101, name: "大新县", id: 903, type: 3}, {fid: 101, name: "天等县", id: 904, type: 3}],
        name: "崇左",
        id: 101,
        type: 2
    }, {
        fid: 7,
        children: [{fid: 102, name: "港口区", id: 905, type: 3}, {fid: 102, name: "防城区", id: 906, type: 3}, {fid: 102, name: "东兴市", id: 907, type: 3}, {
            fid: 102,
            name: "上思县",
            id: 908,
            type: 3
        }],
        name: "防城港",
        id: 102,
        type: 2
    }, {
        fid: 7,
        children: [{fid: 103, name: "港北区", id: 909, type: 3}, {fid: 103, name: "港南区", id: 910, type: 3}, {fid: 103, name: "覃塘区", id: 911, type: 3}, {
            fid: 103,
            name: "桂平市",
            id: 912,
            type: 3
        }, {fid: 103, name: "平南县", id: 913, type: 3}],
        name: "贵港",
        id: 103,
        type: 2
    }, {
        fid: 7,
        children: [{fid: 104, name: "金城江区", id: 914, type: 3}, {fid: 104, name: "宜州市", id: 915, type: 3}, {fid: 104, name: "天峨县", id: 916, type: 3}, {
            fid: 104,
            name: "凤山县",
            id: 917,
            type: 3
        }, {fid: 104, name: "南丹县", id: 918, type: 3}, {fid: 104, name: "东兰县", id: 919, type: 3}, {fid: 104, name: "都安", id: 920, type: 3}, {
            fid: 104,
            name: "罗城",
            id: 921,
            type: 3
        }, {fid: 104, name: "巴马", id: 922, type: 3}, {fid: 104, name: "环江", id: 923, type: 3}, {fid: 104, name: "大化", id: 924, type: 3}],
        name: "河池",
        id: 104,
        type: 2
    }, {
        fid: 7,
        children: [{fid: 105, name: "八步区", id: 925, type: 3}, {fid: 105, name: "钟山县", id: 926, type: 3}, {fid: 105, name: "昭平县", id: 927, type: 3}, {
            fid: 105,
            name: "富川",
            id: 928,
            type: 3
        }],
        name: "贺州",
        id: 105,
        type: 2
    }, {
        fid: 7,
        children: [{fid: 106, name: "兴宾区", id: 929, type: 3}, {fid: 106, name: "合山市", id: 930, type: 3}, {fid: 106, name: "象州县", id: 931, type: 3}, {
            fid: 106,
            name: "武宣县",
            id: 932,
            type: 3
        }, {fid: 106, name: "忻城县", id: 933, type: 3}, {fid: 106, name: "金秀", id: 934, type: 3}],
        name: "来宾",
        id: 106,
        type: 2
    }, {
        fid: 7,
        children: [{fid: 107, name: "城中区", id: 935, type: 3}, {fid: 107, name: "鱼峰区", id: 936, type: 3}, {fid: 107, name: "柳北区", id: 937, type: 3}, {
            fid: 107,
            name: "柳南区",
            id: 938,
            type: 3
        }, {fid: 107, name: "柳江县", id: 939, type: 3}, {fid: 107, name: "柳城县", id: 940, type: 3}, {fid: 107, name: "鹿寨县", id: 941, type: 3}, {
            fid: 107,
            name: "融安县",
            id: 942,
            type: 3
        }, {fid: 107, name: "融水", id: 943, type: 3}, {fid: 107, name: "三江", id: 944, type: 3}],
        name: "柳州",
        id: 107,
        type: 2
    }, {
        fid: 7,
        children: [{fid: 108, name: "钦南区", id: 945, type: 3}, {fid: 108, name: "钦北区", id: 946, type: 3}, {fid: 108, name: "灵山县", id: 947, type: 3}, {
            fid: 108,
            name: "浦北县",
            id: 948,
            type: 3
        }],
        name: "钦州",
        id: 108,
        type: 2
    }, {
        fid: 7,
        children: [{fid: 109, name: "万秀区", id: 949, type: 3}, {fid: 109, name: "蝶山区", id: 950, type: 3}, {fid: 109, name: "长洲区", id: 951, type: 3}, {
            fid: 109,
            name: "岑溪市",
            id: 952,
            type: 3
        }, {fid: 109, name: "苍梧县", id: 953, type: 3}, {fid: 109, name: "藤县", id: 954, type: 3}, {fid: 109, name: "蒙山县", id: 955, type: 3}],
        name: "梧州",
        id: 109,
        type: 2
    }, {
        fid: 7,
        children: [{fid: 110, name: "玉州区", id: 956, type: 3}, {fid: 110, name: "北流市", id: 957, type: 3}, {fid: 110, name: "容县", id: 958, type: 3}, {
            fid: 110,
            name: "陆川县",
            id: 959,
            type: 3
        }, {fid: 110, name: "博白县", id: 960, type: 3}, {fid: 110, name: "兴业县", id: 961, type: 3}],
        name: "玉林",
        id: 110,
        type: 2
    }],
    name: "广西",
    id: 7,
    type: 1
}, {
    fid: 1,
    children: [{
        fid: 8,
        children: [{fid: 111, name: "南明区", id: 962, type: 3}, {fid: 111, name: "云岩区", id: 963, type: 3}, {fid: 111, name: "花溪区", id: 964, type: 3}, {
            fid: 111,
            name: "乌当区",
            id: 965,
            type: 3
        }, {fid: 111, name: "白云区", id: 966, type: 3}, {fid: 111, name: "小河区", id: 967, type: 3}, {fid: 111, name: "金阳新区", id: 968, type: 3}, {
            fid: 111,
            name: "新天园区",
            id: 969,
            type: 3
        }, {fid: 111, name: "清镇市", id: 970, type: 3}, {fid: 111, name: "开阳县", id: 971, type: 3}, {fid: 111, name: "修文县", id: 972, type: 3}, {
            fid: 111,
            name: "息烽县",
            id: 973,
            type: 3
        }],
        name: "贵阳",
        id: 111,
        type: 2
    }, {
        fid: 8,
        children: [{fid: 112, name: "西秀区", id: 974, type: 3}, {fid: 112, name: "关岭", id: 975, type: 3}, {fid: 112, name: "镇宁", id: 976, type: 3}, {
            fid: 112,
            name: "紫云",
            id: 977,
            type: 3
        }, {fid: 112, name: "平坝县", id: 978, type: 3}, {fid: 112, name: "普定县", id: 979, type: 3}],
        name: "安顺",
        id: 112,
        type: 2
    }, {
        fid: 8,
        children: [{fid: 113, name: "毕节市", id: 980, type: 3}, {fid: 113, name: "大方县", id: 981, type: 3}, {fid: 113, name: "黔西县", id: 982, type: 3}, {
            fid: 113,
            name: "金沙县",
            id: 983,
            type: 3
        }, {fid: 113, name: "织金县", id: 984, type: 3}, {fid: 113, name: "纳雍县", id: 985, type: 3}, {fid: 113, name: "赫章县", id: 986, type: 3}, {
            fid: 113,
            name: "威宁",
            id: 987,
            type: 3
        }],
        name: "毕节",
        id: 113,
        type: 2
    }, {
        fid: 8,
        children: [{fid: 114, name: "钟山区", id: 988, type: 3}, {fid: 114, name: "六枝特区", id: 989, type: 3}, {fid: 114, name: "水城县", id: 990, type: 3}, {
            fid: 114,
            name: "盘县",
            id: 991,
            type: 3
        }],
        name: "六盘水",
        id: 114,
        type: 2
    }, {
        fid: 8,
        children: [{fid: 115, name: "凯里市", id: 992, type: 3}, {fid: 115, name: "黄平县", id: 993, type: 3}, {fid: 115, name: "施秉县", id: 994, type: 3}, {
            fid: 115,
            name: "三穗县",
            id: 995,
            type: 3
        }, {fid: 115, name: "镇远县", id: 996, type: 3}, {fid: 115, name: "岑巩县", id: 997, type: 3}, {fid: 115, name: "天柱县", id: 998, type: 3}, {
            fid: 115,
            name: "锦屏县",
            id: 999,
            type: 3
        }, {fid: 115, name: "剑河县", id: 1000, type: 3}, {fid: 115, name: "台江县", id: 1001, type: 3}, {fid: 115, name: "黎平县", id: 1002, type: 3}, {
            fid: 115,
            name: "榕江县",
            id: 1003,
            type: 3
        }, {fid: 115, name: "从江县", id: 1004, type: 3}, {fid: 115, name: "雷山县", id: 1005, type: 3}, {fid: 115, name: "麻江县", id: 1006, type: 3}, {
            fid: 115,
            name: "丹寨县",
            id: 1007,
            type: 3
        }],
        name: "黔东南",
        id: 115,
        type: 2
    }, {
        fid: 8,
        children: [{fid: 116, name: "都匀市", id: 1008, type: 3}, {fid: 116, name: "福泉市", id: 1009, type: 3}, {fid: 116, name: "荔波县", id: 1010, type: 3}, {
            fid: 116,
            name: "贵定县",
            id: 1011,
            type: 3
        }, {fid: 116, name: "瓮安县", id: 1012, type: 3}, {fid: 116, name: "独山县", id: 1013, type: 3}, {fid: 116, name: "平塘县", id: 1014, type: 3}, {
            fid: 116,
            name: "罗甸县",
            id: 1015,
            type: 3
        }, {fid: 116, name: "长顺县", id: 1016, type: 3}, {fid: 116, name: "龙里县", id: 1017, type: 3}, {fid: 116, name: "惠水县", id: 1018, type: 3}, {
            fid: 116,
            name: "三都",
            id: 1019,
            type: 3
        }],
        name: "黔南",
        id: 116,
        type: 2
    }, {
        fid: 8,
        children: [{fid: 117, name: "兴义市", id: 1020, type: 3}, {fid: 117, name: "兴仁县", id: 1021, type: 3}, {fid: 117, name: "普安县", id: 1022, type: 3}, {
            fid: 117,
            name: "晴隆县",
            id: 1023,
            type: 3
        }, {fid: 117, name: "贞丰县", id: 1024, type: 3}, {fid: 117, name: "望谟县", id: 1025, type: 3}, {fid: 117, name: "册亨县", id: 1026, type: 3}, {
            fid: 117,
            name: "安龙县",
            id: 1027,
            type: 3
        }],
        name: "黔西南",
        id: 117,
        type: 2
    }, {
        fid: 8,
        children: [{fid: 118, name: "铜仁市", id: 1028, type: 3}, {fid: 118, name: "江口县", id: 1029, type: 3}, {fid: 118, name: "石阡县", id: 1030, type: 3}, {
            fid: 118,
            name: "思南县",
            id: 1031,
            type: 3
        }, {fid: 118, name: "德江县", id: 1032, type: 3}, {fid: 118, name: "玉屏", id: 1033, type: 3}, {fid: 118, name: "印江", id: 1034, type: 3}, {
            fid: 118,
            name: "沿河",
            id: 1035,
            type: 3
        }, {fid: 118, name: "松桃", id: 1036, type: 3}, {fid: 118, name: "万山特区", id: 1037, type: 3}],
        name: "铜仁",
        id: 118,
        type: 2
    }, {
        fid: 8,
        children: [{fid: 119, name: "红花岗区", id: 1038, type: 3}, {fid: 119, name: "务川县", id: 1039, type: 3}, {fid: 119, name: "道真县", id: 1040, type: 3}, {
            fid: 119,
            name: "汇川区",
            id: 1041,
            type: 3
        }, {fid: 119, name: "赤水市", id: 1042, type: 3}, {fid: 119, name: "仁怀市", id: 1043, type: 3}, {fid: 119, name: "遵义县", id: 1044, type: 3}, {
            fid: 119,
            name: "桐梓县",
            id: 1045,
            type: 3
        }, {fid: 119, name: "绥阳县", id: 1046, type: 3}, {fid: 119, name: "正安县", id: 1047, type: 3}, {fid: 119, name: "凤冈县", id: 1048, type: 3}, {
            fid: 119,
            name: "湄潭县",
            id: 1049,
            type: 3
        }, {fid: 119, name: "余庆县", id: 1050, type: 3}, {fid: 119, name: "习水县", id: 1051, type: 3}, {fid: 119, name: "道真", id: 1052, type: 3}, {
            fid: 119,
            name: "务川",
            id: 1053,
            type: 3
        }],
        name: "遵义",
        id: 119,
        type: 2
    }],
    name: "贵州",
    id: 8,
    type: 1
}, {
    fid: 1,
    children: [{
        fid: 9,
        children: [{fid: 120, name: "秀英区", id: 1054, type: 3}, {fid: 120, name: "龙华区", id: 1055, type: 3}, {fid: 120, name: "琼山区", id: 1056, type: 3}, {
            fid: 120,
            name: "美兰区",
            id: 1057,
            type: 3
        }],
        name: "海口",
        id: 120,
        type: 2
    }, {fid: 9, name: "三亚", id: 121, type: 2}, {fid: 9, name: "白沙", id: 122, type: 2}, {fid: 9, name: "保亭", id: 123, type: 2}, {
        fid: 9,
        name: "昌江",
        id: 124,
        type: 2
    }, {fid: 9, name: "澄迈县", id: 125, type: 2}, {fid: 9, name: "定安县", id: 126, type: 2}, {fid: 9, name: "东方", id: 127, type: 2}, {
        fid: 9,
        name: "乐东",
        id: 128,
        type: 2
    }, {fid: 9, name: "临高县", id: 129, type: 2}, {fid: 9, name: "陵水", id: 130, type: 2}, {fid: 9, name: "琼海", id: 131, type: 2}, {
        fid: 9,
        name: "琼中",
        id: 132,
        type: 2
    }, {fid: 9, name: "屯昌县", id: 133, type: 2}, {fid: 9, name: "万宁", id: 134, type: 2}, {fid: 9, name: "文昌", id: 135, type: 2}, {
        fid: 9,
        name: "五指山",
        id: 136,
        type: 2
    }, {
        fid: 9,
        children: [{fid: 137, name: "市区", id: 1058, type: 3}, {fid: 137, name: "洋浦开发区", id: 1059, type: 3}, {fid: 137, name: "那大镇", id: 1060, type: 3}, {
            fid: 137,
            name: "王五镇",
            id: 1061,
            type: 3
        }, {fid: 137, name: "雅星镇", id: 1062, type: 3}, {fid: 137, name: "大成镇", id: 1063, type: 3}, {fid: 137, name: "中和镇", id: 1064, type: 3}, {
            fid: 137,
            name: "峨蔓镇",
            id: 1065,
            type: 3
        }, {fid: 137, name: "南丰镇", id: 1066, type: 3}, {fid: 137, name: "白马井镇", id: 1067, type: 3}, {fid: 137, name: "兰洋镇", id: 1068, type: 3}, {
            fid: 137,
            name: "和庆镇",
            id: 1069,
            type: 3
        }, {fid: 137, name: "海头镇", id: 1070, type: 3}, {fid: 137, name: "排浦镇", id: 1071, type: 3}, {fid: 137, name: "东成镇", id: 1072, type: 3}, {
            fid: 137,
            name: "光村镇",
            id: 1073,
            type: 3
        }, {fid: 137, name: "木棠镇", id: 1074, type: 3}, {fid: 137, name: "新州镇", id: 1075, type: 3}, {fid: 137, name: "三都镇", id: 1076, type: 3}, {
            fid: 137,
            name: "其他",
            id: 1077,
            type: 3
        }],
        name: "儋州",
        id: 137,
        type: 2
    }],
    name: "海南",
    id: 9,
    type: 1
}, {
    fid: 1, children: [{
        fid: 10,
        children: [{fid: 138, name: "长安区", id: 1078, type: 3}, {fid: 138, name: "桥东区", id: 1079, type: 3}, {fid: 138, name: "桥西区", id: 1080, type: 3}, {
            fid: 138,
            name: "新华区",
            id: 1081,
            type: 3
        }, {fid: 138, name: "裕华区", id: 1082, type: 3}, {fid: 138, name: "井陉矿区", id: 1083, type: 3}, {fid: 138, name: "高新区", id: 1084, type: 3}, {
            fid: 138,
            name: "辛集市",
            id: 1085,
            type: 3
        }, {fid: 138, name: "藁城市", id: 1086, type: 3}, {fid: 138, name: "晋州市", id: 1087, type: 3}, {fid: 138, name: "新乐市", id: 1088, type: 3}, {
            fid: 138,
            name: "鹿泉市",
            id: 1089,
            type: 3
        }, {fid: 138, name: "井陉县", id: 1090, type: 3}, {fid: 138, name: "正定县", id: 1091, type: 3}, {fid: 138, name: "栾城县", id: 1092, type: 3}, {
            fid: 138,
            name: "行唐县",
            id: 1093,
            type: 3
        }, {fid: 138, name: "灵寿县", id: 1094, type: 3}, {fid: 138, name: "高邑县", id: 1095, type: 3}, {fid: 138, name: "深泽县", id: 1096, type: 3}, {
            fid: 138,
            name: "赞皇县",
            id: 1097,
            type: 3
        }, {fid: 138, name: "无极县", id: 1098, type: 3}, {fid: 138, name: "平山县", id: 1099, type: 3}, {fid: 138, name: "元氏县", id: 1100, type: 3}, {
            fid: 138,
            name: "赵县",
            id: 1101,
            type: 3
        }],
        name: "石家庄",
        id: 138,
        type: 2
    }, {
        fid: 10,
        children: [{fid: 139, name: "新市区", id: 1102, type: 3}, {fid: 139, name: "南市区", id: 1103, type: 3}, {fid: 139, name: "北市区", id: 1104, type: 3}, {
            fid: 139,
            name: "涿州市",
            id: 1105,
            type: 3
        }, {fid: 139, name: "定州市", id: 1106, type: 3}, {fid: 139, name: "安国市", id: 1107, type: 3}, {fid: 139, name: "高碑店市", id: 1108, type: 3}, {
            fid: 139,
            name: "满城县",
            id: 1109,
            type: 3
        }, {fid: 139, name: "清苑县", id: 1110, type: 3}, {fid: 139, name: "涞水县", id: 1111, type: 3}, {fid: 139, name: "阜平县", id: 1112, type: 3}, {
            fid: 139,
            name: "徐水县",
            id: 1113,
            type: 3
        }, {fid: 139, name: "定兴县", id: 1114, type: 3}, {fid: 139, name: "唐县", id: 1115, type: 3}, {fid: 139, name: "高阳县", id: 1116, type: 3}, {
            fid: 139,
            name: "容城县",
            id: 1117,
            type: 3
        }, {fid: 139, name: "涞源县", id: 1118, type: 3}, {fid: 139, name: "望都县", id: 1119, type: 3}, {fid: 139, name: "安新县", id: 1120, type: 3}, {
            fid: 139,
            name: "易县",
            id: 1121,
            type: 3
        }, {fid: 139, name: "曲阳县", id: 1122, type: 3}, {fid: 139, name: "蠡县", id: 1123, type: 3}, {fid: 139, name: "顺平县", id: 1124, type: 3}, {
            fid: 139,
            name: "博野县",
            id: 1125,
            type: 3
        }, {fid: 139, name: "雄县", id: 1126, type: 3}],
        name: "保定",
        id: 139,
        type: 2
    }, {
        fid: 10,
        children: [{fid: 140, name: "运河区", id: 1127, type: 3}, {fid: 140, name: "新华区", id: 1128, type: 3}, {fid: 140, name: "泊头市", id: 1129, type: 3}, {
            fid: 140,
            name: "任丘市",
            id: 1130,
            type: 3
        }, {fid: 140, name: "黄骅市", id: 1131, type: 3}, {fid: 140, name: "河间市", id: 1132, type: 3}, {fid: 140, name: "沧县", id: 1133, type: 3}, {
            fid: 140,
            name: "青县",
            id: 1134,
            type: 3
        }, {fid: 140, name: "东光县", id: 1135, type: 3}, {fid: 140, name: "海兴县", id: 1136, type: 3}, {fid: 140, name: "盐山县", id: 1137, type: 3}, {
            fid: 140,
            name: "肃宁县",
            id: 1138,
            type: 3
        }, {fid: 140, name: "南皮县", id: 1139, type: 3}, {fid: 140, name: "吴桥县", id: 1140, type: 3}, {fid: 140, name: "献县", id: 1141, type: 3}, {
            fid: 140,
            name: "孟村",
            id: 1142,
            type: 3
        }],
        name: "沧州",
        id: 140,
        type: 2
    }, {
        fid: 10,
        children: [{fid: 141, name: "双桥区", id: 1143, type: 3}, {fid: 141, name: "双滦区", id: 1144, type: 3}, {fid: 141, name: "鹰手营子矿区", id: 1145, type: 3}, {
            fid: 141,
            name: "承德县",
            id: 1146,
            type: 3
        }, {fid: 141, name: "兴隆县", id: 1147, type: 3}, {fid: 141, name: "平泉县", id: 1148, type: 3}, {fid: 141, name: "滦平县", id: 1149, type: 3}, {
            fid: 141,
            name: "隆化县",
            id: 1150,
            type: 3
        }, {fid: 141, name: "丰宁", id: 1151, type: 3}, {fid: 141, name: "宽城", id: 1152, type: 3}, {fid: 141, name: "围场", id: 1153, type: 3}],
        name: "承德",
        id: 141,
        type: 2
    }, {
        fid: 10,
        children: [{fid: 142, name: "从台区", id: 1154, type: 3}, {fid: 142, name: "复兴区", id: 1155, type: 3}, {fid: 142, name: "邯山区", id: 1156, type: 3}, {
            fid: 142,
            name: "峰峰矿区",
            id: 1157,
            type: 3
        }, {fid: 142, name: "武安市", id: 1158, type: 3}, {fid: 142, name: "邯郸县", id: 1159, type: 3}, {fid: 142, name: "临漳县", id: 1160, type: 3}, {
            fid: 142,
            name: "成安县",
            id: 1161,
            type: 3
        }, {fid: 142, name: "大名县", id: 1162, type: 3}, {fid: 142, name: "涉县", id: 1163, type: 3}, {fid: 142, name: "磁县", id: 1164, type: 3}, {
            fid: 142,
            name: "肥乡县",
            id: 1165,
            type: 3
        }, {fid: 142, name: "永年县", id: 1166, type: 3}, {fid: 142, name: "邱县", id: 1167, type: 3}, {fid: 142, name: "鸡泽县", id: 1168, type: 3}, {
            fid: 142,
            name: "广平县",
            id: 1169,
            type: 3
        }, {fid: 142, name: "馆陶县", id: 1170, type: 3}, {fid: 142, name: "魏县", id: 1171, type: 3}, {fid: 142, name: "曲周县", id: 1172, type: 3}],
        name: "邯郸",
        id: 142,
        type: 2
    }, {
        fid: 10,
        children: [{fid: 143, name: "桃城区", id: 1173, type: 3}, {fid: 143, name: "冀州市", id: 1174, type: 3}, {fid: 143, name: "深州市", id: 1175, type: 3}, {
            fid: 143,
            name: "枣强县",
            id: 1176,
            type: 3
        }, {fid: 143, name: "武邑县", id: 1177, type: 3}, {fid: 143, name: "武强县", id: 1178, type: 3}, {fid: 143, name: "饶阳县", id: 1179, type: 3}, {
            fid: 143,
            name: "安平县",
            id: 1180,
            type: 3
        }, {fid: 143, name: "故城县", id: 1181, type: 3}, {fid: 143, name: "景县", id: 1182, type: 3}, {fid: 143, name: "阜城县", id: 1183, type: 3}],
        name: "衡水",
        id: 143,
        type: 2
    }, {
        fid: 10,
        children: [{fid: 144, name: "安次区", id: 1184, type: 3}, {fid: 144, name: "广阳区", id: 1185, type: 3}, {fid: 144, name: "霸州市", id: 1186, type: 3}, {
            fid: 144,
            name: "三河市",
            id: 1187,
            type: 3
        }, {fid: 144, name: "固安县", id: 1188, type: 3}, {fid: 144, name: "永清县", id: 1189, type: 3}, {fid: 144, name: "香河县", id: 1190, type: 3}, {
            fid: 144,
            name: "大城县",
            id: 1191,
            type: 3
        }, {fid: 144, name: "文安县", id: 1192, type: 3}, {fid: 144, name: "大厂", id: 1193, type: 3}],
        name: "廊坊",
        id: 144,
        type: 2
    }, {
        fid: 10,
        children: [{fid: 145, name: "海港区", id: 1194, type: 3}, {fid: 145, name: "山海关区", id: 1195, type: 3}, {fid: 145, name: "北戴河区", id: 1196, type: 3}, {
            fid: 145,
            name: "昌黎县",
            id: 1197,
            type: 3
        }, {fid: 145, name: "抚宁县", id: 1198, type: 3}, {fid: 145, name: "卢龙县", id: 1199, type: 3}, {fid: 145, name: "青龙", id: 1200, type: 3}],
        name: "秦皇岛",
        id: 145,
        type: 2
    }, {
        fid: 10,
        children: [{fid: 146, name: "路北区", id: 1201, type: 3}, {fid: 146, name: "路南区", id: 1202, type: 3}, {fid: 146, name: "古冶区", id: 1203, type: 3}, {
            fid: 146,
            name: "开平区",
            id: 1204,
            type: 3
        }, {fid: 146, name: "丰南区", id: 1205, type: 3}, {fid: 146, name: "丰润区", id: 1206, type: 3}, {fid: 146, name: "遵化市", id: 1207, type: 3}, {
            fid: 146,
            name: "迁安市",
            id: 1208,
            type: 3
        }, {fid: 146, name: "滦县", id: 1209, type: 3}, {fid: 146, name: "滦南县", id: 1210, type: 3}, {fid: 146, name: "乐亭县", id: 1211, type: 3}, {
            fid: 146,
            name: "迁西县",
            id: 1212,
            type: 3
        }, {fid: 146, name: "玉田县", id: 1213, type: 3}, {fid: 146, name: "唐海县", id: 1214, type: 3}],
        name: "唐山",
        id: 146,
        type: 2
    }, {
        fid: 10,
        children: [{fid: 147, name: "桥东区", id: 1215, type: 3}, {fid: 147, name: "桥西区", id: 1216, type: 3}, {fid: 147, name: "南宫市", id: 1217, type: 3}, {
            fid: 147,
            name: "沙河市",
            id: 1218,
            type: 3
        }, {fid: 147, name: "邢台县", id: 1219, type: 3}, {fid: 147, name: "临城县", id: 1220, type: 3}, {fid: 147, name: "内丘县", id: 1221, type: 3}, {
            fid: 147,
            name: "柏乡县",
            id: 1222,
            type: 3
        }, {fid: 147, name: "隆尧县", id: 1223, type: 3}, {fid: 147, name: "任县", id: 1224, type: 3}, {fid: 147, name: "南和县", id: 1225, type: 3}, {
            fid: 147,
            name: "宁晋县",
            id: 1226,
            type: 3
        }, {fid: 147, name: "巨鹿县", id: 1227, type: 3}, {fid: 147, name: "新河县", id: 1228, type: 3}, {fid: 147, name: "广宗县", id: 1229, type: 3}, {
            fid: 147,
            name: "平乡县",
            id: 1230,
            type: 3
        }, {fid: 147, name: "威县", id: 1231, type: 3}, {fid: 147, name: "清河县", id: 1232, type: 3}, {fid: 147, name: "临西县", id: 1233, type: 3}],
        name: "邢台",
        id: 147,
        type: 2
    }, {
        fid: 10,
        children: [{fid: 148, name: "桥西区", id: 1234, type: 3}, {fid: 148, name: "桥东区", id: 1235, type: 3}, {fid: 148, name: "宣化区", id: 1236, type: 3}, {
            fid: 148,
            name: "下花园区",
            id: 1237,
            type: 3
        }, {fid: 148, name: "宣化县", id: 1238, type: 3}, {fid: 148, name: "张北县", id: 1239, type: 3}, {fid: 148, name: "康保县", id: 1240, type: 3}, {
            fid: 148,
            name: "沽源县",
            id: 1241,
            type: 3
        }, {fid: 148, name: "尚义县", id: 1242, type: 3}, {fid: 148, name: "蔚县", id: 1243, type: 3}, {fid: 148, name: "阳原县", id: 1244, type: 3}, {
            fid: 148,
            name: "怀安县",
            id: 1245,
            type: 3
        }, {fid: 148, name: "万全县", id: 1246, type: 3}, {fid: 148, name: "怀来县", id: 1247, type: 3}, {fid: 148, name: "涿鹿县", id: 1248, type: 3}, {
            fid: 148,
            name: "赤城县",
            id: 1249,
            type: 3
        }, {fid: 148, name: "崇礼县", id: 1250, type: 3}],
        name: "张家口",
        id: 148,
        type: 2
    }], name: "河北", id: 10, type: 1
}, {
    fid: 1,
    children: [{
        fid: 11,
        children: [{fid: 149, name: "金水区", id: 1251, type: 3}, {fid: 149, name: "邙山区", id: 1252, type: 3}, {fid: 149, name: "二七区", id: 1253, type: 3}, {
            fid: 149,
            name: "管城区",
            id: 1254,
            type: 3
        }, {fid: 149, name: "中原区", id: 1255, type: 3}, {fid: 149, name: "上街区", id: 1256, type: 3}, {fid: 149, name: "惠济区", id: 1257, type: 3}, {
            fid: 149,
            name: "郑东新区",
            id: 1258,
            type: 3
        }, {fid: 149, name: "经济技术开发区", id: 1259, type: 3}, {fid: 149, name: "高新开发区", id: 1260, type: 3}, {fid: 149, name: "出口加工区", id: 1261, type: 3}, {
            fid: 149,
            name: "巩义市",
            id: 1262,
            type: 3
        }, {fid: 149, name: "荥阳市", id: 1263, type: 3}, {fid: 149, name: "新密市", id: 1264, type: 3}, {fid: 149, name: "新郑市", id: 1265, type: 3}, {
            fid: 149,
            name: "登封市",
            id: 1266,
            type: 3
        }, {fid: 149, name: "中牟县", id: 1267, type: 3}],
        name: "郑州",
        id: 149,
        type: 2
    }, {
        fid: 11,
        children: [{fid: 150, name: "西工区", id: 1268, type: 3}, {fid: 150, name: "老城区", id: 1269, type: 3}, {fid: 150, name: "涧西区", id: 1270, type: 3}, {
            fid: 150,
            name: "瀍河回族区",
            id: 1271,
            type: 3
        }, {fid: 150, name: "洛龙区", id: 1272, type: 3}, {fid: 150, name: "吉利区", id: 1273, type: 3}, {fid: 150, name: "偃师市", id: 1274, type: 3}, {
            fid: 150,
            name: "孟津县",
            id: 1275,
            type: 3
        }, {fid: 150, name: "新安县", id: 1276, type: 3}, {fid: 150, name: "栾川县", id: 1277, type: 3}, {fid: 150, name: "嵩县", id: 1278, type: 3}, {
            fid: 150,
            name: "汝阳县",
            id: 1279,
            type: 3
        }, {fid: 150, name: "宜阳县", id: 1280, type: 3}, {fid: 150, name: "洛宁县", id: 1281, type: 3}, {fid: 150, name: "伊川县", id: 1282, type: 3}],
        name: "洛阳",
        id: 150,
        type: 2
    }, {
        fid: 11,
        children: [{fid: 151, name: "鼓楼区", id: 1283, type: 3}, {fid: 151, name: "龙亭区", id: 1284, type: 3}, {fid: 151, name: "顺河回族区", id: 1285, type: 3}, {
            fid: 151,
            name: "金明区",
            id: 1286,
            type: 3
        }, {fid: 151, name: "禹王台区", id: 1287, type: 3}, {fid: 151, name: "杞县", id: 1288, type: 3}, {fid: 151, name: "通许县", id: 1289, type: 3}, {
            fid: 151,
            name: "尉氏县",
            id: 1290,
            type: 3
        }, {fid: 151, name: "开封县", id: 1291, type: 3}, {fid: 151, name: "兰考县", id: 1292, type: 3}],
        name: "开封",
        id: 151,
        type: 2
    }, {
        fid: 11,
        children: [{fid: 152, name: "北关区", id: 1293, type: 3}, {fid: 152, name: "文峰区", id: 1294, type: 3}, {fid: 152, name: "殷都区", id: 1295, type: 3}, {
            fid: 152,
            name: "龙安区",
            id: 1296,
            type: 3
        }, {fid: 152, name: "林州市", id: 1297, type: 3}, {fid: 152, name: "安阳县", id: 1298, type: 3}, {fid: 152, name: "汤阴县", id: 1299, type: 3}, {
            fid: 152,
            name: "滑县",
            id: 1300,
            type: 3
        }, {fid: 152, name: "内黄县", id: 1301, type: 3}],
        name: "安阳",
        id: 152,
        type: 2
    }, {
        fid: 11,
        children: [{fid: 153, name: "淇滨区", id: 1302, type: 3}, {fid: 153, name: "山城区", id: 1303, type: 3}, {fid: 153, name: "鹤山区", id: 1304, type: 3}, {
            fid: 153,
            name: "浚县",
            id: 1305,
            type: 3
        }, {fid: 153, name: "淇县", id: 1306, type: 3}],
        name: "鹤壁",
        id: 153,
        type: 2
    }, {fid: 11, children: [{fid: 154, name: "济源市", id: 1307, type: 3}], name: "济源", id: 154, type: 2}, {
        fid: 11,
        children: [{fid: 155, name: "解放区", id: 1308, type: 3}, {fid: 155, name: "中站区", id: 1309, type: 3}, {fid: 155, name: "马村区", id: 1310, type: 3}, {
            fid: 155,
            name: "山阳区",
            id: 1311,
            type: 3
        }, {fid: 155, name: "沁阳市", id: 1312, type: 3}, {fid: 155, name: "孟州市", id: 1313, type: 3}, {fid: 155, name: "修武县", id: 1314, type: 3}, {
            fid: 155,
            name: "博爱县",
            id: 1315,
            type: 3
        }, {fid: 155, name: "武陟县", id: 1316, type: 3}, {fid: 155, name: "温县", id: 1317, type: 3}],
        name: "焦作",
        id: 155,
        type: 2
    }, {
        fid: 11,
        children: [{fid: 156, name: "卧龙区", id: 1318, type: 3}, {fid: 156, name: "宛城区", id: 1319, type: 3}, {fid: 156, name: "邓州市", id: 1320, type: 3}, {
            fid: 156,
            name: "南召县",
            id: 1321,
            type: 3
        }, {fid: 156, name: "方城县", id: 1322, type: 3}, {fid: 156, name: "西峡县", id: 1323, type: 3}, {fid: 156, name: "镇平县", id: 1324, type: 3}, {
            fid: 156,
            name: "内乡县",
            id: 1325,
            type: 3
        }, {fid: 156, name: "淅川县", id: 1326, type: 3}, {fid: 156, name: "社旗县", id: 1327, type: 3}, {fid: 156, name: "唐河县", id: 1328, type: 3}, {
            fid: 156,
            name: "新野县",
            id: 1329,
            type: 3
        }, {fid: 156, name: "桐柏县", id: 1330, type: 3}],
        name: "南阳",
        id: 156,
        type: 2
    }, {
        fid: 11,
        children: [{fid: 157, name: "新华区", id: 1331, type: 3}, {fid: 157, name: "卫东区", id: 1332, type: 3}, {fid: 157, name: "湛河区", id: 1333, type: 3}, {
            fid: 157,
            name: "石龙区",
            id: 1334,
            type: 3
        }, {fid: 157, name: "舞钢市", id: 1335, type: 3}, {fid: 157, name: "汝州市", id: 1336, type: 3}, {fid: 157, name: "宝丰县", id: 1337, type: 3}, {
            fid: 157,
            name: "叶县",
            id: 1338,
            type: 3
        }, {fid: 157, name: "鲁山县", id: 1339, type: 3}, {fid: 157, name: "郏县", id: 1340, type: 3}],
        name: "平顶山",
        id: 157,
        type: 2
    }, {
        fid: 11,
        children: [{fid: 158, name: "湖滨区", id: 1341, type: 3}, {fid: 158, name: "义马市", id: 1342, type: 3}, {fid: 158, name: "灵宝市", id: 1343, type: 3}, {
            fid: 158,
            name: "渑池县",
            id: 1344,
            type: 3
        }, {fid: 158, name: "陕县", id: 1345, type: 3}, {fid: 158, name: "卢氏县", id: 1346, type: 3}],
        name: "三门峡",
        id: 158,
        type: 2
    }, {
        fid: 11,
        children: [{fid: 159, name: "梁园区", id: 1347, type: 3}, {fid: 159, name: "睢阳区", id: 1348, type: 3}, {fid: 159, name: "永城市", id: 1349, type: 3}, {
            fid: 159,
            name: "民权县",
            id: 1350,
            type: 3
        }, {fid: 159, name: "睢县", id: 1351, type: 3}, {fid: 159, name: "宁陵县", id: 1352, type: 3}, {fid: 159, name: "虞城县", id: 1353, type: 3}, {
            fid: 159,
            name: "柘城县",
            id: 1354,
            type: 3
        }, {fid: 159, name: "夏邑县", id: 1355, type: 3}],
        name: "商丘",
        id: 159,
        type: 2
    }, {
        fid: 11,
        children: [{fid: 160, name: "卫滨区", id: 1356, type: 3}, {fid: 160, name: "红旗区", id: 1357, type: 3}, {fid: 160, name: "凤泉区", id: 1358, type: 3}, {
            fid: 160,
            name: "牧野区",
            id: 1359,
            type: 3
        }, {fid: 160, name: "卫辉市", id: 1360, type: 3}, {fid: 160, name: "辉县市", id: 1361, type: 3}, {fid: 160, name: "新乡县", id: 1362, type: 3}, {
            fid: 160,
            name: "获嘉县",
            id: 1363,
            type: 3
        }, {fid: 160, name: "原阳县", id: 1364, type: 3}, {fid: 160, name: "延津县", id: 1365, type: 3}, {fid: 160, name: "封丘县", id: 1366, type: 3}, {
            fid: 160,
            name: "长垣县",
            id: 1367,
            type: 3
        }],
        name: "新乡",
        id: 160,
        type: 2
    }, {
        fid: 11,
        children: [{fid: 161, name: "浉河区", id: 1368, type: 3}, {fid: 161, name: "平桥区", id: 1369, type: 3}, {fid: 161, name: "罗山县", id: 1370, type: 3}, {
            fid: 161,
            name: "光山县",
            id: 1371,
            type: 3
        }, {fid: 161, name: "新县", id: 1372, type: 3}, {fid: 161, name: "商城县", id: 1373, type: 3}, {fid: 161, name: "固始县", id: 1374, type: 3}, {
            fid: 161,
            name: "潢川县",
            id: 1375,
            type: 3
        }, {fid: 161, name: "淮滨县", id: 1376, type: 3}, {fid: 161, name: "息县", id: 1377, type: 3}],
        name: "信阳",
        id: 161,
        type: 2
    }, {
        fid: 11,
        children: [{fid: 162, name: "魏都区", id: 1378, type: 3}, {fid: 162, name: "禹州市", id: 1379, type: 3}, {fid: 162, name: "长葛市", id: 1380, type: 3}, {
            fid: 162,
            name: "许昌县",
            id: 1381,
            type: 3
        }, {fid: 162, name: "鄢陵县", id: 1382, type: 3}, {fid: 162, name: "襄城县", id: 1383, type: 3}],
        name: "许昌",
        id: 162,
        type: 2
    }, {
        fid: 11,
        children: [{fid: 163, name: "川汇区", id: 1384, type: 3}, {fid: 163, name: "项城市", id: 1385, type: 3}, {fid: 163, name: "扶沟县", id: 1386, type: 3}, {
            fid: 163,
            name: "西华县",
            id: 1387,
            type: 3
        }, {fid: 163, name: "商水县", id: 1388, type: 3}, {fid: 163, name: "沈丘县", id: 1389, type: 3}, {fid: 163, name: "郸城县", id: 1390, type: 3}, {
            fid: 163,
            name: "淮阳县",
            id: 1391,
            type: 3
        }, {fid: 163, name: "太康县", id: 1392, type: 3}, {fid: 163, name: "鹿邑县", id: 1393, type: 3}],
        name: "周口",
        id: 163,
        type: 2
    }, {
        fid: 11,
        children: [{fid: 164, name: "驿城区", id: 1394, type: 3}, {fid: 164, name: "西平县", id: 1395, type: 3}, {fid: 164, name: "上蔡县", id: 1396, type: 3}, {
            fid: 164,
            name: "平舆县",
            id: 1397,
            type: 3
        }, {fid: 164, name: "正阳县", id: 1398, type: 3}, {fid: 164, name: "确山县", id: 1399, type: 3}, {fid: 164, name: "泌阳县", id: 1400, type: 3}, {
            fid: 164,
            name: "汝南县",
            id: 1401,
            type: 3
        }, {fid: 164, name: "遂平县", id: 1402, type: 3}, {fid: 164, name: "新蔡县", id: 1403, type: 3}],
        name: "驻马店",
        id: 164,
        type: 2
    }, {
        fid: 11,
        children: [{fid: 165, name: "郾城区", id: 1404, type: 3}, {fid: 165, name: "源汇区", id: 1405, type: 3}, {fid: 165, name: "召陵区", id: 1406, type: 3}, {
            fid: 165,
            name: "舞阳县",
            id: 1407,
            type: 3
        }, {fid: 165, name: "临颍县", id: 1408, type: 3}],
        name: "漯河",
        id: 165,
        type: 2
    }, {
        fid: 11,
        children: [{fid: 166, name: "华龙区", id: 1409, type: 3}, {fid: 166, name: "清丰县", id: 1410, type: 3}, {fid: 166, name: "南乐县", id: 1411, type: 3}, {
            fid: 166,
            name: "范县",
            id: 1412,
            type: 3
        }, {fid: 166, name: "台前县", id: 1413, type: 3}, {fid: 166, name: "濮阳县", id: 1414, type: 3}],
        name: "濮阳",
        id: 166,
        type: 2
    }],
    name: "河南",
    id: 11,
    type: 1
}, {
    fid: 1,
    children: [{
        fid: 12,
        children: [{fid: 167, name: "道里区", id: 1415, type: 3}, {fid: 167, name: "南岗区", id: 1416, type: 3}, {fid: 167, name: "动力区", id: 1417, type: 3}, {
            fid: 167,
            name: "平房区",
            id: 1418,
            type: 3
        }, {fid: 167, name: "香坊区", id: 1419, type: 3}, {fid: 167, name: "太平区", id: 1420, type: 3}, {fid: 167, name: "道外区", id: 1421, type: 3}, {
            fid: 167,
            name: "阿城区",
            id: 1422,
            type: 3
        }, {fid: 167, name: "呼兰区", id: 1423, type: 3}, {fid: 167, name: "松北区", id: 1424, type: 3}, {fid: 167, name: "尚志市", id: 1425, type: 3}, {
            fid: 167,
            name: "双城市",
            id: 1426,
            type: 3
        }, {fid: 167, name: "五常市", id: 1427, type: 3}, {fid: 167, name: "方正县", id: 1428, type: 3}, {fid: 167, name: "宾县", id: 1429, type: 3}, {
            fid: 167,
            name: "依兰县",
            id: 1430,
            type: 3
        }, {fid: 167, name: "巴彦县", id: 1431, type: 3}, {fid: 167, name: "通河县", id: 1432, type: 3}, {fid: 167, name: "木兰县", id: 1433, type: 3}, {
            fid: 167,
            name: "延寿县",
            id: 1434,
            type: 3
        }],
        name: "哈尔滨",
        id: 167,
        type: 2
    }, {
        fid: 12,
        children: [{fid: 168, name: "萨尔图区", id: 1435, type: 3}, {fid: 168, name: "红岗区", id: 1436, type: 3}, {fid: 168, name: "龙凤区", id: 1437, type: 3}, {
            fid: 168,
            name: "让胡路区",
            id: 1438,
            type: 3
        }, {fid: 168, name: "大同区", id: 1439, type: 3}, {fid: 168, name: "肇州县", id: 1440, type: 3}, {fid: 168, name: "肇源县", id: 1441, type: 3}, {
            fid: 168,
            name: "林甸县",
            id: 1442,
            type: 3
        }, {fid: 168, name: "杜尔伯特", id: 1443, type: 3}],
        name: "大庆",
        id: 168,
        type: 2
    }, {
        fid: 12,
        children: [{fid: 169, name: "呼玛县", id: 1444, type: 3}, {fid: 169, name: "漠河县", id: 1445, type: 3}, {fid: 169, name: "塔河县", id: 1446, type: 3}],
        name: "大兴安岭",
        id: 169,
        type: 2
    }, {
        fid: 12,
        children: [{fid: 170, name: "兴山区", id: 1447, type: 3}, {fid: 170, name: "工农区", id: 1448, type: 3}, {fid: 170, name: "南山区", id: 1449, type: 3}, {
            fid: 170,
            name: "兴安区",
            id: 1450,
            type: 3
        }, {fid: 170, name: "向阳区", id: 1451, type: 3}, {fid: 170, name: "东山区", id: 1452, type: 3}, {fid: 170, name: "萝北县", id: 1453, type: 3}, {
            fid: 170,
            name: "绥滨县",
            id: 1454,
            type: 3
        }],
        name: "鹤岗",
        id: 170,
        type: 2
    }, {
        fid: 12,
        children: [{fid: 171, name: "爱辉区", id: 1455, type: 3}, {fid: 171, name: "五大连池市", id: 1456, type: 3}, {fid: 171, name: "北安市", id: 1457, type: 3}, {
            fid: 171,
            name: "嫩江县",
            id: 1458,
            type: 3
        }, {fid: 171, name: "逊克县", id: 1459, type: 3}, {fid: 171, name: "孙吴县", id: 1460, type: 3}],
        name: "黑河",
        id: 171,
        type: 2
    }, {
        fid: 12,
        children: [{fid: 172, name: "鸡冠区", id: 1461, type: 3}, {fid: 172, name: "恒山区", id: 1462, type: 3}, {fid: 172, name: "城子河区", id: 1463, type: 3}, {
            fid: 172,
            name: "滴道区",
            id: 1464,
            type: 3
        }, {fid: 172, name: "梨树区", id: 1465, type: 3}, {fid: 172, name: "虎林市", id: 1466, type: 3}, {fid: 172, name: "密山市", id: 1467, type: 3}, {
            fid: 172,
            name: "鸡东县",
            id: 1468,
            type: 3
        }],
        name: "鸡西",
        id: 172,
        type: 2
    }, {
        fid: 12,
        children: [{fid: 173, name: "前进区", id: 1469, type: 3}, {fid: 173, name: "郊区", id: 1470, type: 3}, {fid: 173, name: "向阳区", id: 1471, type: 3}, {
            fid: 173,
            name: "东风区",
            id: 1472,
            type: 3
        }, {fid: 173, name: "同江市", id: 1473, type: 3}, {fid: 173, name: "富锦市", id: 1474, type: 3}, {fid: 173, name: "桦南县", id: 1475, type: 3}, {
            fid: 173,
            name: "桦川县",
            id: 1476,
            type: 3
        }, {fid: 173, name: "汤原县", id: 1477, type: 3}, {fid: 173, name: "抚远县", id: 1478, type: 3}],
        name: "佳木斯",
        id: 173,
        type: 2
    }, {
        fid: 12,
        children: [{fid: 174, name: "爱民区", id: 1479, type: 3}, {fid: 174, name: "东安区", id: 1480, type: 3}, {fid: 174, name: "阳明区", id: 1481, type: 3}, {
            fid: 174,
            name: "西安区",
            id: 1482,
            type: 3
        }, {fid: 174, name: "绥芬河市", id: 1483, type: 3}, {fid: 174, name: "海林市", id: 1484, type: 3}, {fid: 174, name: "宁安市", id: 1485, type: 3}, {
            fid: 174,
            name: "穆棱市",
            id: 1486,
            type: 3
        }, {fid: 174, name: "东宁县", id: 1487, type: 3}, {fid: 174, name: "林口县", id: 1488, type: 3}],
        name: "牡丹江",
        id: 174,
        type: 2
    }, {
        fid: 12,
        children: [{fid: 175, name: "桃山区", id: 1489, type: 3}, {fid: 175, name: "新兴区", id: 1490, type: 3}, {fid: 175, name: "茄子河区", id: 1491, type: 3}, {
            fid: 175,
            name: "勃利县",
            id: 1492,
            type: 3
        }],
        name: "七台河",
        id: 175,
        type: 2
    }, {
        fid: 12,
        children: [{fid: 176, name: "龙沙区", id: 1493, type: 3}, {fid: 176, name: "昂昂溪区", id: 1494, type: 3}, {fid: 176, name: "铁峰区", id: 1495, type: 3}, {
            fid: 176,
            name: "建华区",
            id: 1496,
            type: 3
        }, {fid: 176, name: "富拉尔基区", id: 1497, type: 3}, {fid: 176, name: "碾子山区", id: 1498, type: 3}, {fid: 176, name: "梅里斯达斡尔区", id: 1499, type: 3}, {
            fid: 176,
            name: "讷河市",
            id: 1500,
            type: 3
        }, {fid: 176, name: "龙江县", id: 1501, type: 3}, {fid: 176, name: "依安县", id: 1502, type: 3}, {fid: 176, name: "泰来县", id: 1503, type: 3}, {
            fid: 176,
            name: "甘南县",
            id: 1504,
            type: 3
        }, {fid: 176, name: "富裕县", id: 1505, type: 3}, {fid: 176, name: "克山县", id: 1506, type: 3}, {fid: 176, name: "克东县", id: 1507, type: 3}, {
            fid: 176,
            name: "拜泉县",
            id: 1508,
            type: 3
        }],
        name: "齐齐哈尔",
        id: 176,
        type: 2
    }, {
        fid: 12,
        children: [{fid: 177, name: "尖山区", id: 1509, type: 3}, {fid: 177, name: "岭东区", id: 1510, type: 3}, {fid: 177, name: "四方台区", id: 1511, type: 3}, {
            fid: 177,
            name: "宝山区",
            id: 1512,
            type: 3
        }, {fid: 177, name: "集贤县", id: 1513, type: 3}, {fid: 177, name: "友谊县", id: 1514, type: 3}, {fid: 177, name: "宝清县", id: 1515, type: 3}, {
            fid: 177,
            name: "饶河县",
            id: 1516,
            type: 3
        }],
        name: "双鸭山",
        id: 177,
        type: 2
    }, {
        fid: 12,
        children: [{fid: 178, name: "北林区", id: 1517, type: 3}, {fid: 178, name: "安达市", id: 1518, type: 3}, {fid: 178, name: "肇东市", id: 1519, type: 3}, {
            fid: 178,
            name: "海伦市",
            id: 1520,
            type: 3
        }, {fid: 178, name: "望奎县", id: 1521, type: 3}, {fid: 178, name: "兰西县", id: 1522, type: 3}, {fid: 178, name: "青冈县", id: 1523, type: 3}, {
            fid: 178,
            name: "庆安县",
            id: 1524,
            type: 3
        }, {fid: 178, name: "明水县", id: 1525, type: 3}, {fid: 178, name: "绥棱县", id: 1526, type: 3}],
        name: "绥化",
        id: 178,
        type: 2
    }, {
        fid: 12,
        children: [{fid: 179, name: "伊春区", id: 1527, type: 3}, {fid: 179, name: "带岭区", id: 1528, type: 3}, {fid: 179, name: "南岔区", id: 1529, type: 3}, {
            fid: 179,
            name: "金山屯区",
            id: 1530,
            type: 3
        }, {fid: 179, name: "西林区", id: 1531, type: 3}, {fid: 179, name: "美溪区", id: 1532, type: 3}, {fid: 179, name: "乌马河区", id: 1533, type: 3}, {
            fid: 179,
            name: "翠峦区",
            id: 1534,
            type: 3
        }, {fid: 179, name: "友好区", id: 1535, type: 3}, {fid: 179, name: "上甘岭区", id: 1536, type: 3}, {fid: 179, name: "五营区", id: 1537, type: 3}, {
            fid: 179,
            name: "红星区",
            id: 1538,
            type: 3
        }, {fid: 179, name: "新青区", id: 1539, type: 3}, {fid: 179, name: "汤旺河区", id: 1540, type: 3}, {fid: 179, name: "乌伊岭区", id: 1541, type: 3}, {
            fid: 179,
            name: "铁力市",
            id: 1542,
            type: 3
        }, {fid: 179, name: "嘉荫县", id: 1543, type: 3}],
        name: "伊春",
        id: 179,
        type: 2
    }],
    name: "黑龙江",
    id: 12,
    type: 1
}, {
    fid: 1,
    children: [{
        fid: 13,
        children: [{fid: 180, name: "江岸区", id: 1544, type: 3}, {fid: 180, name: "武昌区", id: 1545, type: 3}, {fid: 180, name: "江汉区", id: 1546, type: 3}, {
            fid: 180,
            name: "硚口区",
            id: 1547,
            type: 3
        }, {fid: 180, name: "汉阳区", id: 1548, type: 3}, {fid: 180, name: "青山区", id: 1549, type: 3}, {fid: 180, name: "洪山区", id: 1550, type: 3}, {
            fid: 180,
            name: "东西湖区",
            id: 1551,
            type: 3
        }, {fid: 180, name: "汉南区", id: 1552, type: 3}, {fid: 180, name: "蔡甸区", id: 1553, type: 3}, {fid: 180, name: "江夏区", id: 1554, type: 3}, {
            fid: 180,
            name: "黄陂区",
            id: 1555,
            type: 3
        }, {fid: 180, name: "新洲区", id: 1556, type: 3}, {fid: 180, name: "经济开发区", id: 1557, type: 3}],
        name: "武汉",
        id: 180,
        type: 2
    }, {fid: 13, children: [{fid: 181, name: "仙桃市", id: 1558, type: 3}], name: "仙桃", id: 181, type: 2}, {
        fid: 13,
        children: [{fid: 182, name: "鄂城区", id: 1559, type: 3}, {fid: 182, name: "华容区", id: 1560, type: 3}, {fid: 182, name: "梁子湖区", id: 1561, type: 3}],
        name: "鄂州",
        id: 182,
        type: 2
    }, {
        fid: 13,
        children: [{fid: 183, name: "黄州区", id: 1562, type: 3}, {fid: 183, name: "麻城市", id: 1563, type: 3}, {fid: 183, name: "武穴市", id: 1564, type: 3}, {
            fid: 183,
            name: "团风县",
            id: 1565,
            type: 3
        }, {fid: 183, name: "红安县", id: 1566, type: 3}, {fid: 183, name: "罗田县", id: 1567, type: 3}, {fid: 183, name: "英山县", id: 1568, type: 3}, {
            fid: 183,
            name: "浠水县",
            id: 1569,
            type: 3
        }, {fid: 183, name: "蕲春县", id: 1570, type: 3}, {fid: 183, name: "黄梅县", id: 1571, type: 3}],
        name: "黄冈",
        id: 183,
        type: 2
    }, {
        fid: 13,
        children: [{fid: 184, name: "黄石港区", id: 1572, type: 3}, {fid: 184, name: "西塞山区", id: 1573, type: 3}, {fid: 184, name: "下陆区", id: 1574, type: 3}, {
            fid: 184,
            name: "铁山区",
            id: 1575,
            type: 3
        }, {fid: 184, name: "大冶市", id: 1576, type: 3}, {fid: 184, name: "阳新县", id: 1577, type: 3}],
        name: "黄石",
        id: 184,
        type: 2
    }, {
        fid: 13,
        children: [{fid: 185, name: "东宝区", id: 1578, type: 3}, {fid: 185, name: "掇刀区", id: 1579, type: 3}, {fid: 185, name: "钟祥市", id: 1580, type: 3}, {
            fid: 185,
            name: "京山县",
            id: 1581,
            type: 3
        }, {fid: 185, name: "沙洋县", id: 1582, type: 3}],
        name: "荆门",
        id: 185,
        type: 2
    }, {
        fid: 13,
        children: [{fid: 186, name: "沙市区", id: 1583, type: 3}, {fid: 186, name: "荆州区", id: 1584, type: 3}, {fid: 186, name: "石首市", id: 1585, type: 3}, {
            fid: 186,
            name: "洪湖市",
            id: 1586,
            type: 3
        }, {fid: 186, name: "松滋市", id: 1587, type: 3}, {fid: 186, name: "公安县", id: 1588, type: 3}, {fid: 186, name: "监利县", id: 1589, type: 3}, {
            fid: 186,
            name: "江陵县",
            id: 1590,
            type: 3
        }],
        name: "荆州",
        id: 186,
        type: 2
    }, {fid: 13, children: [{fid: 187, name: "潜江市", id: 1591, type: 3}], name: "潜江", id: 187, type: 2}, {
        fid: 13,
        children: [{fid: 188, name: "神农架林区", id: 1592, type: 3}],
        name: "神农架林区",
        id: 188,
        type: 2
    }, {
        fid: 13,
        children: [{fid: 189, name: "张湾区", id: 1593, type: 3}, {fid: 189, name: "茅箭区", id: 1594, type: 3}, {fid: 189, name: "丹江口市", id: 1595, type: 3}, {
            fid: 189,
            name: "郧县",
            id: 1596,
            type: 3
        }, {fid: 189, name: "郧西县", id: 1597, type: 3}, {fid: 189, name: "竹山县", id: 1598, type: 3}, {fid: 189, name: "竹溪县", id: 1599, type: 3}, {
            fid: 189,
            name: "房县",
            id: 1600,
            type: 3
        }],
        name: "十堰",
        id: 189,
        type: 2
    }, {fid: 13, children: [{fid: 190, name: "曾都区", id: 1601, type: 3}, {fid: 190, name: "广水市", id: 1602, type: 3}], name: "随州", id: 190, type: 2}, {
        fid: 13,
        children: [{fid: 191, name: "天门市", id: 1603, type: 3}],
        name: "天门",
        id: 191,
        type: 2
    }, {
        fid: 13,
        children: [{fid: 192, name: "咸安区", id: 1604, type: 3}, {fid: 192, name: "赤壁市", id: 1605, type: 3}, {fid: 192, name: "嘉鱼县", id: 1606, type: 3}, {
            fid: 192,
            name: "通城县",
            id: 1607,
            type: 3
        }, {fid: 192, name: "崇阳县", id: 1608, type: 3}, {fid: 192, name: "通山县", id: 1609, type: 3}],
        name: "咸宁",
        id: 192,
        type: 2
    }, {
        fid: 13,
        children: [{fid: 193, name: "襄城区", id: 1610, type: 3}, {fid: 193, name: "樊城区", id: 1611, type: 3}, {fid: 193, name: "襄阳区", id: 1612, type: 3}, {
            fid: 193,
            name: "老河口市",
            id: 1613,
            type: 3
        }, {fid: 193, name: "枣阳市", id: 1614, type: 3}, {fid: 193, name: "宜城市", id: 1615, type: 3}, {fid: 193, name: "南漳县", id: 1616, type: 3}, {
            fid: 193,
            name: "谷城县",
            id: 1617,
            type: 3
        }, {fid: 193, name: "保康县", id: 1618, type: 3}],
        name: "襄樊",
        id: 193,
        type: 2
    }, {
        fid: 13,
        children: [{fid: 194, name: "孝南区", id: 1619, type: 3}, {fid: 194, name: "应城市", id: 1620, type: 3}, {fid: 194, name: "安陆市", id: 1621, type: 3}, {
            fid: 194,
            name: "汉川市",
            id: 1622,
            type: 3
        }, {fid: 194, name: "孝昌县", id: 1623, type: 3}, {fid: 194, name: "大悟县", id: 1624, type: 3}, {fid: 194, name: "云梦县", id: 1625, type: 3}],
        name: "孝感",
        id: 194,
        type: 2
    }, {
        fid: 13,
        children: [{fid: 195, name: "长阳", id: 1626, type: 3}, {fid: 195, name: "五峰", id: 1627, type: 3}, {fid: 195, name: "西陵区", id: 1628, type: 3}, {
            fid: 195,
            name: "伍家岗区",
            id: 1629,
            type: 3
        }, {fid: 195, name: "点军区", id: 1630, type: 3}, {fid: 195, name: "猇亭区", id: 1631, type: 3}, {fid: 195, name: "夷陵区", id: 1632, type: 3}, {
            fid: 195,
            name: "宜都市",
            id: 1633,
            type: 3
        }, {fid: 195, name: "当阳市", id: 1634, type: 3}, {fid: 195, name: "枝江市", id: 1635, type: 3}, {fid: 195, name: "远安县", id: 1636, type: 3}, {
            fid: 195,
            name: "兴山县",
            id: 1637,
            type: 3
        }, {fid: 195, name: "秭归县", id: 1638, type: 3}],
        name: "宜昌",
        id: 195,
        type: 2
    }, {
        fid: 13,
        children: [{fid: 196, name: "恩施市", id: 1639, type: 3}, {fid: 196, name: "利川市", id: 1640, type: 3}, {fid: 196, name: "建始县", id: 1641, type: 3}, {
            fid: 196,
            name: "巴东县",
            id: 1642,
            type: 3
        }, {fid: 196, name: "宣恩县", id: 1643, type: 3}, {fid: 196, name: "咸丰县", id: 1644, type: 3}, {fid: 196, name: "来凤县", id: 1645, type: 3}, {
            fid: 196,
            name: "鹤峰县",
            id: 1646,
            type: 3
        }],
        name: "恩施",
        id: 196,
        type: 2
    }],
    name: "湖北",
    id: 13,
    type: 1
}, {
    fid: 1,
    children: [{
        fid: 14,
        children: [{fid: 197, name: "岳麓区", id: 1647, type: 3}, {fid: 197, name: "芙蓉区", id: 1648, type: 3}, {fid: 197, name: "天心区", id: 1649, type: 3}, {
            fid: 197,
            name: "开福区",
            id: 1650,
            type: 3
        }, {fid: 197, name: "雨花区", id: 1651, type: 3}, {fid: 197, name: "开发区", id: 1652, type: 3}, {fid: 197, name: "浏阳市", id: 1653, type: 3}, {
            fid: 197,
            name: "长沙县",
            id: 1654,
            type: 3
        }, {fid: 197, name: "望城县", id: 1655, type: 3}, {fid: 197, name: "宁乡县", id: 1656, type: 3}],
        name: "长沙",
        id: 197,
        type: 2
    }, {
        fid: 14,
        children: [{fid: 198, name: "永定区", id: 1657, type: 3}, {fid: 198, name: "武陵源区", id: 1658, type: 3}, {fid: 198, name: "慈利县", id: 1659, type: 3}, {
            fid: 198,
            name: "桑植县",
            id: 1660,
            type: 3
        }],
        name: "张家界",
        id: 198,
        type: 2
    }, {
        fid: 14,
        children: [{fid: 199, name: "武陵区", id: 1661, type: 3}, {fid: 199, name: "鼎城区", id: 1662, type: 3}, {fid: 199, name: "津市市", id: 1663, type: 3}, {
            fid: 199,
            name: "安乡县",
            id: 1664,
            type: 3
        }, {fid: 199, name: "汉寿县", id: 1665, type: 3}, {fid: 199, name: "澧县", id: 1666, type: 3}, {fid: 199, name: "临澧县", id: 1667, type: 3}, {
            fid: 199,
            name: "桃源县",
            id: 1668,
            type: 3
        }, {fid: 199, name: "石门县", id: 1669, type: 3}],
        name: "常德",
        id: 199,
        type: 2
    }, {
        fid: 14,
        children: [{fid: 200, name: "北湖区", id: 1670, type: 3}, {fid: 200, name: "苏仙区", id: 1671, type: 3}, {fid: 200, name: "资兴市", id: 1672, type: 3}, {
            fid: 200,
            name: "桂阳县",
            id: 1673,
            type: 3
        }, {fid: 200, name: "宜章县", id: 1674, type: 3}, {fid: 200, name: "永兴县", id: 1675, type: 3}, {fid: 200, name: "嘉禾县", id: 1676, type: 3}, {
            fid: 200,
            name: "临武县",
            id: 1677,
            type: 3
        }, {fid: 200, name: "汝城县", id: 1678, type: 3}, {fid: 200, name: "桂东县", id: 1679, type: 3}, {fid: 200, name: "安仁县", id: 1680, type: 3}],
        name: "郴州",
        id: 200,
        type: 2
    }, {
        fid: 14,
        children: [{fid: 201, name: "雁峰区", id: 1681, type: 3}, {fid: 201, name: "珠晖区", id: 1682, type: 3}, {fid: 201, name: "石鼓区", id: 1683, type: 3}, {
            fid: 201,
            name: "蒸湘区",
            id: 1684,
            type: 3
        }, {fid: 201, name: "南岳区", id: 1685, type: 3}, {fid: 201, name: "耒阳市", id: 1686, type: 3}, {fid: 201, name: "常宁市", id: 1687, type: 3}, {
            fid: 201,
            name: "衡阳县",
            id: 1688,
            type: 3
        }, {fid: 201, name: "衡南县", id: 1689, type: 3}, {fid: 201, name: "衡山县", id: 1690, type: 3}, {fid: 201, name: "衡东县", id: 1691, type: 3}, {
            fid: 201,
            name: "祁东县",
            id: 1692,
            type: 3
        }],
        name: "衡阳",
        id: 201,
        type: 2
    }, {
        fid: 14,
        children: [{fid: 202, name: "鹤城区", id: 1693, type: 3}, {fid: 202, name: "靖州", id: 1694, type: 3}, {fid: 202, name: "麻阳", id: 1695, type: 3}, {
            fid: 202,
            name: "通道",
            id: 1696,
            type: 3
        }, {fid: 202, name: "新晃", id: 1697, type: 3}, {fid: 202, name: "芷江", id: 1698, type: 3}, {fid: 202, name: "沅陵县", id: 1699, type: 3}, {
            fid: 202,
            name: "辰溪县",
            id: 1700,
            type: 3
        }, {fid: 202, name: "溆浦县", id: 1701, type: 3}, {fid: 202, name: "中方县", id: 1702, type: 3}, {fid: 202, name: "会同县", id: 1703, type: 3}, {
            fid: 202,
            name: "洪江市",
            id: 1704,
            type: 3
        }],
        name: "怀化",
        id: 202,
        type: 2
    }, {
        fid: 14,
        children: [{fid: 203, name: "娄星区", id: 1705, type: 3}, {fid: 203, name: "冷水江市", id: 1706, type: 3}, {fid: 203, name: "涟源市", id: 1707, type: 3}, {
            fid: 203,
            name: "双峰县",
            id: 1708,
            type: 3
        }, {fid: 203, name: "新化县", id: 1709, type: 3}],
        name: "娄底",
        id: 203,
        type: 2
    }, {
        fid: 14,
        children: [{fid: 204, name: "城步", id: 1710, type: 3}, {fid: 204, name: "双清区", id: 1711, type: 3}, {fid: 204, name: "大祥区", id: 1712, type: 3}, {
            fid: 204,
            name: "北塔区",
            id: 1713,
            type: 3
        }, {fid: 204, name: "武冈市", id: 1714, type: 3}, {fid: 204, name: "邵东县", id: 1715, type: 3}, {fid: 204, name: "新邵县", id: 1716, type: 3}, {
            fid: 204,
            name: "邵阳县",
            id: 1717,
            type: 3
        }, {fid: 204, name: "隆回县", id: 1718, type: 3}, {fid: 204, name: "洞口县", id: 1719, type: 3}, {fid: 204, name: "绥宁县", id: 1720, type: 3}, {
            fid: 204,
            name: "新宁县",
            id: 1721,
            type: 3
        }],
        name: "邵阳",
        id: 204,
        type: 2
    }, {
        fid: 14,
        children: [{fid: 205, name: "岳塘区", id: 1722, type: 3}, {fid: 205, name: "雨湖区", id: 1723, type: 3}, {fid: 205, name: "湘乡市", id: 1724, type: 3}, {
            fid: 205,
            name: "韶山市",
            id: 1725,
            type: 3
        }, {fid: 205, name: "湘潭县", id: 1726, type: 3}],
        name: "湘潭",
        id: 205,
        type: 2
    }, {
        fid: 14,
        children: [{fid: 206, name: "吉首市", id: 1727, type: 3}, {fid: 206, name: "泸溪县", id: 1728, type: 3}, {fid: 206, name: "凤凰县", id: 1729, type: 3}, {
            fid: 206,
            name: "花垣县",
            id: 1730,
            type: 3
        }, {fid: 206, name: "保靖县", id: 1731, type: 3}, {fid: 206, name: "古丈县", id: 1732, type: 3}, {fid: 206, name: "永顺县", id: 1733, type: 3}, {
            fid: 206,
            name: "龙山县",
            id: 1734,
            type: 3
        }],
        name: "湘西",
        id: 206,
        type: 2
    }, {
        fid: 14,
        children: [{fid: 207, name: "赫山区", id: 1735, type: 3}, {fid: 207, name: "资阳区", id: 1736, type: 3}, {fid: 207, name: "沅江市", id: 1737, type: 3}, {
            fid: 207,
            name: "南县",
            id: 1738,
            type: 3
        }, {fid: 207, name: "桃江县", id: 1739, type: 3}, {fid: 207, name: "安化县", id: 1740, type: 3}],
        name: "益阳",
        id: 207,
        type: 2
    }, {
        fid: 14,
        children: [{fid: 208, name: "江华", id: 1741, type: 3}, {fid: 208, name: "冷水滩区", id: 1742, type: 3}, {fid: 208, name: "零陵区", id: 1743, type: 3}, {
            fid: 208,
            name: "祁阳县",
            id: 1744,
            type: 3
        }, {fid: 208, name: "东安县", id: 1745, type: 3}, {fid: 208, name: "双牌县", id: 1746, type: 3}, {fid: 208, name: "道县", id: 1747, type: 3}, {
            fid: 208,
            name: "江永县",
            id: 1748,
            type: 3
        }, {fid: 208, name: "宁远县", id: 1749, type: 3}, {fid: 208, name: "蓝山县", id: 1750, type: 3}, {fid: 208, name: "新田县", id: 1751, type: 3}],
        name: "永州",
        id: 208,
        type: 2
    }, {
        fid: 14,
        children: [{fid: 209, name: "岳阳楼区", id: 1752, type: 3}, {fid: 209, name: "君山区", id: 1753, type: 3}, {fid: 209, name: "云溪区", id: 1754, type: 3}, {
            fid: 209,
            name: "汨罗市",
            id: 1755,
            type: 3
        }, {fid: 209, name: "临湘市", id: 1756, type: 3}, {fid: 209, name: "岳阳县", id: 1757, type: 3}, {fid: 209, name: "华容县", id: 1758, type: 3}, {
            fid: 209,
            name: "湘阴县",
            id: 1759,
            type: 3
        }, {fid: 209, name: "平江县", id: 1760, type: 3}],
        name: "岳阳",
        id: 209,
        type: 2
    }, {
        fid: 14,
        children: [{fid: 210, name: "天元区", id: 1761, type: 3}, {fid: 210, name: "荷塘区", id: 1762, type: 3}, {fid: 210, name: "芦淞区", id: 1763, type: 3}, {
            fid: 210,
            name: "石峰区",
            id: 1764,
            type: 3
        }, {fid: 210, name: "醴陵市", id: 1765, type: 3}, {fid: 210, name: "株洲县", id: 1766, type: 3}, {fid: 210, name: "攸县", id: 1767, type: 3}, {
            fid: 210,
            name: "茶陵县",
            id: 1768,
            type: 3
        }, {fid: 210, name: "炎陵县", id: 1769, type: 3}],
        name: "株洲",
        id: 210,
        type: 2
    }],
    name: "湖南",
    id: 14,
    type: 1
}, {
    fid: 1,
    children: [{
        fid: 15,
        children: [{fid: 211, name: "朝阳区", id: 1770, type: 3}, {fid: 211, name: "宽城区", id: 1771, type: 3}, {fid: 211, name: "二道区", id: 1772, type: 3}, {
            fid: 211,
            name: "南关区",
            id: 1773,
            type: 3
        }, {fid: 211, name: "绿园区", id: 1774, type: 3}, {fid: 211, name: "双阳区", id: 1775, type: 3}, {fid: 211, name: "净月潭开发区", id: 1776, type: 3}, {
            fid: 211,
            name: "高新技术开发区",
            id: 1777,
            type: 3
        }, {fid: 211, name: "经济技术开发区", id: 1778, type: 3}, {fid: 211, name: "汽车产业开发区", id: 1779, type: 3}, {fid: 211, name: "德惠市", id: 1780, type: 3}, {
            fid: 211,
            name: "九台市",
            id: 1781,
            type: 3
        }, {fid: 211, name: "榆树市", id: 1782, type: 3}, {fid: 211, name: "农安县", id: 1783, type: 3}],
        name: "长春",
        id: 211,
        type: 2
    }, {
        fid: 15,
        children: [{fid: 212, name: "船营区", id: 1784, type: 3}, {fid: 212, name: "昌邑区", id: 1785, type: 3}, {fid: 212, name: "龙潭区", id: 1786, type: 3}, {
            fid: 212,
            name: "丰满区",
            id: 1787,
            type: 3
        }, {fid: 212, name: "蛟河市", id: 1788, type: 3}, {fid: 212, name: "桦甸市", id: 1789, type: 3}, {fid: 212, name: "舒兰市", id: 1790, type: 3}, {
            fid: 212,
            name: "磐石市",
            id: 1791,
            type: 3
        }, {fid: 212, name: "永吉县", id: 1792, type: 3}],
        name: "吉林",
        id: 212,
        type: 2
    }, {
        fid: 15,
        children: [{fid: 213, name: "洮北区", id: 1793, type: 3}, {fid: 213, name: "洮南市", id: 1794, type: 3}, {fid: 213, name: "大安市", id: 1795, type: 3}, {
            fid: 213,
            name: "镇赉县",
            id: 1796,
            type: 3
        }, {fid: 213, name: "通榆县", id: 1797, type: 3}],
        name: "白城",
        id: 213,
        type: 2
    }, {
        fid: 15,
        children: [{fid: 214, name: "江源区", id: 1798, type: 3}, {fid: 214, name: "八道江区", id: 1799, type: 3}, {fid: 214, name: "长白", id: 1800, type: 3}, {
            fid: 214,
            name: "临江市",
            id: 1801,
            type: 3
        }, {fid: 214, name: "抚松县", id: 1802, type: 3}, {fid: 214, name: "靖宇县", id: 1803, type: 3}],
        name: "白山",
        id: 214,
        type: 2
    }, {
        fid: 15,
        children: [{fid: 215, name: "龙山区", id: 1804, type: 3}, {fid: 215, name: "西安区", id: 1805, type: 3}, {fid: 215, name: "东丰县", id: 1806, type: 3}, {
            fid: 215,
            name: "东辽县",
            id: 1807,
            type: 3
        }],
        name: "辽源",
        id: 215,
        type: 2
    }, {
        fid: 15,
        children: [{fid: 216, name: "铁西区", id: 1808, type: 3}, {fid: 216, name: "铁东区", id: 1809, type: 3}, {fid: 216, name: "伊通", id: 1810, type: 3}, {
            fid: 216,
            name: "公主岭市",
            id: 1811,
            type: 3
        }, {fid: 216, name: "双辽市", id: 1812, type: 3}, {fid: 216, name: "梨树县", id: 1813, type: 3}],
        name: "四平",
        id: 216,
        type: 2
    }, {
        fid: 15,
        children: [{fid: 217, name: "前郭尔罗斯", id: 1814, type: 3}, {fid: 217, name: "宁江区", id: 1815, type: 3}, {fid: 217, name: "长岭县", id: 1816, type: 3}, {
            fid: 217,
            name: "乾安县",
            id: 1817,
            type: 3
        }, {fid: 217, name: "扶余县", id: 1818, type: 3}],
        name: "松原",
        id: 217,
        type: 2
    }, {
        fid: 15,
        children: [{fid: 218, name: "东昌区", id: 1819, type: 3}, {fid: 218, name: "二道江区", id: 1820, type: 3}, {fid: 218, name: "梅河口市", id: 1821, type: 3}, {
            fid: 218,
            name: "集安市",
            id: 1822,
            type: 3
        }, {fid: 218, name: "通化县", id: 1823, type: 3}, {fid: 218, name: "辉南县", id: 1824, type: 3}, {fid: 218, name: "柳河县", id: 1825, type: 3}],
        name: "通化",
        id: 218,
        type: 2
    }, {
        fid: 15,
        children: [{fid: 219, name: "延吉市", id: 1826, type: 3}, {fid: 219, name: "图们市", id: 1827, type: 3}, {fid: 219, name: "敦化市", id: 1828, type: 3}, {
            fid: 219,
            name: "珲春市",
            id: 1829,
            type: 3
        }, {fid: 219, name: "龙井市", id: 1830, type: 3}, {fid: 219, name: "和龙市", id: 1831, type: 3}, {fid: 219, name: "安图县", id: 1832, type: 3}, {
            fid: 219,
            name: "汪清县",
            id: 1833,
            type: 3
        }],
        name: "延边",
        id: 219,
        type: 2
    }],
    name: "吉林",
    id: 15,
    type: 1
}, {
    fid: 1,
    children: [{
        fid: 16,
        children: [{fid: 220, name: "玄武区", id: 1834, type: 3}, {fid: 220, name: "鼓楼区", id: 1835, type: 3}, {fid: 220, name: "白下区", id: 1836, type: 3}, {
            fid: 220,
            name: "建邺区",
            id: 1837,
            type: 3
        }, {fid: 220, name: "秦淮区", id: 1838, type: 3}, {fid: 220, name: "雨花台区", id: 1839, type: 3}, {fid: 220, name: "下关区", id: 1840, type: 3}, {
            fid: 220,
            name: "栖霞区",
            id: 1841,
            type: 3
        }, {fid: 220, name: "浦口区", id: 1842, type: 3}, {fid: 220, name: "江宁区", id: 1843, type: 3}, {fid: 220, name: "六合区", id: 1844, type: 3}, {
            fid: 220,
            name: "溧水县",
            id: 1845,
            type: 3
        }, {fid: 220, name: "高淳县", id: 1846, type: 3}],
        name: "南京",
        id: 220,
        type: 2
    }, {
        fid: 16,
        children: [{fid: 221, name: "沧浪区", id: 1847, type: 3}, {fid: 221, name: "金阊区", id: 1848, type: 3}, {fid: 221, name: "平江区", id: 1849, type: 3}, {
            fid: 221,
            name: "虎丘区",
            id: 1850,
            type: 3
        }, {fid: 221, name: "吴中区", id: 1851, type: 3}, {fid: 221, name: "相城区", id: 1852, type: 3}, {fid: 221, name: "园区", id: 1853, type: 3}, {
            fid: 221,
            name: "新区",
            id: 1854,
            type: 3
        }, {fid: 221, name: "常熟市", id: 1855, type: 3}, {fid: 221, name: "张家港市", id: 1856, type: 3}, {fid: 221, name: "玉山镇", id: 1857, type: 3}, {
            fid: 221,
            name: "巴城镇",
            id: 1858,
            type: 3
        }, {fid: 221, name: "周市镇", id: 1859, type: 3}, {fid: 221, name: "陆家镇", id: 1860, type: 3}, {fid: 221, name: "花桥镇", id: 1861, type: 3}, {
            fid: 221,
            name: "淀山湖镇",
            id: 1862,
            type: 3
        }, {fid: 221, name: "张浦镇", id: 1863, type: 3}, {fid: 221, name: "周庄镇", id: 1864, type: 3}, {fid: 221, name: "千灯镇", id: 1865, type: 3}, {
            fid: 221,
            name: "锦溪镇",
            id: 1866,
            type: 3
        }, {fid: 221, name: "开发区", id: 1867, type: 3}, {fid: 221, name: "吴江市", id: 1868, type: 3}, {fid: 221, name: "太仓市", id: 1869, type: 3}],
        name: "苏州",
        id: 221,
        type: 2
    }, {
        fid: 16,
        children: [{fid: 222, name: "崇安区", id: 1870, type: 3}, {fid: 222, name: "北塘区", id: 1871, type: 3}, {fid: 222, name: "南长区", id: 1872, type: 3}, {
            fid: 222,
            name: "锡山区",
            id: 1873,
            type: 3
        }, {fid: 222, name: "惠山区", id: 1874, type: 3}, {fid: 222, name: "滨湖区", id: 1875, type: 3}, {fid: 222, name: "新区", id: 1876, type: 3}, {
            fid: 222,
            name: "江阴市",
            id: 1877,
            type: 3
        }, {fid: 222, name: "宜兴市", id: 1878, type: 3}],
        name: "无锡",
        id: 222,
        type: 2
    }, {
        fid: 16,
        children: [{fid: 223, name: "天宁区", id: 1879, type: 3}, {fid: 223, name: "钟楼区", id: 1880, type: 3}, {fid: 223, name: "戚墅堰区", id: 1881, type: 3}, {
            fid: 223,
            name: "郊区",
            id: 1882,
            type: 3
        }, {fid: 223, name: "新北区", id: 1883, type: 3}, {fid: 223, name: "武进区", id: 1884, type: 3}, {fid: 223, name: "溧阳市", id: 1885, type: 3}, {
            fid: 223,
            name: "金坛市",
            id: 1886,
            type: 3
        }],
        name: "常州",
        id: 223,
        type: 2
    }, {
        fid: 16,
        children: [{fid: 224, name: "清河区", id: 1887, type: 3}, {fid: 224, name: "清浦区", id: 1888, type: 3}, {fid: 224, name: "楚州区", id: 1889, type: 3}, {
            fid: 224,
            name: "淮阴区",
            id: 1890,
            type: 3
        }, {fid: 224, name: "涟水县", id: 1891, type: 3}, {fid: 224, name: "洪泽县", id: 1892, type: 3}, {fid: 224, name: "盱眙县", id: 1893, type: 3}, {
            fid: 224,
            name: "金湖县",
            id: 1894,
            type: 3
        }],
        name: "淮安",
        id: 224,
        type: 2
    }, {
        fid: 16,
        children: [{fid: 225, name: "新浦区", id: 1895, type: 3}, {fid: 225, name: "连云区", id: 1896, type: 3}, {fid: 225, name: "海州区", id: 1897, type: 3}, {
            fid: 225,
            name: "赣榆县",
            id: 1898,
            type: 3
        }, {fid: 225, name: "东海县", id: 1899, type: 3}, {fid: 225, name: "灌云县", id: 1900, type: 3}, {fid: 225, name: "灌南县", id: 1901, type: 3}],
        name: "连云港",
        id: 225,
        type: 2
    }, {
        fid: 16,
        children: [{fid: 226, name: "崇川区", id: 1902, type: 3}, {fid: 226, name: "港闸区", id: 1903, type: 3}, {fid: 226, name: "经济开发区", id: 1904, type: 3}, {
            fid: 226,
            name: "启东市",
            id: 1905,
            type: 3
        }, {fid: 226, name: "如皋市", id: 1906, type: 3}, {fid: 226, name: "通州市", id: 1907, type: 3}, {fid: 226, name: "海门市", id: 1908, type: 3}, {
            fid: 226,
            name: "海安县",
            id: 1909,
            type: 3
        }, {fid: 226, name: "如东县", id: 1910, type: 3}],
        name: "南通",
        id: 226,
        type: 2
    }, {
        fid: 16,
        children: [{fid: 227, name: "宿城区", id: 1911, type: 3}, {fid: 227, name: "宿豫区", id: 1912, type: 3}, {fid: 227, name: "宿豫县", id: 1913, type: 3}, {
            fid: 227,
            name: "沭阳县",
            id: 1914,
            type: 3
        }, {fid: 227, name: "泗阳县", id: 1915, type: 3}, {fid: 227, name: "泗洪县", id: 1916, type: 3}],
        name: "宿迁",
        id: 227,
        type: 2
    }, {
        fid: 16,
        children: [{fid: 228, name: "海陵区", id: 1917, type: 3}, {fid: 228, name: "高港区", id: 1918, type: 3}, {fid: 228, name: "兴化市", id: 1919, type: 3}, {
            fid: 228,
            name: "靖江市",
            id: 1920,
            type: 3
        }, {fid: 228, name: "泰兴市", id: 1921, type: 3}, {fid: 228, name: "姜堰市", id: 1922, type: 3}],
        name: "泰州",
        id: 228,
        type: 2
    }, {
        fid: 16,
        children: [{fid: 229, name: "云龙区", id: 1923, type: 3}, {fid: 229, name: "鼓楼区", id: 1924, type: 3}, {fid: 229, name: "九里区", id: 1925, type: 3}, {
            fid: 229,
            name: "贾汪区",
            id: 1926,
            type: 3
        }, {fid: 229, name: "泉山区", id: 1927, type: 3}, {fid: 229, name: "新沂市", id: 1928, type: 3}, {fid: 229, name: "邳州市", id: 1929, type: 3}, {
            fid: 229,
            name: "丰县",
            id: 1930,
            type: 3
        }, {fid: 229, name: "沛县", id: 1931, type: 3}, {fid: 229, name: "铜山县", id: 1932, type: 3}, {fid: 229, name: "睢宁县", id: 1933, type: 3}],
        name: "徐州",
        id: 229,
        type: 2
    }, {
        fid: 16,
        children: [{fid: 230, name: "城区", id: 1934, type: 3}, {fid: 230, name: "亭湖区", id: 1935, type: 3}, {fid: 230, name: "盐都区", id: 1936, type: 3}, {
            fid: 230,
            name: "盐都县",
            id: 1937,
            type: 3
        }, {fid: 230, name: "东台市", id: 1938, type: 3}, {fid: 230, name: "大丰市", id: 1939, type: 3}, {fid: 230, name: "响水县", id: 1940, type: 3}, {
            fid: 230,
            name: "滨海县",
            id: 1941,
            type: 3
        }, {fid: 230, name: "阜宁县", id: 1942, type: 3}, {fid: 230, name: "射阳县", id: 1943, type: 3}, {fid: 230, name: "建湖县", id: 1944, type: 3}],
        name: "盐城",
        id: 230,
        type: 2
    }, {
        fid: 16,
        children: [{fid: 231, name: "广陵区", id: 1945, type: 3}, {fid: 231, name: "维扬区", id: 1946, type: 3}, {fid: 231, name: "邗江区", id: 1947, type: 3}, {
            fid: 231,
            name: "仪征市",
            id: 1948,
            type: 3
        }, {fid: 231, name: "高邮市", id: 1949, type: 3}, {fid: 231, name: "江都市", id: 1950, type: 3}, {fid: 231, name: "宝应县", id: 1951, type: 3}],
        name: "扬州",
        id: 231,
        type: 2
    }, {
        fid: 16,
        children: [{fid: 232, name: "京口区", id: 1952, type: 3}, {fid: 232, name: "润州区", id: 1953, type: 3}, {fid: 232, name: "丹徒区", id: 1954, type: 3}, {
            fid: 232,
            name: "丹阳市",
            id: 1955,
            type: 3
        }, {fid: 232, name: "扬中市", id: 1956, type: 3}, {fid: 232, name: "句容市", id: 1957, type: 3}],
        name: "镇江",
        id: 232,
        type: 2
    }],
    name: "江苏",
    id: 16,
    type: 1
}, {
    fid: 1,
    children: [{
        fid: 17,
        children: [{fid: 233, name: "东湖区", id: 1958, type: 3}, {fid: 233, name: "西湖区", id: 1959, type: 3}, {fid: 233, name: "青云谱区", id: 1960, type: 3}, {
            fid: 233,
            name: "湾里区",
            id: 1961,
            type: 3
        }, {fid: 233, name: "青山湖区", id: 1962, type: 3}, {fid: 233, name: "红谷滩新区", id: 1963, type: 3}, {fid: 233, name: "昌北区", id: 1964, type: 3}, {
            fid: 233,
            name: "高新区",
            id: 1965,
            type: 3
        }, {fid: 233, name: "南昌县", id: 1966, type: 3}, {fid: 233, name: "新建县", id: 1967, type: 3}, {fid: 233, name: "安义县", id: 1968, type: 3}, {
            fid: 233,
            name: "进贤县",
            id: 1969,
            type: 3
        }],
        name: "南昌",
        id: 233,
        type: 2
    }, {
        fid: 17,
        children: [{fid: 234, name: "临川区", id: 1970, type: 3}, {fid: 234, name: "南城县", id: 1971, type: 3}, {fid: 234, name: "黎川县", id: 1972, type: 3}, {
            fid: 234,
            name: "南丰县",
            id: 1973,
            type: 3
        }, {fid: 234, name: "崇仁县", id: 1974, type: 3}, {fid: 234, name: "乐安县", id: 1975, type: 3}, {fid: 234, name: "宜黄县", id: 1976, type: 3}, {
            fid: 234,
            name: "金溪县",
            id: 1977,
            type: 3
        }, {fid: 234, name: "资溪县", id: 1978, type: 3}, {fid: 234, name: "东乡县", id: 1979, type: 3}, {fid: 234, name: "广昌县", id: 1980, type: 3}],
        name: "抚州",
        id: 234,
        type: 2
    }, {
        fid: 17,
        children: [{fid: 235, name: "章贡区", id: 1981, type: 3}, {fid: 235, name: "于都县", id: 1982, type: 3}, {fid: 235, name: "瑞金市", id: 1983, type: 3}, {
            fid: 235,
            name: "南康市",
            id: 1984,
            type: 3
        }, {fid: 235, name: "赣县", id: 1985, type: 3}, {fid: 235, name: "信丰县", id: 1986, type: 3}, {fid: 235, name: "大余县", id: 1987, type: 3}, {
            fid: 235,
            name: "上犹县",
            id: 1988,
            type: 3
        }, {fid: 235, name: "崇义县", id: 1989, type: 3}, {fid: 235, name: "安远县", id: 1990, type: 3}, {fid: 235, name: "龙南县", id: 1991, type: 3}, {
            fid: 235,
            name: "定南县",
            id: 1992,
            type: 3
        }, {fid: 235, name: "全南县", id: 1993, type: 3}, {fid: 235, name: "宁都县", id: 1994, type: 3}, {fid: 235, name: "兴国县", id: 1995, type: 3}, {
            fid: 235,
            name: "会昌县",
            id: 1996,
            type: 3
        }, {fid: 235, name: "寻乌县", id: 1997, type: 3}, {fid: 235, name: "石城县", id: 1998, type: 3}],
        name: "赣州",
        id: 235,
        type: 2
    }, {
        fid: 17,
        children: [{fid: 236, name: "安福县", id: 1999, type: 3}, {fid: 236, name: "吉州区", id: 2000, type: 3}, {fid: 236, name: "青原区", id: 2001, type: 3}, {
            fid: 236,
            name: "井冈山市",
            id: 2002,
            type: 3
        }, {fid: 236, name: "吉安县", id: 2003, type: 3}, {fid: 236, name: "吉水县", id: 2004, type: 3}, {fid: 236, name: "峡江县", id: 2005, type: 3}, {
            fid: 236,
            name: "新干县",
            id: 2006,
            type: 3
        }, {fid: 236, name: "永丰县", id: 2007, type: 3}, {fid: 236, name: "泰和县", id: 2008, type: 3}, {fid: 236, name: "遂川县", id: 2009, type: 3}, {
            fid: 236,
            name: "万安县",
            id: 2010,
            type: 3
        }, {fid: 236, name: "永新县", id: 2011, type: 3}],
        name: "吉安",
        id: 236,
        type: 2
    }, {
        fid: 17,
        children: [{fid: 237, name: "珠山区", id: 2012, type: 3}, {fid: 237, name: "昌江区", id: 2013, type: 3}, {fid: 237, name: "乐平市", id: 2014, type: 3}, {
            fid: 237,
            name: "浮梁县",
            id: 2015,
            type: 3
        }],
        name: "景德镇",
        id: 237,
        type: 2
    }, {
        fid: 17,
        children: [{fid: 238, name: "浔阳区", id: 2016, type: 3}, {fid: 238, name: "庐山区", id: 2017, type: 3}, {fid: 238, name: "瑞昌市", id: 2018, type: 3}, {
            fid: 238,
            name: "九江县",
            id: 2019,
            type: 3
        }, {fid: 238, name: "武宁县", id: 2020, type: 3}, {fid: 238, name: "修水县", id: 2021, type: 3}, {fid: 238, name: "永修县", id: 2022, type: 3}, {
            fid: 238,
            name: "德安县",
            id: 2023,
            type: 3
        }, {fid: 238, name: "星子县", id: 2024, type: 3}, {fid: 238, name: "都昌县", id: 2025, type: 3}, {fid: 238, name: "湖口县", id: 2026, type: 3}, {
            fid: 238,
            name: "彭泽县",
            id: 2027,
            type: 3
        }],
        name: "九江",
        id: 238,
        type: 2
    }, {
        fid: 17,
        children: [{fid: 239, name: "安源区", id: 2028, type: 3}, {fid: 239, name: "湘东区", id: 2029, type: 3}, {fid: 239, name: "莲花县", id: 2030, type: 3}, {
            fid: 239,
            name: "芦溪县",
            id: 2031,
            type: 3
        }, {fid: 239, name: "上栗县", id: 2032, type: 3}],
        name: "萍乡",
        id: 239,
        type: 2
    }, {
        fid: 17,
        children: [{fid: 240, name: "信州区", id: 2033, type: 3}, {fid: 240, name: "德兴市", id: 2034, type: 3}, {fid: 240, name: "上饶县", id: 2035, type: 3}, {
            fid: 240,
            name: "广丰县",
            id: 2036,
            type: 3
        }, {fid: 240, name: "玉山县", id: 2037, type: 3}, {fid: 240, name: "铅山县", id: 2038, type: 3}, {fid: 240, name: "横峰县", id: 2039, type: 3}, {
            fid: 240,
            name: "弋阳县",
            id: 2040,
            type: 3
        }, {fid: 240, name: "余干县", id: 2041, type: 3}, {fid: 240, name: "波阳县", id: 2042, type: 3}, {fid: 240, name: "万年县", id: 2043, type: 3}, {
            fid: 240,
            name: "婺源县",
            id: 2044,
            type: 3
        }],
        name: "上饶",
        id: 240,
        type: 2
    }, {fid: 17, children: [{fid: 241, name: "渝水区", id: 2045, type: 3}, {fid: 241, name: "分宜县", id: 2046, type: 3}], name: "新余", id: 241, type: 2}, {
        fid: 17,
        children: [{fid: 242, name: "袁州区", id: 2047, type: 3}, {fid: 242, name: "丰城市", id: 2048, type: 3}, {fid: 242, name: "樟树市", id: 2049, type: 3}, {
            fid: 242,
            name: "高安市",
            id: 2050,
            type: 3
        }, {fid: 242, name: "奉新县", id: 2051, type: 3}, {fid: 242, name: "万载县", id: 2052, type: 3}, {fid: 242, name: "上高县", id: 2053, type: 3}, {
            fid: 242,
            name: "宜丰县",
            id: 2054,
            type: 3
        }, {fid: 242, name: "靖安县", id: 2055, type: 3}, {fid: 242, name: "铜鼓县", id: 2056, type: 3}],
        name: "宜春",
        id: 242,
        type: 2
    }, {
        fid: 17,
        children: [{fid: 243, name: "月湖区", id: 2057, type: 3}, {fid: 243, name: "贵溪市", id: 2058, type: 3}, {fid: 243, name: "余江县", id: 2059, type: 3}],
        name: "鹰潭",
        id: 243,
        type: 2
    }],
    name: "江西",
    id: 17,
    type: 1
}, {
    fid: 1,
    children: [{
        fid: 18,
        children: [{fid: 244, name: "沈河区", id: 2060, type: 3}, {fid: 244, name: "皇姑区", id: 2061, type: 3}, {fid: 244, name: "和平区", id: 2062, type: 3}, {
            fid: 244,
            name: "大东区",
            id: 2063,
            type: 3
        }, {fid: 244, name: "铁西区", id: 2064, type: 3}, {fid: 244, name: "苏家屯区", id: 2065, type: 3}, {fid: 244, name: "东陵区", id: 2066, type: 3}, {
            fid: 244,
            name: "沈北新区",
            id: 2067,
            type: 3
        }, {fid: 244, name: "于洪区", id: 2068, type: 3}, {fid: 244, name: "浑南新区", id: 2069, type: 3}, {fid: 244, name: "新民市", id: 2070, type: 3}, {
            fid: 244,
            name: "辽中县",
            id: 2071,
            type: 3
        }, {fid: 244, name: "康平县", id: 2072, type: 3}, {fid: 244, name: "法库县", id: 2073, type: 3}],
        name: "沈阳",
        id: 244,
        type: 2
    }, {
        fid: 18,
        children: [{fid: 245, name: "西岗区", id: 2074, type: 3}, {fid: 245, name: "中山区", id: 2075, type: 3}, {fid: 245, name: "沙河口区", id: 2076, type: 3}, {
            fid: 245,
            name: "甘井子区",
            id: 2077,
            type: 3
        }, {fid: 245, name: "旅顺口区", id: 2078, type: 3}, {fid: 245, name: "金州区", id: 2079, type: 3}, {fid: 245, name: "开发区", id: 2080, type: 3}, {
            fid: 245,
            name: "瓦房店市",
            id: 2081,
            type: 3
        }, {fid: 245, name: "普兰店市", id: 2082, type: 3}, {fid: 245, name: "庄河市", id: 2083, type: 3}, {fid: 245, name: "长海县", id: 2084, type: 3}],
        name: "大连",
        id: 245,
        type: 2
    }, {
        fid: 18,
        children: [{fid: 246, name: "铁东区", id: 2085, type: 3}, {fid: 246, name: "铁西区", id: 2086, type: 3}, {fid: 246, name: "立山区", id: 2087, type: 3}, {
            fid: 246,
            name: "千山区",
            id: 2088,
            type: 3
        }, {fid: 246, name: "岫岩", id: 2089, type: 3}, {fid: 246, name: "海城市", id: 2090, type: 3}, {fid: 246, name: "台安县", id: 2091, type: 3}],
        name: "鞍山",
        id: 246,
        type: 2
    }, {
        fid: 18,
        children: [{fid: 247, name: "本溪", id: 2092, type: 3}, {fid: 247, name: "平山区", id: 2093, type: 3}, {fid: 247, name: "明山区", id: 2094, type: 3}, {
            fid: 247,
            name: "溪湖区",
            id: 2095,
            type: 3
        }, {fid: 247, name: "南芬区", id: 2096, type: 3}, {fid: 247, name: "桓仁", id: 2097, type: 3}],
        name: "本溪",
        id: 247,
        type: 2
    }, {
        fid: 18,
        children: [{fid: 248, name: "双塔区", id: 2098, type: 3}, {fid: 248, name: "龙城区", id: 2099, type: 3}, {fid: 248, name: "喀喇沁左翼蒙古族自治县", id: 2100, type: 3}, {
            fid: 248,
            name: "北票市",
            id: 2101,
            type: 3
        }, {fid: 248, name: "凌源市", id: 2102, type: 3}, {fid: 248, name: "朝阳县", id: 2103, type: 3}, {fid: 248, name: "建平县", id: 2104, type: 3}],
        name: "朝阳",
        id: 248,
        type: 2
    }, {
        fid: 18,
        children: [{fid: 249, name: "振兴区", id: 2105, type: 3}, {fid: 249, name: "元宝区", id: 2106, type: 3}, {fid: 249, name: "振安区", id: 2107, type: 3}, {
            fid: 249,
            name: "宽甸",
            id: 2108,
            type: 3
        }, {fid: 249, name: "东港市", id: 2109, type: 3}, {fid: 249, name: "凤城市", id: 2110, type: 3}],
        name: "丹东",
        id: 249,
        type: 2
    }, {
        fid: 18,
        children: [{fid: 250, name: "顺城区", id: 2111, type: 3}, {fid: 250, name: "新抚区", id: 2112, type: 3}, {fid: 250, name: "东洲区", id: 2113, type: 3}, {
            fid: 250,
            name: "望花区",
            id: 2114,
            type: 3
        }, {fid: 250, name: "清原", id: 2115, type: 3}, {fid: 250, name: "新宾", id: 2116, type: 3}, {fid: 250, name: "抚顺县", id: 2117, type: 3}],
        name: "抚顺",
        id: 250,
        type: 2
    }, {
        fid: 18,
        children: [{fid: 251, name: "阜新", id: 2118, type: 3}, {fid: 251, name: "海州区", id: 2119, type: 3}, {fid: 251, name: "新邱区", id: 2120, type: 3}, {
            fid: 251,
            name: "太平区",
            id: 2121,
            type: 3
        }, {fid: 251, name: "清河门区", id: 2122, type: 3}, {fid: 251, name: "细河区", id: 2123, type: 3}, {fid: 251, name: "彰武县", id: 2124, type: 3}],
        name: "阜新",
        id: 251,
        type: 2
    }, {
        fid: 18,
        children: [{fid: 252, name: "龙港区", id: 2125, type: 3}, {fid: 252, name: "南票区", id: 2126, type: 3}, {fid: 252, name: "连山区", id: 2127, type: 3}, {
            fid: 252,
            name: "兴城市",
            id: 2128,
            type: 3
        }, {fid: 252, name: "绥中县", id: 2129, type: 3}, {fid: 252, name: "建昌县", id: 2130, type: 3}],
        name: "葫芦岛",
        id: 252,
        type: 2
    }, {
        fid: 18,
        children: [{fid: 253, name: "太和区", id: 2131, type: 3}, {fid: 253, name: "古塔区", id: 2132, type: 3}, {fid: 253, name: "凌河区", id: 2133, type: 3}, {
            fid: 253,
            name: "凌海市",
            id: 2134,
            type: 3
        }, {fid: 253, name: "北镇市", id: 2135, type: 3}, {fid: 253, name: "黑山县", id: 2136, type: 3}, {fid: 253, name: "义县", id: 2137, type: 3}],
        name: "锦州",
        id: 253,
        type: 2
    }, {
        fid: 18,
        children: [{fid: 254, name: "白塔区", id: 2138, type: 3}, {fid: 254, name: "文圣区", id: 2139, type: 3}, {fid: 254, name: "宏伟区", id: 2140, type: 3}, {
            fid: 254,
            name: "太子河区",
            id: 2141,
            type: 3
        }, {fid: 254, name: "弓长岭区", id: 2142, type: 3}, {fid: 254, name: "灯塔市", id: 2143, type: 3}, {fid: 254, name: "辽阳县", id: 2144, type: 3}],
        name: "辽阳",
        id: 254,
        type: 2
    }, {
        fid: 18,
        children: [{fid: 255, name: "双台子区", id: 2145, type: 3}, {fid: 255, name: "兴隆台区", id: 2146, type: 3}, {fid: 255, name: "大洼县", id: 2147, type: 3}, {
            fid: 255,
            name: "盘山县",
            id: 2148,
            type: 3
        }],
        name: "盘锦",
        id: 255,
        type: 2
    }, {
        fid: 18,
        children: [{fid: 256, name: "银州区", id: 2149, type: 3}, {fid: 256, name: "清河区", id: 2150, type: 3}, {fid: 256, name: "调兵山市", id: 2151, type: 3}, {
            fid: 256,
            name: "开原市",
            id: 2152,
            type: 3
        }, {fid: 256, name: "铁岭县", id: 2153, type: 3}, {fid: 256, name: "西丰县", id: 2154, type: 3}, {fid: 256, name: "昌图县", id: 2155, type: 3}],
        name: "铁岭",
        id: 256,
        type: 2
    }, {
        fid: 18,
        children: [{fid: 257, name: "站前区", id: 2156, type: 3}, {fid: 257, name: "西市区", id: 2157, type: 3}, {fid: 257, name: "鲅鱼圈区", id: 2158, type: 3}, {
            fid: 257,
            name: "老边区",
            id: 2159,
            type: 3
        }, {fid: 257, name: "盖州市", id: 2160, type: 3}, {fid: 257, name: "大石桥市", id: 2161, type: 3}],
        name: "营口",
        id: 257,
        type: 2
    }],
    name: "辽宁",
    id: 18,
    type: 1
}, {
    fid: 1,
    children: [{
        fid: 19,
        children: [{fid: 258, name: "回民区", id: 2162, type: 3}, {fid: 258, name: "玉泉区", id: 2163, type: 3}, {fid: 258, name: "新城区", id: 2164, type: 3}, {
            fid: 258,
            name: "赛罕区",
            id: 2165,
            type: 3
        }, {fid: 258, name: "清水河县", id: 2166, type: 3}, {fid: 258, name: "土默特左旗", id: 2167, type: 3}, {fid: 258, name: "托克托县", id: 2168, type: 3}, {
            fid: 258,
            name: "和林格尔县",
            id: 2169,
            type: 3
        }, {fid: 258, name: "武川县", id: 2170, type: 3}],
        name: "呼和浩特",
        id: 258,
        type: 2
    }, {
        fid: 19,
        children: [{fid: 259, name: "阿拉善左旗", id: 2171, type: 3}, {fid: 259, name: "阿拉善右旗", id: 2172, type: 3}, {fid: 259, name: "额济纳旗", id: 2173, type: 3}],
        name: "阿拉善盟",
        id: 259,
        type: 2
    }, {
        fid: 19,
        children: [{fid: 260, name: "临河区", id: 2174, type: 3}, {fid: 260, name: "五原县", id: 2175, type: 3}, {fid: 260, name: "磴口县", id: 2176, type: 3}, {
            fid: 260,
            name: "乌拉特前旗",
            id: 2177,
            type: 3
        }, {fid: 260, name: "乌拉特中旗", id: 2178, type: 3}, {fid: 260, name: "乌拉特后旗", id: 2179, type: 3}, {fid: 260, name: "杭锦后旗", id: 2180, type: 3}],
        name: "巴彦淖尔盟",
        id: 260,
        type: 2
    }, {
        fid: 19,
        children: [{fid: 261, name: "昆都仑区", id: 2181, type: 3}, {fid: 261, name: "青山区", id: 2182, type: 3}, {fid: 261, name: "东河区", id: 2183, type: 3}, {
            fid: 261,
            name: "九原区",
            id: 2184,
            type: 3
        }, {fid: 261, name: "石拐区", id: 2185, type: 3}, {fid: 261, name: "白云矿区", id: 2186, type: 3}, {fid: 261, name: "土默特右旗", id: 2187, type: 3}, {
            fid: 261,
            name: "固阳县",
            id: 2188,
            type: 3
        }, {fid: 261, name: "达尔罕茂明安联合旗", id: 2189, type: 3}],
        name: "包头",
        id: 261,
        type: 2
    }, {
        fid: 19,
        children: [{fid: 262, name: "红山区", id: 2190, type: 3}, {fid: 262, name: "元宝山区", id: 2191, type: 3}, {fid: 262, name: "松山区", id: 2192, type: 3}, {
            fid: 262,
            name: "阿鲁科尔沁旗",
            id: 2193,
            type: 3
        }, {fid: 262, name: "巴林左旗", id: 2194, type: 3}, {fid: 262, name: "巴林右旗", id: 2195, type: 3}, {fid: 262, name: "林西县", id: 2196, type: 3}, {
            fid: 262,
            name: "克什克腾旗",
            id: 2197,
            type: 3
        }, {fid: 262, name: "翁牛特旗", id: 2198, type: 3}, {fid: 262, name: "喀喇沁旗", id: 2199, type: 3}, {fid: 262, name: "宁城县", id: 2200, type: 3}, {
            fid: 262,
            name: "敖汉旗",
            id: 2201,
            type: 3
        }],
        name: "赤峰",
        id: 262,
        type: 2
    }, {
        fid: 19,
        children: [{fid: 263, name: "东胜区", id: 2202, type: 3}, {fid: 263, name: "达拉特旗", id: 2203, type: 3}, {fid: 263, name: "准格尔旗", id: 2204, type: 3}, {
            fid: 263,
            name: "鄂托克前旗",
            id: 2205,
            type: 3
        }, {fid: 263, name: "鄂托克旗", id: 2206, type: 3}, {fid: 263, name: "杭锦旗", id: 2207, type: 3}, {fid: 263, name: "乌审旗", id: 2208, type: 3}, {
            fid: 263,
            name: "伊金霍洛旗",
            id: 2209,
            type: 3
        }],
        name: "鄂尔多斯",
        id: 263,
        type: 2
    }, {
        fid: 19,
        children: [{fid: 264, name: "海拉尔区", id: 2210, type: 3}, {fid: 264, name: "莫力达瓦", id: 2211, type: 3}, {fid: 264, name: "满洲里市", id: 2212, type: 3}, {
            fid: 264,
            name: "牙克石市",
            id: 2213,
            type: 3
        }, {fid: 264, name: "扎兰屯市", id: 2214, type: 3}, {fid: 264, name: "额尔古纳市", id: 2215, type: 3}, {fid: 264, name: "根河市", id: 2216, type: 3}, {
            fid: 264,
            name: "阿荣旗",
            id: 2217,
            type: 3
        }, {fid: 264, name: "鄂伦春自治旗", id: 2218, type: 3}, {fid: 264, name: "鄂温克族自治旗", id: 2219, type: 3}, {fid: 264, name: "陈巴尔虎旗", id: 2220, type: 3}, {
            fid: 264,
            name: "新巴尔虎左旗",
            id: 2221,
            type: 3
        }, {fid: 264, name: "新巴尔虎右旗", id: 2222, type: 3}],
        name: "呼伦贝尔",
        id: 264,
        type: 2
    }, {
        fid: 19,
        children: [{fid: 265, name: "科尔沁区", id: 2223, type: 3}, {fid: 265, name: "霍林郭勒市", id: 2224, type: 3}, {fid: 265, name: "科尔沁左翼中旗", id: 2225, type: 3}, {
            fid: 265,
            name: "科尔沁左翼后旗",
            id: 2226,
            type: 3
        }, {fid: 265, name: "开鲁县", id: 2227, type: 3}, {fid: 265, name: "库伦旗", id: 2228, type: 3}, {fid: 265, name: "奈曼旗", id: 2229, type: 3}, {
            fid: 265,
            name: "扎鲁特旗",
            id: 2230,
            type: 3
        }],
        name: "通辽",
        id: 265,
        type: 2
    }, {
        fid: 19,
        children: [{fid: 266, name: "海勃湾区", id: 2231, type: 3}, {fid: 266, name: "乌达区", id: 2232, type: 3}, {fid: 266, name: "海南区", id: 2233, type: 3}],
        name: "乌海",
        id: 266,
        type: 2
    }, {
        fid: 19,
        children: [{fid: 267, name: "化德县", id: 2234, type: 3}, {fid: 267, name: "集宁区", id: 2235, type: 3}, {fid: 267, name: "丰镇市", id: 2236, type: 3}, {
            fid: 267,
            name: "卓资县",
            id: 2237,
            type: 3
        }, {fid: 267, name: "商都县", id: 2238, type: 3}, {fid: 267, name: "兴和县", id: 2239, type: 3}, {fid: 267, name: "凉城县", id: 2240, type: 3}, {
            fid: 267,
            name: "察哈尔右翼前旗",
            id: 2241,
            type: 3
        }, {fid: 267, name: "察哈尔右翼中旗", id: 2242, type: 3}, {fid: 267, name: "察哈尔右翼后旗", id: 2243, type: 3}, {fid: 267, name: "四子王旗", id: 2244, type: 3}],
        name: "乌兰察布市",
        id: 267,
        type: 2
    }, {
        fid: 19,
        children: [{fid: 268, name: "二连浩特市", id: 2245, type: 3}, {fid: 268, name: "锡林浩特市", id: 2246, type: 3}, {fid: 268, name: "阿巴嘎旗", id: 2247, type: 3}, {
            fid: 268,
            name: "苏尼特左旗",
            id: 2248,
            type: 3
        }, {fid: 268, name: "苏尼特右旗", id: 2249, type: 3}, {fid: 268, name: "东乌珠穆沁旗", id: 2250, type: 3}, {fid: 268, name: "西乌珠穆沁旗", id: 2251, type: 3}, {
            fid: 268,
            name: "太仆寺旗",
            id: 2252,
            type: 3
        }, {fid: 268, name: "镶黄旗", id: 2253, type: 3}, {fid: 268, name: "正镶白旗", id: 2254, type: 3}, {fid: 268, name: "正蓝旗", id: 2255, type: 3}, {
            fid: 268,
            name: "多伦县",
            id: 2256,
            type: 3
        }],
        name: "锡林郭勒盟",
        id: 268,
        type: 2
    }, {
        fid: 19,
        children: [{fid: 269, name: "乌兰浩特市", id: 2257, type: 3}, {fid: 269, name: "阿尔山市", id: 2258, type: 3}, {fid: 269, name: "科尔沁右翼前旗", id: 2259, type: 3}, {
            fid: 269,
            name: "科尔沁右翼中旗",
            id: 2260,
            type: 3
        }, {fid: 269, name: "扎赉特旗", id: 2261, type: 3}, {fid: 269, name: "突泉县", id: 2262, type: 3}],
        name: "兴安盟",
        id: 269,
        type: 2
    }],
    name: "内蒙古",
    id: 19,
    type: 1
}, {
    fid: 1,
    children: [{
        fid: 20,
        children: [{fid: 270, name: "西夏区", id: 2263, type: 3}, {fid: 270, name: "金凤区", id: 2264, type: 3}, {fid: 270, name: "兴庆区", id: 2265, type: 3}, {
            fid: 270,
            name: "灵武市",
            id: 2266,
            type: 3
        }, {fid: 270, name: "永宁县", id: 2267, type: 3}, {fid: 270, name: "贺兰县", id: 2268, type: 3}],
        name: "银川",
        id: 270,
        type: 2
    }, {
        fid: 20,
        children: [{fid: 271, name: "原州区", id: 2269, type: 3}, {fid: 271, name: "海原县", id: 2270, type: 3}, {fid: 271, name: "西吉县", id: 2271, type: 3}, {
            fid: 271,
            name: "隆德县",
            id: 2272,
            type: 3
        }, {fid: 271, name: "泾源县", id: 2273, type: 3}, {fid: 271, name: "彭阳县", id: 2274, type: 3}],
        name: "固原",
        id: 271,
        type: 2
    }, {
        fid: 20,
        children: [{fid: 272, name: "惠农县", id: 2275, type: 3}, {fid: 272, name: "大武口区", id: 2276, type: 3}, {fid: 272, name: "惠农区", id: 2277, type: 3}, {
            fid: 272,
            name: "陶乐县",
            id: 2278,
            type: 3
        }, {fid: 272, name: "平罗县", id: 2279, type: 3}],
        name: "石嘴山",
        id: 272,
        type: 2
    }, {
        fid: 20,
        children: [{fid: 273, name: "利通区", id: 2280, type: 3}, {fid: 273, name: "中卫县", id: 2281, type: 3}, {fid: 273, name: "青铜峡市", id: 2282, type: 3}, {
            fid: 273,
            name: "中宁县",
            id: 2283,
            type: 3
        }, {fid: 273, name: "盐池县", id: 2284, type: 3}, {fid: 273, name: "同心县", id: 2285, type: 3}],
        name: "吴忠",
        id: 273,
        type: 2
    }, {
        fid: 20,
        children: [{fid: 274, name: "沙坡头区", id: 2286, type: 3}, {fid: 274, name: "海原县", id: 2287, type: 3}, {fid: 274, name: "中宁县", id: 2288, type: 3}],
        name: "中卫",
        id: 274,
        type: 2
    }],
    name: "宁夏",
    id: 20,
    type: 1
}, {
    fid: 1,
    children: [{
        fid: 21,
        children: [{fid: 275, name: "城中区", id: 2289, type: 3}, {fid: 275, name: "城东区", id: 2290, type: 3}, {fid: 275, name: "城西区", id: 2291, type: 3}, {
            fid: 275,
            name: "城北区",
            id: 2292,
            type: 3
        }, {fid: 275, name: "湟中县", id: 2293, type: 3}, {fid: 275, name: "湟源县", id: 2294, type: 3}, {fid: 275, name: "大通", id: 2295, type: 3}],
        name: "西宁",
        id: 275,
        type: 2
    }, {
        fid: 21,
        children: [{fid: 276, name: "玛沁县", id: 2296, type: 3}, {fid: 276, name: "班玛县", id: 2297, type: 3}, {fid: 276, name: "甘德县", id: 2298, type: 3}, {
            fid: 276,
            name: "达日县",
            id: 2299,
            type: 3
        }, {fid: 276, name: "久治县", id: 2300, type: 3}, {fid: 276, name: "玛多县", id: 2301, type: 3}],
        name: "果洛",
        id: 276,
        type: 2
    }, {
        fid: 21,
        children: [{fid: 277, name: "海晏县", id: 2302, type: 3}, {fid: 277, name: "祁连县", id: 2303, type: 3}, {fid: 277, name: "刚察县", id: 2304, type: 3}, {
            fid: 277,
            name: "门源",
            id: 2305,
            type: 3
        }],
        name: "海北",
        id: 277,
        type: 2
    }, {
        fid: 21,
        children: [{fid: 278, name: "平安县", id: 2306, type: 3}, {fid: 278, name: "乐都县", id: 2307, type: 3}, {fid: 278, name: "民和", id: 2308, type: 3}, {
            fid: 278,
            name: "互助",
            id: 2309,
            type: 3
        }, {fid: 278, name: "化隆", id: 2310, type: 3}, {fid: 278, name: "循化", id: 2311, type: 3}],
        name: "海东",
        id: 278,
        type: 2
    }, {
        fid: 21,
        children: [{fid: 279, name: "共和县", id: 2312, type: 3}, {fid: 279, name: "同德县", id: 2313, type: 3}, {fid: 279, name: "贵德县", id: 2314, type: 3}, {
            fid: 279,
            name: "兴海县",
            id: 2315,
            type: 3
        }, {fid: 279, name: "贵南县", id: 2316, type: 3}],
        name: "海南",
        id: 279,
        type: 2
    }, {
        fid: 21,
        children: [{fid: 280, name: "德令哈市", id: 2317, type: 3}, {fid: 280, name: "格尔木市", id: 2318, type: 3}, {fid: 280, name: "乌兰县", id: 2319, type: 3}, {
            fid: 280,
            name: "都兰县",
            id: 2320,
            type: 3
        }, {fid: 280, name: "天峻县", id: 2321, type: 3}],
        name: "海西",
        id: 280,
        type: 2
    }, {
        fid: 21,
        children: [{fid: 281, name: "同仁县", id: 2322, type: 3}, {fid: 281, name: "尖扎县", id: 2323, type: 3}, {fid: 281, name: "泽库县", id: 2324, type: 3}, {
            fid: 281,
            name: "河南蒙古族自治县",
            id: 2325,
            type: 3
        }],
        name: "黄南",
        id: 281,
        type: 2
    }, {
        fid: 21,
        children: [{fid: 282, name: "玉树县", id: 2326, type: 3}, {fid: 282, name: "杂多县", id: 2327, type: 3}, {fid: 282, name: "称多县", id: 2328, type: 3}, {
            fid: 282,
            name: "治多县",
            id: 2329,
            type: 3
        }, {fid: 282, name: "囊谦县", id: 2330, type: 3}, {fid: 282, name: "曲麻莱县", id: 2331, type: 3}],
        name: "玉树",
        id: 282,
        type: 2
    }],
    name: "青海",
    id: 21,
    type: 1
}, {
    fid: 1,
    children: [{
        fid: 22,
        children: [{fid: 283, name: "市中区", id: 2332, type: 3}, {fid: 283, name: "历下区", id: 2333, type: 3}, {fid: 283, name: "天桥区", id: 2334, type: 3}, {
            fid: 283,
            name: "槐荫区",
            id: 2335,
            type: 3
        }, {fid: 283, name: "历城区", id: 2336, type: 3}, {fid: 283, name: "长清区", id: 2337, type: 3}, {fid: 283, name: "章丘市", id: 2338, type: 3}, {
            fid: 283,
            name: "平阴县",
            id: 2339,
            type: 3
        }, {fid: 283, name: "济阳县", id: 2340, type: 3}, {fid: 283, name: "商河县", id: 2341, type: 3}],
        name: "济南",
        id: 283,
        type: 2
    }, {
        fid: 22,
        children: [{fid: 284, name: "市南区", id: 2342, type: 3}, {fid: 284, name: "市北区", id: 2343, type: 3}, {fid: 284, name: "城阳区", id: 2344, type: 3}, {
            fid: 284,
            name: "四方区",
            id: 2345,
            type: 3
        }, {fid: 284, name: "李沧区", id: 2346, type: 3}, {fid: 284, name: "黄岛区", id: 2347, type: 3}, {fid: 284, name: "崂山区", id: 2348, type: 3}, {
            fid: 284,
            name: "胶州市",
            id: 2349,
            type: 3
        }, {fid: 284, name: "即墨市", id: 2350, type: 3}, {fid: 284, name: "平度市", id: 2351, type: 3}, {fid: 284, name: "胶南市", id: 2352, type: 3}, {
            fid: 284,
            name: "莱西市",
            id: 2353,
            type: 3
        }],
        name: "青岛",
        id: 284,
        type: 2
    }, {
        fid: 22,
        children: [{fid: 285, name: "滨城区", id: 2354, type: 3}, {fid: 285, name: "惠民县", id: 2355, type: 3}, {fid: 285, name: "阳信县", id: 2356, type: 3}, {
            fid: 285,
            name: "无棣县",
            id: 2357,
            type: 3
        }, {fid: 285, name: "沾化县", id: 2358, type: 3}, {fid: 285, name: "博兴县", id: 2359, type: 3}, {fid: 285, name: "邹平县", id: 2360, type: 3}],
        name: "滨州",
        id: 285,
        type: 2
    }, {
        fid: 22,
        children: [{fid: 286, name: "德城区", id: 2361, type: 3}, {fid: 286, name: "陵县", id: 2362, type: 3}, {fid: 286, name: "乐陵市", id: 2363, type: 3}, {
            fid: 286,
            name: "禹城市",
            id: 2364,
            type: 3
        }, {fid: 286, name: "宁津县", id: 2365, type: 3}, {fid: 286, name: "庆云县", id: 2366, type: 3}, {fid: 286, name: "临邑县", id: 2367, type: 3}, {
            fid: 286,
            name: "齐河县",
            id: 2368,
            type: 3
        }, {fid: 286, name: "平原县", id: 2369, type: 3}, {fid: 286, name: "夏津县", id: 2370, type: 3}, {fid: 286, name: "武城县", id: 2371, type: 3}],
        name: "德州",
        id: 286,
        type: 2
    }, {
        fid: 22,
        children: [{fid: 287, name: "东营区", id: 2372, type: 3}, {fid: 287, name: "河口区", id: 2373, type: 3}, {fid: 287, name: "垦利县", id: 2374, type: 3}, {
            fid: 287,
            name: "利津县",
            id: 2375,
            type: 3
        }, {fid: 287, name: "广饶县", id: 2376, type: 3}],
        name: "东营",
        id: 287,
        type: 2
    }, {
        fid: 22,
        children: [{fid: 288, name: "牡丹区", id: 2377, type: 3}, {fid: 288, name: "曹县", id: 2378, type: 3}, {fid: 288, name: "单县", id: 2379, type: 3}, {
            fid: 288,
            name: "成武县",
            id: 2380,
            type: 3
        }, {fid: 288, name: "巨野县", id: 2381, type: 3}, {fid: 288, name: "郓城县", id: 2382, type: 3}, {fid: 288, name: "鄄城县", id: 2383, type: 3}, {
            fid: 288,
            name: "定陶县",
            id: 2384,
            type: 3
        }, {fid: 288, name: "东明县", id: 2385, type: 3}],
        name: "菏泽",
        id: 288,
        type: 2
    }, {
        fid: 22,
        children: [{fid: 289, name: "市中区", id: 2386, type: 3}, {fid: 289, name: "任城区", id: 2387, type: 3}, {fid: 289, name: "曲阜市", id: 2388, type: 3}, {
            fid: 289,
            name: "兖州市",
            id: 2389,
            type: 3
        }, {fid: 289, name: "邹城市", id: 2390, type: 3}, {fid: 289, name: "微山县", id: 2391, type: 3}, {fid: 289, name: "鱼台县", id: 2392, type: 3}, {
            fid: 289,
            name: "金乡县",
            id: 2393,
            type: 3
        }, {fid: 289, name: "嘉祥县", id: 2394, type: 3}, {fid: 289, name: "汶上县", id: 2395, type: 3}, {fid: 289, name: "泗水县", id: 2396, type: 3}, {
            fid: 289,
            name: "梁山县",
            id: 2397,
            type: 3
        }],
        name: "济宁",
        id: 289,
        type: 2
    }, {fid: 22, children: [{fid: 290, name: "莱城区", id: 2398, type: 3}, {fid: 290, name: "钢城区", id: 2399, type: 3}], name: "莱芜", id: 290, type: 2}, {
        fid: 22,
        children: [{fid: 291, name: "东昌府区", id: 2400, type: 3}, {fid: 291, name: "临清市", id: 2401, type: 3}, {fid: 291, name: "阳谷县", id: 2402, type: 3}, {
            fid: 291,
            name: "莘县",
            id: 2403,
            type: 3
        }, {fid: 291, name: "茌平县", id: 2404, type: 3}, {fid: 291, name: "东阿县", id: 2405, type: 3}, {fid: 291, name: "冠县", id: 2406, type: 3}, {
            fid: 291,
            name: "高唐县",
            id: 2407,
            type: 3
        }],
        name: "聊城",
        id: 291,
        type: 2
    }, {
        fid: 22,
        children: [{fid: 292, name: "兰山区", id: 2408, type: 3}, {fid: 292, name: "罗庄区", id: 2409, type: 3}, {fid: 292, name: "河东区", id: 2410, type: 3}, {
            fid: 292,
            name: "沂南县",
            id: 2411,
            type: 3
        }, {fid: 292, name: "郯城县", id: 2412, type: 3}, {fid: 292, name: "沂水县", id: 2413, type: 3}, {fid: 292, name: "苍山县", id: 2414, type: 3}, {
            fid: 292,
            name: "费县",
            id: 2415,
            type: 3
        }, {fid: 292, name: "平邑县", id: 2416, type: 3}, {fid: 292, name: "莒南县", id: 2417, type: 3}, {fid: 292, name: "蒙阴县", id: 2418, type: 3}, {
            fid: 292,
            name: "临沭县",
            id: 2419,
            type: 3
        }],
        name: "临沂",
        id: 292,
        type: 2
    }, {
        fid: 22,
        children: [{fid: 293, name: "东港区", id: 2420, type: 3}, {fid: 293, name: "岚山区", id: 2421, type: 3}, {fid: 293, name: "五莲县", id: 2422, type: 3}, {
            fid: 293,
            name: "莒县",
            id: 2423,
            type: 3
        }],
        name: "日照",
        id: 293,
        type: 2
    }, {
        fid: 22,
        children: [{fid: 294, name: "泰山区", id: 2424, type: 3}, {fid: 294, name: "岱岳区", id: 2425, type: 3}, {fid: 294, name: "新泰市", id: 2426, type: 3}, {
            fid: 294,
            name: "肥城市",
            id: 2427,
            type: 3
        }, {fid: 294, name: "宁阳县", id: 2428, type: 3}, {fid: 294, name: "东平县", id: 2429, type: 3}],
        name: "泰安",
        id: 294,
        type: 2
    }, {
        fid: 22,
        children: [{fid: 295, name: "荣成市", id: 2430, type: 3}, {fid: 295, name: "乳山市", id: 2431, type: 3}, {fid: 295, name: "环翠区", id: 2432, type: 3}, {
            fid: 295,
            name: "文登市",
            id: 2433,
            type: 3
        }],
        name: "威海",
        id: 295,
        type: 2
    }, {
        fid: 22,
        children: [{fid: 296, name: "潍城区", id: 2434, type: 3}, {fid: 296, name: "寒亭区", id: 2435, type: 3}, {fid: 296, name: "坊子区", id: 2436, type: 3}, {
            fid: 296,
            name: "奎文区",
            id: 2437,
            type: 3
        }, {fid: 296, name: "青州市", id: 2438, type: 3}, {fid: 296, name: "诸城市", id: 2439, type: 3}, {fid: 296, name: "寿光市", id: 2440, type: 3}, {
            fid: 296,
            name: "安丘市",
            id: 2441,
            type: 3
        }, {fid: 296, name: "高密市", id: 2442, type: 3}, {fid: 296, name: "昌邑市", id: 2443, type: 3}, {fid: 296, name: "临朐县", id: 2444, type: 3}, {
            fid: 296,
            name: "昌乐县",
            id: 2445,
            type: 3
        }],
        name: "潍坊",
        id: 296,
        type: 2
    }, {
        fid: 22,
        children: [{fid: 297, name: "芝罘区", id: 2446, type: 3}, {fid: 297, name: "福山区", id: 2447, type: 3}, {fid: 297, name: "牟平区", id: 2448, type: 3}, {
            fid: 297,
            name: "莱山区",
            id: 2449,
            type: 3
        }, {fid: 297, name: "开发区", id: 2450, type: 3}, {fid: 297, name: "龙口市", id: 2451, type: 3}, {fid: 297, name: "莱阳市", id: 2452, type: 3}, {
            fid: 297,
            name: "莱州市",
            id: 2453,
            type: 3
        }, {fid: 297, name: "蓬莱市", id: 2454, type: 3}, {fid: 297, name: "招远市", id: 2455, type: 3}, {fid: 297, name: "栖霞市", id: 2456, type: 3}, {
            fid: 297,
            name: "海阳市",
            id: 2457,
            type: 3
        }, {fid: 297, name: "长岛县", id: 2458, type: 3}],
        name: "烟台",
        id: 297,
        type: 2
    }, {
        fid: 22,
        children: [{fid: 298, name: "市中区", id: 2459, type: 3}, {fid: 298, name: "山亭区", id: 2460, type: 3}, {fid: 298, name: "峄城区", id: 2461, type: 3}, {
            fid: 298,
            name: "台儿庄区",
            id: 2462,
            type: 3
        }, {fid: 298, name: "薛城区", id: 2463, type: 3}, {fid: 298, name: "滕州市", id: 2464, type: 3}],
        name: "枣庄",
        id: 298,
        type: 2
    }, {
        fid: 22,
        children: [{fid: 299, name: "张店区", id: 2465, type: 3}, {fid: 299, name: "临淄区", id: 2466, type: 3}, {fid: 299, name: "淄川区", id: 2467, type: 3}, {
            fid: 299,
            name: "博山区",
            id: 2468,
            type: 3
        }, {fid: 299, name: "周村区", id: 2469, type: 3}, {fid: 299, name: "桓台县", id: 2470, type: 3}, {fid: 299, name: "高青县", id: 2471, type: 3}, {
            fid: 299,
            name: "沂源县",
            id: 2472,
            type: 3
        }],
        name: "淄博",
        id: 299,
        type: 2
    }],
    name: "山东",
    id: 22,
    type: 1
}, {
    fid: 1,
    children: [{
        fid: 23,
        children: [{fid: 300, name: "杏花岭区", id: 2473, type: 3}, {fid: 300, name: "小店区", id: 2474, type: 3}, {fid: 300, name: "迎泽区", id: 2475, type: 3}, {
            fid: 300,
            name: "尖草坪区",
            id: 2476,
            type: 3
        }, {fid: 300, name: "万柏林区", id: 2477, type: 3}, {fid: 300, name: "晋源区", id: 2478, type: 3}, {fid: 300, name: "高新开发区", id: 2479, type: 3}, {
            fid: 300,
            name: "民营经济开发区",
            id: 2480,
            type: 3
        }, {fid: 300, name: "经济技术开发区", id: 2481, type: 3}, {fid: 300, name: "清徐县", id: 2482, type: 3}, {fid: 300, name: "阳曲县", id: 2483, type: 3}, {
            fid: 300,
            name: "娄烦县",
            id: 2484,
            type: 3
        }, {fid: 300, name: "古交市", id: 2485, type: 3}],
        name: "太原",
        id: 300,
        type: 2
    }, {
        fid: 23,
        children: [{fid: 301, name: "城区", id: 2486, type: 3}, {fid: 301, name: "郊区", id: 2487, type: 3}, {fid: 301, name: "沁县", id: 2488, type: 3}, {
            fid: 301,
            name: "潞城市",
            id: 2489,
            type: 3
        }, {fid: 301, name: "长治县", id: 2490, type: 3}, {fid: 301, name: "襄垣县", id: 2491, type: 3}, {fid: 301, name: "屯留县", id: 2492, type: 3}, {
            fid: 301,
            name: "平顺县",
            id: 2493,
            type: 3
        }, {fid: 301, name: "黎城县", id: 2494, type: 3}, {fid: 301, name: "壶关县", id: 2495, type: 3}, {fid: 301, name: "长子县", id: 2496, type: 3}, {
            fid: 301,
            name: "武乡县",
            id: 2497,
            type: 3
        }, {fid: 301, name: "沁源县", id: 2498, type: 3}],
        name: "长治",
        id: 301,
        type: 2
    }, {
        fid: 23,
        children: [{fid: 302, name: "城区", id: 2499, type: 3}, {fid: 302, name: "矿区", id: 2500, type: 3}, {fid: 302, name: "南郊区", id: 2501, type: 3}, {
            fid: 302,
            name: "新荣区",
            id: 2502,
            type: 3
        }, {fid: 302, name: "阳高县", id: 2503, type: 3}, {fid: 302, name: "天镇县", id: 2504, type: 3}, {fid: 302, name: "广灵县", id: 2505, type: 3}, {
            fid: 302,
            name: "灵丘县",
            id: 2506,
            type: 3
        }, {fid: 302, name: "浑源县", id: 2507, type: 3}, {fid: 302, name: "左云县", id: 2508, type: 3}, {fid: 302, name: "大同县", id: 2509, type: 3}],
        name: "大同",
        id: 302,
        type: 2
    }, {
        fid: 23,
        children: [{fid: 303, name: "城区", id: 2510, type: 3}, {fid: 303, name: "高平市", id: 2511, type: 3}, {fid: 303, name: "沁水县", id: 2512, type: 3}, {
            fid: 303,
            name: "阳城县",
            id: 2513,
            type: 3
        }, {fid: 303, name: "陵川县", id: 2514, type: 3}, {fid: 303, name: "泽州县", id: 2515, type: 3}],
        name: "晋城",
        id: 303,
        type: 2
    }, {
        fid: 23,
        children: [{fid: 304, name: "榆次区", id: 2516, type: 3}, {fid: 304, name: "介休市", id: 2517, type: 3}, {fid: 304, name: "榆社县", id: 2518, type: 3}, {
            fid: 304,
            name: "左权县",
            id: 2519,
            type: 3
        }, {fid: 304, name: "和顺县", id: 2520, type: 3}, {fid: 304, name: "昔阳县", id: 2521, type: 3}, {fid: 304, name: "寿阳县", id: 2522, type: 3}, {
            fid: 304,
            name: "太谷县",
            id: 2523,
            type: 3
        }, {fid: 304, name: "祁县", id: 2524, type: 3}, {fid: 304, name: "平遥县", id: 2525, type: 3}, {fid: 304, name: "灵石县", id: 2526, type: 3}],
        name: "晋中",
        id: 304,
        type: 2
    }, {
        fid: 23,
        children: [{fid: 305, name: "尧都区", id: 2527, type: 3}, {fid: 305, name: "侯马市", id: 2528, type: 3}, {fid: 305, name: "霍州市", id: 2529, type: 3}, {
            fid: 305,
            name: "曲沃县",
            id: 2530,
            type: 3
        }, {fid: 305, name: "翼城县", id: 2531, type: 3}, {fid: 305, name: "襄汾县", id: 2532, type: 3}, {fid: 305, name: "洪洞县", id: 2533, type: 3}, {
            fid: 305,
            name: "吉县",
            id: 2534,
            type: 3
        }, {fid: 305, name: "安泽县", id: 2535, type: 3}, {fid: 305, name: "浮山县", id: 2536, type: 3}, {fid: 305, name: "古县", id: 2537, type: 3}, {
            fid: 305,
            name: "乡宁县",
            id: 2538,
            type: 3
        }, {fid: 305, name: "大宁县", id: 2539, type: 3}, {fid: 305, name: "隰县", id: 2540, type: 3}, {fid: 305, name: "永和县", id: 2541, type: 3}, {
            fid: 305,
            name: "蒲县",
            id: 2542,
            type: 3
        }, {fid: 305, name: "汾西县", id: 2543, type: 3}],
        name: "临汾",
        id: 305,
        type: 2
    }, {
        fid: 23,
        children: [{fid: 306, name: "离石市", id: 2544, type: 3}, {fid: 306, name: "离石区", id: 2545, type: 3}, {fid: 306, name: "孝义市", id: 2546, type: 3}, {
            fid: 306,
            name: "汾阳市",
            id: 2547,
            type: 3
        }, {fid: 306, name: "文水县", id: 2548, type: 3}, {fid: 306, name: "交城县", id: 2549, type: 3}, {fid: 306, name: "兴县", id: 2550, type: 3}, {
            fid: 306,
            name: "临县",
            id: 2551,
            type: 3
        }, {fid: 306, name: "柳林县", id: 2552, type: 3}, {fid: 306, name: "石楼县", id: 2553, type: 3}, {fid: 306, name: "岚县", id: 2554, type: 3}, {
            fid: 306,
            name: "方山县",
            id: 2555,
            type: 3
        }, {fid: 306, name: "中阳县", id: 2556, type: 3}, {fid: 306, name: "交口县", id: 2557, type: 3}],
        name: "吕梁",
        id: 306,
        type: 2
    }, {
        fid: 23,
        children: [{fid: 307, name: "朔城区", id: 2558, type: 3}, {fid: 307, name: "平鲁区", id: 2559, type: 3}, {fid: 307, name: "山阴县", id: 2560, type: 3}, {
            fid: 307,
            name: "应县",
            id: 2561,
            type: 3
        }, {fid: 307, name: "右玉县", id: 2562, type: 3}, {fid: 307, name: "怀仁县", id: 2563, type: 3}],
        name: "朔州",
        id: 307,
        type: 2
    }, {
        fid: 23,
        children: [{fid: 308, name: "忻府区", id: 2564, type: 3}, {fid: 308, name: "原平市", id: 2565, type: 3}, {fid: 308, name: "定襄县", id: 2566, type: 3}, {
            fid: 308,
            name: "五台县",
            id: 2567,
            type: 3
        }, {fid: 308, name: "代县", id: 2568, type: 3}, {fid: 308, name: "繁峙县", id: 2569, type: 3}, {fid: 308, name: "宁武县", id: 2570, type: 3}, {
            fid: 308,
            name: "静乐县",
            id: 2571,
            type: 3
        }, {fid: 308, name: "神池县", id: 2572, type: 3}, {fid: 308, name: "五寨县", id: 2573, type: 3}, {fid: 308, name: "岢岚县", id: 2574, type: 3}, {
            fid: 308,
            name: "河曲县",
            id: 2575,
            type: 3
        }, {fid: 308, name: "保德县", id: 2576, type: 3}, {fid: 308, name: "偏关县", id: 2577, type: 3}],
        name: "忻州",
        id: 308,
        type: 2
    }, {
        fid: 23,
        children: [{fid: 309, name: "城区", id: 2578, type: 3}, {fid: 309, name: "矿区", id: 2579, type: 3}, {fid: 309, name: "郊区", id: 2580, type: 3}, {
            fid: 309,
            name: "平定县",
            id: 2581,
            type: 3
        }, {fid: 309, name: "盂县", id: 2582, type: 3}],
        name: "阳泉",
        id: 309,
        type: 2
    }, {
        fid: 23,
        children: [{fid: 310, name: "盐湖区", id: 2583, type: 3}, {fid: 310, name: "永济市", id: 2584, type: 3}, {fid: 310, name: "河津市", id: 2585, type: 3}, {
            fid: 310,
            name: "临猗县",
            id: 2586,
            type: 3
        }, {fid: 310, name: "万荣县", id: 2587, type: 3}, {fid: 310, name: "闻喜县", id: 2588, type: 3}, {fid: 310, name: "稷山县", id: 2589, type: 3}, {
            fid: 310,
            name: "新绛县",
            id: 2590,
            type: 3
        }, {fid: 310, name: "绛县", id: 2591, type: 3}, {fid: 310, name: "垣曲县", id: 2592, type: 3}, {fid: 310, name: "夏县", id: 2593, type: 3}, {
            fid: 310,
            name: "平陆县",
            id: 2594,
            type: 3
        }, {fid: 310, name: "芮城县", id: 2595, type: 3}],
        name: "运城",
        id: 310,
        type: 2
    }],
    name: "山西",
    id: 23,
    type: 1
}, {
    fid: 1,
    children: [{
        fid: 24,
        children: [{fid: 311, name: "莲湖区", id: 2596, type: 3}, {fid: 311, name: "新城区", id: 2597, type: 3}, {fid: 311, name: "碑林区", id: 2598, type: 3}, {
            fid: 311,
            name: "雁塔区",
            id: 2599,
            type: 3
        }, {fid: 311, name: "灞桥区", id: 2600, type: 3}, {fid: 311, name: "未央区", id: 2601, type: 3}, {fid: 311, name: "阎良区", id: 2602, type: 3}, {
            fid: 311,
            name: "临潼区",
            id: 2603,
            type: 3
        }, {fid: 311, name: "长安区", id: 2604, type: 3}, {fid: 311, name: "蓝田县", id: 2605, type: 3}, {fid: 311, name: "周至县", id: 2606, type: 3}, {
            fid: 311,
            name: "户县",
            id: 2607,
            type: 3
        }, {fid: 311, name: "高陵县", id: 2608, type: 3}],
        name: "西安",
        id: 311,
        type: 2
    }, {
        fid: 24,
        children: [{fid: 312, name: "汉滨区", id: 2609, type: 3}, {fid: 312, name: "汉阴县", id: 2610, type: 3}, {fid: 312, name: "石泉县", id: 2611, type: 3}, {
            fid: 312,
            name: "宁陕县",
            id: 2612,
            type: 3
        }, {fid: 312, name: "紫阳县", id: 2613, type: 3}, {fid: 312, name: "岚皋县", id: 2614, type: 3}, {fid: 312, name: "平利县", id: 2615, type: 3}, {
            fid: 312,
            name: "镇坪县",
            id: 2616,
            type: 3
        }, {fid: 312, name: "旬阳县", id: 2617, type: 3}, {fid: 312, name: "白河县", id: 2618, type: 3}],
        name: "安康",
        id: 312,
        type: 2
    }, {
        fid: 24,
        children: [{fid: 313, name: "陈仓区", id: 2619, type: 3}, {fid: 313, name: "渭滨区", id: 2620, type: 3}, {fid: 313, name: "金台区", id: 2621, type: 3}, {
            fid: 313,
            name: "凤翔县",
            id: 2622,
            type: 3
        }, {fid: 313, name: "岐山县", id: 2623, type: 3}, {fid: 313, name: "扶风县", id: 2624, type: 3}, {fid: 313, name: "眉县", id: 2625, type: 3}, {
            fid: 313,
            name: "陇县",
            id: 2626,
            type: 3
        }, {fid: 313, name: "千阳县", id: 2627, type: 3}, {fid: 313, name: "麟游县", id: 2628, type: 3}, {fid: 313, name: "凤县", id: 2629, type: 3}, {
            fid: 313,
            name: "太白县",
            id: 2630,
            type: 3
        }],
        name: "宝鸡",
        id: 313,
        type: 2
    }, {
        fid: 24,
        children: [{fid: 314, name: "汉台区", id: 2631, type: 3}, {fid: 314, name: "南郑县", id: 2632, type: 3}, {fid: 314, name: "城固县", id: 2633, type: 3}, {
            fid: 314,
            name: "洋县",
            id: 2634,
            type: 3
        }, {fid: 314, name: "西乡县", id: 2635, type: 3}, {fid: 314, name: "勉县", id: 2636, type: 3}, {fid: 314, name: "宁强县", id: 2637, type: 3}, {
            fid: 314,
            name: "略阳县",
            id: 2638,
            type: 3
        }, {fid: 314, name: "镇巴县", id: 2639, type: 3}, {fid: 314, name: "留坝县", id: 2640, type: 3}, {fid: 314, name: "佛坪县", id: 2641, type: 3}],
        name: "汉中",
        id: 314,
        type: 2
    }, {
        fid: 24,
        children: [{fid: 315, name: "商州区", id: 2642, type: 3}, {fid: 315, name: "洛南县", id: 2643, type: 3}, {fid: 315, name: "丹凤县", id: 2644, type: 3}, {
            fid: 315,
            name: "商南县",
            id: 2645,
            type: 3
        }, {fid: 315, name: "山阳县", id: 2646, type: 3}, {fid: 315, name: "镇安县", id: 2647, type: 3}, {fid: 315, name: "柞水县", id: 2648, type: 3}],
        name: "商洛",
        id: 315,
        type: 2
    }, {
        fid: 24,
        children: [{fid: 316, name: "耀州区", id: 2649, type: 3}, {fid: 316, name: "王益区", id: 2650, type: 3}, {fid: 316, name: "印台区", id: 2651, type: 3}, {
            fid: 316,
            name: "宜君县",
            id: 2652,
            type: 3
        }],
        name: "铜川",
        id: 316,
        type: 2
    }, {
        fid: 24,
        children: [{fid: 317, name: "临渭区", id: 2653, type: 3}, {fid: 317, name: "韩城市", id: 2654, type: 3}, {fid: 317, name: "华阴市", id: 2655, type: 3}, {
            fid: 317,
            name: "华县",
            id: 2656,
            type: 3
        }, {fid: 317, name: "潼关县", id: 2657, type: 3}, {fid: 317, name: "大荔县", id: 2658, type: 3}, {fid: 317, name: "合阳县", id: 2659, type: 3}, {
            fid: 317,
            name: "澄城县",
            id: 2660,
            type: 3
        }, {fid: 317, name: "蒲城县", id: 2661, type: 3}, {fid: 317, name: "白水县", id: 2662, type: 3}, {fid: 317, name: "富平县", id: 2663, type: 3}],
        name: "渭南",
        id: 317,
        type: 2
    }, {
        fid: 24,
        children: [{fid: 318, name: "秦都区", id: 2664, type: 3}, {fid: 318, name: "渭城区", id: 2665, type: 3}, {fid: 318, name: "杨陵区", id: 2666, type: 3}, {
            fid: 318,
            name: "兴平市",
            id: 2667,
            type: 3
        }, {fid: 318, name: "三原县", id: 2668, type: 3}, {fid: 318, name: "泾阳县", id: 2669, type: 3}, {fid: 318, name: "乾县", id: 2670, type: 3}, {
            fid: 318,
            name: "礼泉县",
            id: 2671,
            type: 3
        }, {fid: 318, name: "永寿县", id: 2672, type: 3}, {fid: 318, name: "彬县", id: 2673, type: 3}, {fid: 318, name: "长武县", id: 2674, type: 3}, {
            fid: 318,
            name: "旬邑县",
            id: 2675,
            type: 3
        }, {fid: 318, name: "淳化县", id: 2676, type: 3}, {fid: 318, name: "武功县", id: 2677, type: 3}],
        name: "咸阳",
        id: 318,
        type: 2
    }, {
        fid: 24,
        children: [{fid: 319, name: "吴起县", id: 2678, type: 3}, {fid: 319, name: "宝塔区", id: 2679, type: 3}, {fid: 319, name: "延长县", id: 2680, type: 3}, {
            fid: 319,
            name: "延川县",
            id: 2681,
            type: 3
        }, {fid: 319, name: "子长县", id: 2682, type: 3}, {fid: 319, name: "安塞县", id: 2683, type: 3}, {fid: 319, name: "志丹县", id: 2684, type: 3}, {
            fid: 319,
            name: "甘泉县",
            id: 2685,
            type: 3
        }, {fid: 319, name: "富县", id: 2686, type: 3}, {fid: 319, name: "洛川县", id: 2687, type: 3}, {fid: 319, name: "宜川县", id: 2688, type: 3}, {
            fid: 319,
            name: "黄龙县",
            id: 2689,
            type: 3
        }, {fid: 319, name: "黄陵县", id: 2690, type: 3}],
        name: "延安",
        id: 319,
        type: 2
    }, {
        fid: 24,
        children: [{fid: 320, name: "榆阳区", id: 2691, type: 3}, {fid: 320, name: "神木县", id: 2692, type: 3}, {fid: 320, name: "府谷县", id: 2693, type: 3}, {
            fid: 320,
            name: "横山县",
            id: 2694,
            type: 3
        }, {fid: 320, name: "靖边县", id: 2695, type: 3}, {fid: 320, name: "定边县", id: 2696, type: 3}, {fid: 320, name: "绥德县", id: 2697, type: 3}, {
            fid: 320,
            name: "米脂县",
            id: 2698,
            type: 3
        }, {fid: 320, name: "佳县", id: 2699, type: 3}, {fid: 320, name: "吴堡县", id: 2700, type: 3}, {fid: 320, name: "清涧县", id: 2701, type: 3}, {
            fid: 320,
            name: "子洲县",
            id: 2702,
            type: 3
        }],
        name: "榆林",
        id: 320,
        type: 2
    }],
    name: "陕西",
    id: 24,
    type: 1
}, {
    fid: 1,
    children: [{
        fid: 25,
        children: [{fid: 321, name: "长宁区", id: 2703, type: 3}, {fid: 321, name: "闸北区", id: 2704, type: 3}, {fid: 321, name: "闵行区", id: 2705, type: 3}, {
            fid: 321,
            name: "徐汇区",
            id: 2706,
            type: 3
        }, {fid: 321, name: "浦东新区", id: 2707, type: 3}, {fid: 321, name: "杨浦区", id: 2708, type: 3}, {fid: 321, name: "普陀区", id: 2709, type: 3}, {
            fid: 321,
            name: "静安区",
            id: 2710,
            type: 3
        }, {fid: 321, name: "卢湾区", id: 2711, type: 3}, {fid: 321, name: "虹口区", id: 2712, type: 3}, {fid: 321, name: "黄浦区", id: 2713, type: 3}, {
            fid: 321,
            name: "南汇区",
            id: 2714,
            type: 3
        }, {fid: 321, name: "松江区", id: 2715, type: 3}, {fid: 321, name: "嘉定区", id: 2716, type: 3}, {fid: 321, name: "宝山区", id: 2717, type: 3}, {
            fid: 321,
            name: "青浦区",
            id: 2718,
            type: 3
        }, {fid: 321, name: "金山区", id: 2719, type: 3}, {fid: 321, name: "奉贤区", id: 2720, type: 3}, {fid: 321, name: "崇明县", id: 2721, type: 3}],
        name: "上海",
        id: 321,
        type: 2
    }],
    name: "上海",
    id: 25,
    type: 1
}, {
    fid: 1, children: [{
        fid: 26,
        children: [{fid: 322, name: "青羊区", id: 2722, type: 3}, {fid: 322, name: "锦江区", id: 2723, type: 3}, {fid: 322, name: "金牛区", id: 2724, type: 3}, {
            fid: 322,
            name: "武侯区",
            id: 2725,
            type: 3
        }, {fid: 322, name: "成华区", id: 2726, type: 3}, {fid: 322, name: "龙泉驿区", id: 2727, type: 3}, {fid: 322, name: "青白江区", id: 2728, type: 3}, {
            fid: 322,
            name: "新都区",
            id: 2729,
            type: 3
        }, {fid: 322, name: "温江区", id: 2730, type: 3}, {fid: 322, name: "高新区", id: 2731, type: 3}, {fid: 322, name: "高新西区", id: 2732, type: 3}, {
            fid: 322,
            name: "都江堰市",
            id: 2733,
            type: 3
        }, {fid: 322, name: "彭州市", id: 2734, type: 3}, {fid: 322, name: "邛崃市", id: 2735, type: 3}, {fid: 322, name: "崇州市", id: 2736, type: 3}, {
            fid: 322,
            name: "金堂县",
            id: 2737,
            type: 3
        }, {fid: 322, name: "双流县", id: 2738, type: 3}, {fid: 322, name: "郫县", id: 2739, type: 3}, {fid: 322, name: "大邑县", id: 2740, type: 3}, {
            fid: 322,
            name: "蒲江县",
            id: 2741,
            type: 3
        }, {fid: 322, name: "新津县", id: 2742, type: 3}, {fid: 322, name: "都江堰市", id: 2743, type: 3}, {fid: 322, name: "彭州市", id: 2744, type: 3}, {
            fid: 322,
            name: "邛崃市",
            id: 2745,
            type: 3
        }, {fid: 322, name: "崇州市", id: 2746, type: 3}, {fid: 322, name: "金堂县", id: 2747, type: 3}, {fid: 322, name: "双流县", id: 2748, type: 3}, {
            fid: 322,
            name: "郫县",
            id: 2749,
            type: 3
        }, {fid: 322, name: "大邑县", id: 2750, type: 3}, {fid: 322, name: "蒲江县", id: 2751, type: 3}, {fid: 322, name: "新津县", id: 2752, type: 3}],
        name: "成都",
        id: 322,
        type: 2
    }, {
        fid: 26,
        children: [{fid: 323, name: "涪城区", id: 2753, type: 3}, {fid: 323, name: "游仙区", id: 2754, type: 3}, {fid: 323, name: "江油市", id: 2755, type: 3}, {
            fid: 323,
            name: "盐亭县",
            id: 2756,
            type: 3
        }, {fid: 323, name: "三台县", id: 2757, type: 3}, {fid: 323, name: "平武县", id: 2758, type: 3}, {fid: 323, name: "安县", id: 2759, type: 3}, {
            fid: 323,
            name: "梓潼县",
            id: 2760,
            type: 3
        }, {fid: 323, name: "北川县", id: 2761, type: 3}],
        name: "绵阳",
        id: 323,
        type: 2
    }, {
        fid: 26,
        children: [{fid: 324, name: "马尔康县", id: 2762, type: 3}, {fid: 324, name: "汶川县", id: 2763, type: 3}, {fid: 324, name: "理县", id: 2764, type: 3}, {
            fid: 324,
            name: "茂县",
            id: 2765,
            type: 3
        }, {fid: 324, name: "松潘县", id: 2766, type: 3}, {fid: 324, name: "九寨沟县", id: 2767, type: 3}, {fid: 324, name: "金川县", id: 2768, type: 3}, {
            fid: 324,
            name: "小金县",
            id: 2769,
            type: 3
        }, {fid: 324, name: "黑水县", id: 2770, type: 3}, {fid: 324, name: "壤塘县", id: 2771, type: 3}, {fid: 324, name: "阿坝县", id: 2772, type: 3}, {
            fid: 324,
            name: "若尔盖县",
            id: 2773,
            type: 3
        }, {fid: 324, name: "红原县", id: 2774, type: 3}],
        name: "阿坝",
        id: 324,
        type: 2
    }, {
        fid: 26,
        children: [{fid: 325, name: "巴州区", id: 2775, type: 3}, {fid: 325, name: "通江县", id: 2776, type: 3}, {fid: 325, name: "南江县", id: 2777, type: 3}, {
            fid: 325,
            name: "平昌县",
            id: 2778,
            type: 3
        }],
        name: "巴中",
        id: 325,
        type: 2
    }, {
        fid: 26,
        children: [{fid: 326, name: "通川区", id: 2779, type: 3}, {fid: 326, name: "万源市", id: 2780, type: 3}, {fid: 326, name: "达县", id: 2781, type: 3}, {
            fid: 326,
            name: "宣汉县",
            id: 2782,
            type: 3
        }, {fid: 326, name: "开江县", id: 2783, type: 3}, {fid: 326, name: "大竹县", id: 2784, type: 3}, {fid: 326, name: "渠县", id: 2785, type: 3}],
        name: "达州",
        id: 326,
        type: 2
    }, {
        fid: 26,
        children: [{fid: 327, name: "旌阳区", id: 2786, type: 3}, {fid: 327, name: "广汉市", id: 2787, type: 3}, {fid: 327, name: "什邡市", id: 2788, type: 3}, {
            fid: 327,
            name: "绵竹市",
            id: 2789,
            type: 3
        }, {fid: 327, name: "罗江县", id: 2790, type: 3}, {fid: 327, name: "中江县", id: 2791, type: 3}],
        name: "德阳",
        id: 327,
        type: 2
    }, {
        fid: 26,
        children: [{fid: 328, name: "康定县", id: 2792, type: 3}, {fid: 328, name: "丹巴县", id: 2793, type: 3}, {fid: 328, name: "泸定县", id: 2794, type: 3}, {
            fid: 328,
            name: "炉霍县",
            id: 2795,
            type: 3
        }, {fid: 328, name: "九龙县", id: 2796, type: 3}, {fid: 328, name: "甘孜县", id: 2797, type: 3}, {fid: 328, name: "雅江县", id: 2798, type: 3}, {
            fid: 328,
            name: "新龙县",
            id: 2799,
            type: 3
        }, {fid: 328, name: "道孚县", id: 2800, type: 3}, {fid: 328, name: "白玉县", id: 2801, type: 3}, {fid: 328, name: "理塘县", id: 2802, type: 3}, {
            fid: 328,
            name: "德格县",
            id: 2803,
            type: 3
        }, {fid: 328, name: "乡城县", id: 2804, type: 3}, {fid: 328, name: "石渠县", id: 2805, type: 3}, {fid: 328, name: "稻城县", id: 2806, type: 3}, {
            fid: 328,
            name: "色达县",
            id: 2807,
            type: 3
        }, {fid: 328, name: "巴塘县", id: 2808, type: 3}, {fid: 328, name: "得荣县", id: 2809, type: 3}],
        name: "甘孜",
        id: 328,
        type: 2
    }, {
        fid: 26,
        children: [{fid: 329, name: "广安区", id: 2810, type: 3}, {fid: 329, name: "华蓥市", id: 2811, type: 3}, {fid: 329, name: "岳池县", id: 2812, type: 3}, {
            fid: 329,
            name: "武胜县",
            id: 2813,
            type: 3
        }, {fid: 329, name: "邻水县", id: 2814, type: 3}],
        name: "广安",
        id: 329,
        type: 2
    }, {
        fid: 26,
        children: [{fid: 330, name: "利州区", id: 2815, type: 3}, {fid: 330, name: "元坝区", id: 2816, type: 3}, {fid: 330, name: "朝天区", id: 2817, type: 3}, {
            fid: 330,
            name: "旺苍县",
            id: 2818,
            type: 3
        }, {fid: 330, name: "青川县", id: 2819, type: 3}, {fid: 330, name: "剑阁县", id: 2820, type: 3}, {fid: 330, name: "苍溪县", id: 2821, type: 3}],
        name: "广元",
        id: 330,
        type: 2
    }, {
        fid: 26,
        children: [{fid: 331, name: "峨眉山市", id: 2822, type: 3}, {fid: 331, name: "乐山市", id: 2823, type: 3}, {fid: 331, name: "犍为县", id: 2824, type: 3}, {
            fid: 331,
            name: "井研县",
            id: 2825,
            type: 3
        }, {fid: 331, name: "夹江县", id: 2826, type: 3}, {fid: 331, name: "沐川县", id: 2827, type: 3}, {fid: 331, name: "峨边", id: 2828, type: 3}, {
            fid: 331,
            name: "马边",
            id: 2829,
            type: 3
        }],
        name: "乐山",
        id: 331,
        type: 2
    }, {
        fid: 26,
        children: [{fid: 332, name: "西昌市", id: 2830, type: 3}, {fid: 332, name: "盐源县", id: 2831, type: 3}, {fid: 332, name: "德昌县", id: 2832, type: 3}, {
            fid: 332,
            name: "会理县",
            id: 2833,
            type: 3
        }, {fid: 332, name: "会东县", id: 2834, type: 3}, {fid: 332, name: "宁南县", id: 2835, type: 3}, {fid: 332, name: "普格县", id: 2836, type: 3}, {
            fid: 332,
            name: "布拖县",
            id: 2837,
            type: 3
        }, {fid: 332, name: "金阳县", id: 2838, type: 3}, {fid: 332, name: "昭觉县", id: 2839, type: 3}, {fid: 332, name: "喜德县", id: 2840, type: 3}, {
            fid: 332,
            name: "冕宁县",
            id: 2841,
            type: 3
        }, {fid: 332, name: "越西县", id: 2842, type: 3}, {fid: 332, name: "甘洛县", id: 2843, type: 3}, {fid: 332, name: "美姑县", id: 2844, type: 3}, {
            fid: 332,
            name: "雷波县",
            id: 2845,
            type: 3
        }, {fid: 332, name: "木里", id: 2846, type: 3}],
        name: "凉山",
        id: 332,
        type: 2
    }, {
        fid: 26,
        children: [{fid: 333, name: "东坡区", id: 2847, type: 3}, {fid: 333, name: "仁寿县", id: 2848, type: 3}, {fid: 333, name: "彭山县", id: 2849, type: 3}, {
            fid: 333,
            name: "洪雅县",
            id: 2850,
            type: 3
        }, {fid: 333, name: "丹棱县", id: 2851, type: 3}, {fid: 333, name: "青神县", id: 2852, type: 3}],
        name: "眉山",
        id: 333,
        type: 2
    }, {
        fid: 26,
        children: [{fid: 334, name: "阆中市", id: 2853, type: 3}, {fid: 334, name: "南部县", id: 2854, type: 3}, {fid: 334, name: "营山县", id: 2855, type: 3}, {
            fid: 334,
            name: "蓬安县",
            id: 2856,
            type: 3
        }, {fid: 334, name: "仪陇县", id: 2857, type: 3}, {fid: 334, name: "顺庆区", id: 2858, type: 3}, {fid: 334, name: "高坪区", id: 2859, type: 3}, {
            fid: 334,
            name: "嘉陵区",
            id: 2860,
            type: 3
        }, {fid: 334, name: "西充县", id: 2861, type: 3}],
        name: "南充",
        id: 334,
        type: 2
    }, {
        fid: 26,
        children: [{fid: 335, name: "市中区", id: 2862, type: 3}, {fid: 335, name: "东兴区", id: 2863, type: 3}, {fid: 335, name: "威远县", id: 2864, type: 3}, {
            fid: 335,
            name: "资中县",
            id: 2865,
            type: 3
        }, {fid: 335, name: "隆昌县", id: 2866, type: 3}],
        name: "内江",
        id: 335,
        type: 2
    }, {
        fid: 26,
        children: [{fid: 336, name: "东  区", id: 2867, type: 3}, {fid: 336, name: "西  区", id: 2868, type: 3}, {fid: 336, name: "仁和区", id: 2869, type: 3}, {
            fid: 336,
            name: "米易县",
            id: 2870,
            type: 3
        }, {fid: 336, name: "盐边县", id: 2871, type: 3}],
        name: "攀枝花",
        id: 336,
        type: 2
    }, {
        fid: 26,
        children: [{fid: 337, name: "船山区", id: 2872, type: 3}, {fid: 337, name: "安居区", id: 2873, type: 3}, {fid: 337, name: "蓬溪县", id: 2874, type: 3}, {
            fid: 337,
            name: "射洪县",
            id: 2875,
            type: 3
        }, {fid: 337, name: "大英县", id: 2876, type: 3}],
        name: "遂宁",
        id: 337,
        type: 2
    }, {
        fid: 26,
        children: [{fid: 338, name: "雨城区", id: 2877, type: 3}, {fid: 338, name: "名山县", id: 2878, type: 3}, {fid: 338, name: "荥经县", id: 2879, type: 3}, {
            fid: 338,
            name: "汉源县",
            id: 2880,
            type: 3
        }, {fid: 338, name: "石棉县", id: 2881, type: 3}, {fid: 338, name: "天全县", id: 2882, type: 3}, {fid: 338, name: "芦山县", id: 2883, type: 3}, {
            fid: 338,
            name: "宝兴县",
            id: 2884,
            type: 3
        }],
        name: "雅安",
        id: 338,
        type: 2
    }, {
        fid: 26,
        children: [{fid: 339, name: "翠屏区", id: 2885, type: 3}, {fid: 339, name: "宜宾县", id: 2886, type: 3}, {fid: 339, name: "南溪县", id: 2887, type: 3}, {
            fid: 339,
            name: "江安县",
            id: 2888,
            type: 3
        }, {fid: 339, name: "长宁县", id: 2889, type: 3}, {fid: 339, name: "高县", id: 2890, type: 3}, {fid: 339, name: "珙县", id: 2891, type: 3}, {
            fid: 339,
            name: "筠连县",
            id: 2892,
            type: 3
        }, {fid: 339, name: "兴文县", id: 2893, type: 3}, {fid: 339, name: "屏山县", id: 2894, type: 3}],
        name: "宜宾",
        id: 339,
        type: 2
    }, {
        fid: 26,
        children: [{fid: 340, name: "雁江区", id: 2895, type: 3}, {fid: 340, name: "简阳市", id: 2896, type: 3}, {fid: 340, name: "安岳县", id: 2897, type: 3}, {
            fid: 340,
            name: "乐至县",
            id: 2898,
            type: 3
        }],
        name: "资阳",
        id: 340,
        type: 2
    }, {
        fid: 26,
        children: [{fid: 341, name: "大安区", id: 2899, type: 3}, {fid: 341, name: "自流井区", id: 2900, type: 3}, {fid: 341, name: "贡井区", id: 2901, type: 3}, {
            fid: 341,
            name: "沿滩区",
            id: 2902,
            type: 3
        }, {fid: 341, name: "荣县", id: 2903, type: 3}, {fid: 341, name: "富顺县", id: 2904, type: 3}],
        name: "自贡",
        id: 341,
        type: 2
    }, {
        fid: 26,
        children: [{fid: 342, name: "江阳区", id: 2905, type: 3}, {fid: 342, name: "纳溪区", id: 2906, type: 3}, {fid: 342, name: "龙马潭区", id: 2907, type: 3}, {
            fid: 342,
            name: "泸县",
            id: 2908,
            type: 3
        }, {fid: 342, name: "合江县", id: 2909, type: 3}, {fid: 342, name: "叙永县", id: 2910, type: 3}, {fid: 342, name: "古蔺县", id: 2911, type: 3}],
        name: "泸州",
        id: 342,
        type: 2
    }], name: "四川", id: 26, type: 1
}, {
    fid: 1,
    children: [{
        fid: 27,
        children: [{fid: 343, name: "和平区", id: 2912, type: 3}, {fid: 343, name: "河西区", id: 2913, type: 3}, {fid: 343, name: "南开区", id: 2914, type: 3}, {
            fid: 343,
            name: "河北区",
            id: 2915,
            type: 3
        }, {fid: 343, name: "河东区", id: 2916, type: 3}, {fid: 343, name: "红桥区", id: 2917, type: 3}, {fid: 343, name: "东丽区", id: 2918, type: 3}, {
            fid: 343,
            name: "津南区",
            id: 2919,
            type: 3
        }, {fid: 343, name: "西青区", id: 2920, type: 3}, {fid: 343, name: "北辰区", id: 2921, type: 3}, {fid: 343, name: "塘沽区", id: 2922, type: 3}, {
            fid: 343,
            name: "汉沽区",
            id: 2923,
            type: 3
        }, {fid: 343, name: "大港区", id: 2924, type: 3}, {fid: 343, name: "武清区", id: 2925, type: 3}, {fid: 343, name: "宝坻区", id: 2926, type: 3}, {
            fid: 343,
            name: "经济开发区",
            id: 2927,
            type: 3
        }, {fid: 343, name: "宁河县", id: 2928, type: 3}, {fid: 343, name: "静海县", id: 2929, type: 3}, {fid: 343, name: "蓟县", id: 2930, type: 3}],
        name: "天津",
        id: 343,
        type: 2
    }],
    name: "天津",
    id: 27,
    type: 1
}, {
    fid: 1,
    children: [{
        fid: 28,
        children: [{fid: 344, name: "城关区", id: 2931, type: 3}, {fid: 344, name: "林周县", id: 2932, type: 3}, {fid: 344, name: "当雄县", id: 2933, type: 3}, {
            fid: 344,
            name: "尼木县",
            id: 2934,
            type: 3
        }, {fid: 344, name: "曲水县", id: 2935, type: 3}, {fid: 344, name: "堆龙德庆县", id: 2936, type: 3}, {fid: 344, name: "达孜县", id: 2937, type: 3}, {
            fid: 344,
            name: "墨竹工卡县",
            id: 2938,
            type: 3
        }],
        name: "拉萨",
        id: 344,
        type: 2
    }, {
        fid: 28,
        children: [{fid: 345, name: "噶尔县", id: 2939, type: 3}, {fid: 345, name: "普兰县", id: 2940, type: 3}, {fid: 345, name: "札达县", id: 2941, type: 3}, {
            fid: 345,
            name: "日土县",
            id: 2942,
            type: 3
        }, {fid: 345, name: "革吉县", id: 2943, type: 3}, {fid: 345, name: "改则县", id: 2944, type: 3}, {fid: 345, name: "措勤县", id: 2945, type: 3}],
        name: "阿里",
        id: 345,
        type: 2
    }, {
        fid: 28,
        children: [{fid: 346, name: "昌都县", id: 2946, type: 3}, {fid: 346, name: "江达县", id: 2947, type: 3}, {fid: 346, name: "贡觉县", id: 2948, type: 3}, {
            fid: 346,
            name: "类乌齐县",
            id: 2949,
            type: 3
        }, {fid: 346, name: "丁青县", id: 2950, type: 3}, {fid: 346, name: "察雅县", id: 2951, type: 3}, {fid: 346, name: "八宿县", id: 2952, type: 3}, {
            fid: 346,
            name: "左贡县",
            id: 2953,
            type: 3
        }, {fid: 346, name: "芒康县", id: 2954, type: 3}, {fid: 346, name: "洛隆县", id: 2955, type: 3}, {fid: 346, name: "边坝县", id: 2956, type: 3}],
        name: "昌都",
        id: 346,
        type: 2
    }, {
        fid: 28,
        children: [{fid: 347, name: "林芝县", id: 2957, type: 3}, {fid: 347, name: "工布江达县", id: 2958, type: 3}, {fid: 347, name: "米林县", id: 2959, type: 3}, {
            fid: 347,
            name: "墨脱县",
            id: 2960,
            type: 3
        }, {fid: 347, name: "波密县", id: 2961, type: 3}, {fid: 347, name: "察隅县", id: 2962, type: 3}, {fid: 347, name: "朗县", id: 2963, type: 3}],
        name: "林芝",
        id: 347,
        type: 2
    }, {
        fid: 28,
        children: [{fid: 348, name: "那曲县", id: 2964, type: 3}, {fid: 348, name: "嘉黎县", id: 2965, type: 3}, {fid: 348, name: "比如县", id: 2966, type: 3}, {
            fid: 348,
            name: "聂荣县",
            id: 2967,
            type: 3
        }, {fid: 348, name: "安多县", id: 2968, type: 3}, {fid: 348, name: "申扎县", id: 2969, type: 3}, {fid: 348, name: "索县", id: 2970, type: 3}, {
            fid: 348,
            name: "班戈县",
            id: 2971,
            type: 3
        }, {fid: 348, name: "巴青县", id: 2972, type: 3}, {fid: 348, name: "尼玛县", id: 2973, type: 3}],
        name: "那曲",
        id: 348,
        type: 2
    }, {
        fid: 28,
        children: [{fid: 349, name: "日喀则市", id: 2974, type: 3}, {fid: 349, name: "南木林县", id: 2975, type: 3}, {fid: 349, name: "江孜县", id: 2976, type: 3}, {
            fid: 349,
            name: "定日县",
            id: 2977,
            type: 3
        }, {fid: 349, name: "萨迦县", id: 2978, type: 3}, {fid: 349, name: "拉孜县", id: 2979, type: 3}, {fid: 349, name: "昂仁县", id: 2980, type: 3}, {
            fid: 349,
            name: "谢通门县",
            id: 2981,
            type: 3
        }, {fid: 349, name: "白朗县", id: 2982, type: 3}, {fid: 349, name: "仁布县", id: 2983, type: 3}, {fid: 349, name: "康马县", id: 2984, type: 3}, {
            fid: 349,
            name: "定结县",
            id: 2985,
            type: 3
        }, {fid: 349, name: "仲巴县", id: 2986, type: 3}, {fid: 349, name: "亚东县", id: 2987, type: 3}, {fid: 349, name: "吉隆县", id: 2988, type: 3}, {
            fid: 349,
            name: "聂拉木县",
            id: 2989,
            type: 3
        }, {fid: 349, name: "萨嘎县", id: 2990, type: 3}, {fid: 349, name: "岗巴县", id: 2991, type: 3}],
        name: "日喀则",
        id: 349,
        type: 2
    }, {
        fid: 28,
        children: [{fid: 350, name: "乃东县", id: 2992, type: 3}, {fid: 350, name: "扎囊县", id: 2993, type: 3}, {fid: 350, name: "贡嘎县", id: 2994, type: 3}, {
            fid: 350,
            name: "桑日县",
            id: 2995,
            type: 3
        }, {fid: 350, name: "琼结县", id: 2996, type: 3}, {fid: 350, name: "曲松县", id: 2997, type: 3}, {fid: 350, name: "措美县", id: 2998, type: 3}, {
            fid: 350,
            name: "洛扎县",
            id: 2999,
            type: 3
        }, {fid: 350, name: "加查县", id: 3000, type: 3}, {fid: 350, name: "隆子县", id: 3001, type: 3}, {fid: 350, name: "错那县", id: 3002, type: 3}, {
            fid: 350,
            name: "浪卡子县",
            id: 3003,
            type: 3
        }],
        name: "山南",
        id: 350,
        type: 2
    }],
    name: "西藏",
    id: 28,
    type: 1
}, {
    fid: 1,
    children: [{
        fid: 29,
        children: [{fid: 351, name: "天山区", id: 3004, type: 3}, {fid: 351, name: "沙依巴克区", id: 3005, type: 3}, {fid: 351, name: "新市区", id: 3006, type: 3}, {
            fid: 351,
            name: "水磨沟区",
            id: 3007,
            type: 3
        }, {fid: 351, name: "头屯河区", id: 3008, type: 3}, {fid: 351, name: "达坂城区", id: 3009, type: 3}, {fid: 351, name: "米东区", id: 3010, type: 3}, {
            fid: 351,
            name: "乌鲁木齐县",
            id: 3011,
            type: 3
        }],
        name: "乌鲁木齐",
        id: 351,
        type: 2
    }, {
        fid: 29,
        children: [{fid: 352, name: "阿克苏市", id: 3012, type: 3}, {fid: 352, name: "温宿县", id: 3013, type: 3}, {fid: 352, name: "库车县", id: 3014, type: 3}, {
            fid: 352,
            name: "沙雅县",
            id: 3015,
            type: 3
        }, {fid: 352, name: "新和县", id: 3016, type: 3}, {fid: 352, name: "拜城县", id: 3017, type: 3}, {fid: 352, name: "乌什县", id: 3018, type: 3}, {
            fid: 352,
            name: "阿瓦提县",
            id: 3019,
            type: 3
        }, {fid: 352, name: "柯坪县", id: 3020, type: 3}],
        name: "阿克苏",
        id: 352,
        type: 2
    }, {fid: 29, children: [{fid: 353, name: "阿拉尔市", id: 3021, type: 3}], name: "阿拉尔", id: 353, type: 2}, {
        fid: 29,
        children: [{fid: 354, name: "库尔勒市", id: 3022, type: 3}, {fid: 354, name: "轮台县", id: 3023, type: 3}, {fid: 354, name: "尉犁县", id: 3024, type: 3}, {
            fid: 354,
            name: "若羌县",
            id: 3025,
            type: 3
        }, {fid: 354, name: "且末县", id: 3026, type: 3}, {fid: 354, name: "焉耆", id: 3027, type: 3}, {fid: 354, name: "和静县", id: 3028, type: 3}, {
            fid: 354,
            name: "和硕县",
            id: 3029,
            type: 3
        }, {fid: 354, name: "博湖县", id: 3030, type: 3}],
        name: "巴音郭楞",
        id: 354,
        type: 2
    }, {
        fid: 29,
        children: [{fid: 355, name: "博乐市", id: 3031, type: 3}, {fid: 355, name: "精河县", id: 3032, type: 3}, {fid: 355, name: "温泉县", id: 3033, type: 3}],
        name: "博尔塔拉",
        id: 355,
        type: 2
    }, {
        fid: 29,
        children: [{fid: 356, name: "呼图壁县", id: 3034, type: 3}, {fid: 356, name: "米泉市", id: 3035, type: 3}, {fid: 356, name: "昌吉市", id: 3036, type: 3}, {
            fid: 356,
            name: "阜康市",
            id: 3037,
            type: 3
        }, {fid: 356, name: "玛纳斯县", id: 3038, type: 3}, {fid: 356, name: "奇台县", id: 3039, type: 3}, {fid: 356, name: "吉木萨尔县", id: 3040, type: 3}, {
            fid: 356,
            name: "木垒",
            id: 3041,
            type: 3
        }],
        name: "昌吉",
        id: 356,
        type: 2
    }, {
        fid: 29,
        children: [{fid: 357, name: "哈密市", id: 3042, type: 3}, {fid: 357, name: "伊吾县", id: 3043, type: 3}, {fid: 357, name: "巴里坤", id: 3044, type: 3}],
        name: "哈密",
        id: 357,
        type: 2
    }, {
        fid: 29,
        children: [{fid: 358, name: "和田市", id: 3045, type: 3}, {fid: 358, name: "和田县", id: 3046, type: 3}, {fid: 358, name: "墨玉县", id: 3047, type: 3}, {
            fid: 358,
            name: "皮山县",
            id: 3048,
            type: 3
        }, {fid: 358, name: "洛浦县", id: 3049, type: 3}, {fid: 358, name: "策勒县", id: 3050, type: 3}, {fid: 358, name: "于田县", id: 3051, type: 3}, {
            fid: 358,
            name: "民丰县",
            id: 3052,
            type: 3
        }],
        name: "和田",
        id: 358,
        type: 2
    }, {
        fid: 29,
        children: [{fid: 359, name: "喀什市", id: 3053, type: 3}, {fid: 359, name: "疏附县", id: 3054, type: 3}, {fid: 359, name: "疏勒县", id: 3055, type: 3}, {
            fid: 359,
            name: "英吉沙县",
            id: 3056,
            type: 3
        }, {fid: 359, name: "泽普县", id: 3057, type: 3}, {fid: 359, name: "莎车县", id: 3058, type: 3}, {fid: 359, name: "叶城县", id: 3059, type: 3}, {
            fid: 359,
            name: "麦盖提县",
            id: 3060,
            type: 3
        }, {fid: 359, name: "岳普湖县", id: 3061, type: 3}, {fid: 359, name: "伽师县", id: 3062, type: 3}, {fid: 359, name: "巴楚县", id: 3063, type: 3}, {
            fid: 359,
            name: "塔什库尔干",
            id: 3064,
            type: 3
        }],
        name: "喀什",
        id: 359,
        type: 2
    }, {fid: 29, children: [{fid: 360, name: "克拉玛依市", id: 3065, type: 3}], name: "克拉玛依", id: 360, type: 2}, {
        fid: 29,
        children: [{fid: 361, name: "阿图什市", id: 3066, type: 3}, {fid: 361, name: "阿克陶县", id: 3067, type: 3}, {fid: 361, name: "阿合奇县", id: 3068, type: 3}, {
            fid: 361,
            name: "乌恰县",
            id: 3069,
            type: 3
        }],
        name: "克孜勒苏",
        id: 361,
        type: 2
    }, {fid: 29, children: [{fid: 362, name: "石河子市", id: 3070, type: 3}], name: "石河子", id: 362, type: 2}, {
        fid: 29,
        children: [{fid: 363, name: "图木舒克市", id: 3071, type: 3}],
        name: "图木舒克",
        id: 363,
        type: 2
    }, {
        fid: 29,
        children: [{fid: 364, name: "吐鲁番市", id: 3072, type: 3}, {fid: 364, name: "鄯善县", id: 3073, type: 3}, {fid: 364, name: "托克逊县", id: 3074, type: 3}],
        name: "吐鲁番",
        id: 364,
        type: 2
    }, {fid: 29, children: [{fid: 365, name: "五家渠市", id: 3075, type: 3}], name: "五家渠", id: 365, type: 2}, {
        fid: 29,
        children: [{fid: 366, name: "阿勒泰市", id: 3076, type: 3}, {fid: 366, name: "布克赛尔", id: 3077, type: 3}, {fid: 366, name: "伊宁市", id: 3078, type: 3}, {
            fid: 366,
            name: "布尔津县",
            id: 3079,
            type: 3
        }, {fid: 366, name: "奎屯市", id: 3080, type: 3}, {fid: 366, name: "乌苏市", id: 3081, type: 3}, {fid: 366, name: "额敏县", id: 3082, type: 3}, {
            fid: 366,
            name: "富蕴县",
            id: 3083,
            type: 3
        }, {fid: 366, name: "伊宁县", id: 3084, type: 3}, {fid: 366, name: "福海县", id: 3085, type: 3}, {fid: 366, name: "霍城县", id: 3086, type: 3}, {
            fid: 366,
            name: "沙湾县",
            id: 3087,
            type: 3
        }, {fid: 366, name: "巩留县", id: 3088, type: 3}, {fid: 366, name: "哈巴河县", id: 3089, type: 3}, {fid: 366, name: "托里县", id: 3090, type: 3}, {
            fid: 366,
            name: "青河县",
            id: 3091,
            type: 3
        }, {fid: 366, name: "新源县", id: 3092, type: 3}, {fid: 366, name: "裕民县", id: 3093, type: 3}, {fid: 366, name: "和布克赛尔", id: 3094, type: 3}, {
            fid: 366,
            name: "吉木乃县",
            id: 3095,
            type: 3
        }, {fid: 366, name: "昭苏县", id: 3096, type: 3}, {fid: 366, name: "特克斯县", id: 3097, type: 3}, {fid: 366, name: "尼勒克县", id: 3098, type: 3}, {
            fid: 366,
            name: "察布查尔",
            id: 3099,
            type: 3
        }],
        name: "伊犁",
        id: 366,
        type: 2
    }],
    name: "新疆",
    id: 29,
    type: 1
}, {
    fid: 1,
    children: [{
        fid: 30,
        children: [{fid: 367, name: "盘龙区", id: 3100, type: 3}, {fid: 367, name: "五华区", id: 3101, type: 3}, {fid: 367, name: "官渡区", id: 3102, type: 3}, {
            fid: 367,
            name: "西山区",
            id: 3103,
            type: 3
        }, {fid: 367, name: "东川区", id: 3104, type: 3}, {fid: 367, name: "安宁市", id: 3105, type: 3}, {fid: 367, name: "呈贡县", id: 3106, type: 3}, {
            fid: 367,
            name: "晋宁县",
            id: 3107,
            type: 3
        }, {fid: 367, name: "富民县", id: 3108, type: 3}, {fid: 367, name: "宜良县", id: 3109, type: 3}, {fid: 367, name: "嵩明县", id: 3110, type: 3}, {
            fid: 367,
            name: "石林县",
            id: 3111,
            type: 3
        }, {fid: 367, name: "禄劝", id: 3112, type: 3}, {fid: 367, name: "寻甸", id: 3113, type: 3}],
        name: "昆明",
        id: 367,
        type: 2
    }, {
        fid: 30,
        children: [{fid: 368, name: "兰坪", id: 3114, type: 3}, {fid: 368, name: "泸水县", id: 3115, type: 3}, {fid: 368, name: "福贡县", id: 3116, type: 3}, {
            fid: 368,
            name: "贡山",
            id: 3117,
            type: 3
        }],
        name: "怒江",
        id: 368,
        type: 2
    }, {
        fid: 30,
        children: [{fid: 369, name: "宁洱", id: 3118, type: 3}, {fid: 369, name: "思茅区", id: 3119, type: 3}, {fid: 369, name: "墨江", id: 3120, type: 3}, {
            fid: 369,
            name: "景东",
            id: 3121,
            type: 3
        }, {fid: 369, name: "景谷", id: 3122, type: 3}, {fid: 369, name: "镇沅", id: 3123, type: 3}, {fid: 369, name: "江城", id: 3124, type: 3}, {
            fid: 369,
            name: "孟连",
            id: 3125,
            type: 3
        }, {fid: 369, name: "澜沧", id: 3126, type: 3}, {fid: 369, name: "西盟", id: 3127, type: 3}],
        name: "普洱",
        id: 369,
        type: 2
    }, {
        fid: 30,
        children: [{fid: 370, name: "古城区", id: 3128, type: 3}, {fid: 370, name: "宁蒗", id: 3129, type: 3}, {fid: 370, name: "玉龙", id: 3130, type: 3}, {
            fid: 370,
            name: "永胜县",
            id: 3131,
            type: 3
        }, {fid: 370, name: "华坪县", id: 3132, type: 3}],
        name: "丽江",
        id: 370,
        type: 2
    }, {
        fid: 30,
        children: [{fid: 371, name: "隆阳区", id: 3133, type: 3}, {fid: 371, name: "施甸县", id: 3134, type: 3}, {fid: 371, name: "腾冲县", id: 3135, type: 3}, {
            fid: 371,
            name: "龙陵县",
            id: 3136,
            type: 3
        }, {fid: 371, name: "昌宁县", id: 3137, type: 3}],
        name: "保山",
        id: 371,
        type: 2
    }, {
        fid: 30,
        children: [{fid: 372, name: "楚雄市", id: 3138, type: 3}, {fid: 372, name: "双柏县", id: 3139, type: 3}, {fid: 372, name: "牟定县", id: 3140, type: 3}, {
            fid: 372,
            name: "南华县",
            id: 3141,
            type: 3
        }, {fid: 372, name: "姚安县", id: 3142, type: 3}, {fid: 372, name: "大姚县", id: 3143, type: 3}, {fid: 372, name: "永仁县", id: 3144, type: 3}, {
            fid: 372,
            name: "元谋县",
            id: 3145,
            type: 3
        }, {fid: 372, name: "武定县", id: 3146, type: 3}, {fid: 372, name: "禄丰县", id: 3147, type: 3}],
        name: "楚雄",
        id: 372,
        type: 2
    }, {
        fid: 30,
        children: [{fid: 373, name: "大理市", id: 3148, type: 3}, {fid: 373, name: "祥云县", id: 3149, type: 3}, {fid: 373, name: "宾川县", id: 3150, type: 3}, {
            fid: 373,
            name: "弥渡县",
            id: 3151,
            type: 3
        }, {fid: 373, name: "永平县", id: 3152, type: 3}, {fid: 373, name: "云龙县", id: 3153, type: 3}, {fid: 373, name: "洱源县", id: 3154, type: 3}, {
            fid: 373,
            name: "剑川县",
            id: 3155,
            type: 3
        }, {fid: 373, name: "鹤庆县", id: 3156, type: 3}, {fid: 373, name: "漾濞", id: 3157, type: 3}, {fid: 373, name: "南涧", id: 3158, type: 3}, {
            fid: 373,
            name: "巍山",
            id: 3159,
            type: 3
        }],
        name: "大理",
        id: 373,
        type: 2
    }, {
        fid: 30,
        children: [{fid: 374, name: "潞西市", id: 3160, type: 3}, {fid: 374, name: "瑞丽市", id: 3161, type: 3}, {fid: 374, name: "梁河县", id: 3162, type: 3}, {
            fid: 374,
            name: "盈江县",
            id: 3163,
            type: 3
        }, {fid: 374, name: "陇川县", id: 3164, type: 3}],
        name: "德宏",
        id: 374,
        type: 2
    }, {
        fid: 30,
        children: [{fid: 375, name: "香格里拉县", id: 3165, type: 3}, {fid: 375, name: "德钦县", id: 3166, type: 3}, {fid: 375, name: "维西", id: 3167, type: 3}],
        name: "迪庆",
        id: 375,
        type: 2
    }, {
        fid: 30,
        children: [{fid: 376, name: "泸西县", id: 3168, type: 3}, {fid: 376, name: "蒙自县", id: 3169, type: 3}, {fid: 376, name: "个旧市", id: 3170, type: 3}, {
            fid: 376,
            name: "开远市",
            id: 3171,
            type: 3
        }, {fid: 376, name: "绿春县", id: 3172, type: 3}, {fid: 376, name: "建水县", id: 3173, type: 3}, {fid: 376, name: "石屏县", id: 3174, type: 3}, {
            fid: 376,
            name: "弥勒县",
            id: 3175,
            type: 3
        }, {fid: 376, name: "元阳县", id: 3176, type: 3}, {fid: 376, name: "红河县", id: 3177, type: 3}, {fid: 376, name: "金平", id: 3178, type: 3}, {
            fid: 376,
            name: "河口",
            id: 3179,
            type: 3
        }, {fid: 376, name: "屏边", id: 3180, type: 3}],
        name: "红河",
        id: 376,
        type: 2
    }, {
        fid: 30,
        children: [{fid: 377, name: "临翔区", id: 3181, type: 3}, {fid: 377, name: "凤庆县", id: 3182, type: 3}, {fid: 377, name: "云县", id: 3183, type: 3}, {
            fid: 377,
            name: "永德县",
            id: 3184,
            type: 3
        }, {fid: 377, name: "镇康县", id: 3185, type: 3}, {fid: 377, name: "双江", id: 3186, type: 3}, {fid: 377, name: "耿马", id: 3187, type: 3}, {
            fid: 377,
            name: "沧源",
            id: 3188,
            type: 3
        }],
        name: "临沧",
        id: 377,
        type: 2
    }, {
        fid: 30,
        children: [{fid: 378, name: "麒麟区", id: 3189, type: 3}, {fid: 378, name: "宣威市", id: 3190, type: 3}, {fid: 378, name: "马龙县", id: 3191, type: 3}, {
            fid: 378,
            name: "陆良县",
            id: 3192,
            type: 3
        }, {fid: 378, name: "师宗县", id: 3193, type: 3}, {fid: 378, name: "罗平县", id: 3194, type: 3}, {fid: 378, name: "富源县", id: 3195, type: 3}, {
            fid: 378,
            name: "会泽县",
            id: 3196,
            type: 3
        }, {fid: 378, name: "沾益县", id: 3197, type: 3}],
        name: "曲靖",
        id: 378,
        type: 2
    }, {
        fid: 30,
        children: [{fid: 379, name: "文山县", id: 3198, type: 3}, {fid: 379, name: "砚山县", id: 3199, type: 3}, {fid: 379, name: "西畴县", id: 3200, type: 3}, {
            fid: 379,
            name: "麻栗坡县",
            id: 3201,
            type: 3
        }, {fid: 379, name: "马关县", id: 3202, type: 3}, {fid: 379, name: "丘北县", id: 3203, type: 3}, {fid: 379, name: "广南县", id: 3204, type: 3}, {
            fid: 379,
            name: "富宁县",
            id: 3205,
            type: 3
        }],
        name: "文山",
        id: 379,
        type: 2
    }, {
        fid: 30,
        children: [{fid: 380, name: "景洪市", id: 3206, type: 3}, {fid: 380, name: "勐海县", id: 3207, type: 3}, {fid: 380, name: "勐腊县", id: 3208, type: 3}],
        name: "西双版纳",
        id: 380,
        type: 2
    }, {
        fid: 30,
        children: [{fid: 381, name: "红塔区", id: 3209, type: 3}, {fid: 381, name: "江川县", id: 3210, type: 3}, {fid: 381, name: "澄江县", id: 3211, type: 3}, {
            fid: 381,
            name: "通海县",
            id: 3212,
            type: 3
        }, {fid: 381, name: "华宁县", id: 3213, type: 3}, {fid: 381, name: "易门县", id: 3214, type: 3}, {fid: 381, name: "峨山", id: 3215, type: 3}, {
            fid: 381,
            name: "新平",
            id: 3216,
            type: 3
        }, {fid: 381, name: "元江", id: 3217, type: 3}],
        name: "玉溪",
        id: 381,
        type: 2
    }, {
        fid: 30,
        children: [{fid: 382, name: "昭阳区", id: 3218, type: 3}, {fid: 382, name: "鲁甸县", id: 3219, type: 3}, {fid: 382, name: "巧家县", id: 3220, type: 3}, {
            fid: 382,
            name: "盐津县",
            id: 3221,
            type: 3
        }, {fid: 382, name: "大关县", id: 3222, type: 3}, {fid: 382, name: "永善县", id: 3223, type: 3}, {fid: 382, name: "绥江县", id: 3224, type: 3}, {
            fid: 382,
            name: "镇雄县",
            id: 3225,
            type: 3
        }, {fid: 382, name: "彝良县", id: 3226, type: 3}, {fid: 382, name: "威信县", id: 3227, type: 3}, {fid: 382, name: "水富县", id: 3228, type: 3}],
        name: "昭通",
        id: 382,
        type: 2
    }],
    name: "云南",
    id: 30,
    type: 1
}, {
    fid: 1,
    children: [{
        fid: 31,
        children: [{fid: 383, name: "西湖区", id: 3229, type: 3}, {fid: 383, name: "上城区", id: 3230, type: 3}, {fid: 383, name: "下城区", id: 3231, type: 3}, {
            fid: 383,
            name: "拱墅区",
            id: 3232,
            type: 3
        }, {fid: 383, name: "滨江区", id: 3233, type: 3}, {fid: 383, name: "江干区", id: 3234, type: 3}, {fid: 383, name: "萧山区", id: 3235, type: 3}, {
            fid: 383,
            name: "余杭区",
            id: 3236,
            type: 3
        }, {fid: 383, name: "市郊", id: 3237, type: 3}, {fid: 383, name: "建德市", id: 3238, type: 3}, {fid: 383, name: "富阳市", id: 3239, type: 3}, {
            fid: 383,
            name: "临安市",
            id: 3240,
            type: 3
        }, {fid: 383, name: "桐庐县", id: 3241, type: 3}, {fid: 383, name: "淳安县", id: 3242, type: 3}],
        name: "杭州",
        id: 383,
        type: 2
    }, {
        fid: 31,
        children: [{fid: 384, name: "吴兴区", id: 3243, type: 3}, {fid: 384, name: "南浔区", id: 3244, type: 3}, {fid: 384, name: "德清县", id: 3245, type: 3}, {
            fid: 384,
            name: "长兴县",
            id: 3246,
            type: 3
        }, {fid: 384, name: "安吉县", id: 3247, type: 3}],
        name: "湖州",
        id: 384,
        type: 2
    }, {
        fid: 31,
        children: [{fid: 385, name: "南湖区", id: 3248, type: 3}, {fid: 385, name: "秀洲区", id: 3249, type: 3}, {fid: 385, name: "海宁市", id: 3250, type: 3}, {
            fid: 385,
            name: "嘉善县",
            id: 3251,
            type: 3
        }, {fid: 385, name: "平湖市", id: 3252, type: 3}, {fid: 385, name: "桐乡市", id: 3253, type: 3}, {fid: 385, name: "海盐县", id: 3254, type: 3}],
        name: "嘉兴",
        id: 385,
        type: 2
    }, {
        fid: 31,
        children: [{fid: 386, name: "婺城区", id: 3255, type: 3}, {fid: 386, name: "金东区", id: 3256, type: 3}, {fid: 386, name: "兰溪市", id: 3257, type: 3}, {
            fid: 386,
            name: "市区",
            id: 3258,
            type: 3
        }, {fid: 386, name: "佛堂镇", id: 3259, type: 3}, {fid: 386, name: "上溪镇", id: 3260, type: 3}, {fid: 386, name: "义亭镇", id: 3261, type: 3}, {
            fid: 386,
            name: "大陈镇",
            id: 3262,
            type: 3
        }, {fid: 386, name: "苏溪镇", id: 3263, type: 3}, {fid: 386, name: "赤岸镇", id: 3264, type: 3}, {fid: 386, name: "东阳市", id: 3265, type: 3}, {
            fid: 386,
            name: "永康市",
            id: 3266,
            type: 3
        }, {fid: 386, name: "武义县", id: 3267, type: 3}, {fid: 386, name: "浦江县", id: 3268, type: 3}, {fid: 386, name: "磐安县", id: 3269, type: 3}],
        name: "金华",
        id: 386,
        type: 2
    }, {
        fid: 31,
        children: [{fid: 387, name: "莲都区", id: 3270, type: 3}, {fid: 387, name: "龙泉市", id: 3271, type: 3}, {fid: 387, name: "青田县", id: 3272, type: 3}, {
            fid: 387,
            name: "缙云县",
            id: 3273,
            type: 3
        }, {fid: 387, name: "遂昌县", id: 3274, type: 3}, {fid: 387, name: "松阳县", id: 3275, type: 3}, {fid: 387, name: "云和县", id: 3276, type: 3}, {
            fid: 387,
            name: "庆元县",
            id: 3277,
            type: 3
        }, {fid: 387, name: "景宁", id: 3278, type: 3}],
        name: "丽水",
        id: 387,
        type: 2
    }, {
        fid: 31,
        children: [{fid: 388, name: "海曙区", id: 3279, type: 3}, {fid: 388, name: "江东区", id: 3280, type: 3}, {fid: 388, name: "江北区", id: 3281, type: 3}, {
            fid: 388,
            name: "镇海区",
            id: 3282,
            type: 3
        }, {fid: 388, name: "北仑区", id: 3283, type: 3}, {fid: 388, name: "鄞州区", id: 3284, type: 3}, {fid: 388, name: "余姚市", id: 3285, type: 3}, {
            fid: 388,
            name: "慈溪市",
            id: 3286,
            type: 3
        }, {fid: 388, name: "奉化市", id: 3287, type: 3}, {fid: 388, name: "象山县", id: 3288, type: 3}, {fid: 388, name: "宁海县", id: 3289, type: 3}],
        name: "宁波",
        id: 388,
        type: 2
    }, {
        fid: 31,
        children: [{fid: 389, name: "越城区", id: 3290, type: 3}, {fid: 389, name: "上虞市", id: 3291, type: 3}, {fid: 389, name: "嵊州市", id: 3292, type: 3}, {
            fid: 389,
            name: "绍兴县",
            id: 3293,
            type: 3
        }, {fid: 389, name: "新昌县", id: 3294, type: 3}, {fid: 389, name: "诸暨市", id: 3295, type: 3}],
        name: "绍兴",
        id: 389,
        type: 2
    }, {
        fid: 31,
        children: [{fid: 390, name: "椒江区", id: 3296, type: 3}, {fid: 390, name: "黄岩区", id: 3297, type: 3}, {fid: 390, name: "路桥区", id: 3298, type: 3}, {
            fid: 390,
            name: "温岭市",
            id: 3299,
            type: 3
        }, {fid: 390, name: "临海市", id: 3300, type: 3}, {fid: 390, name: "玉环县", id: 3301, type: 3}, {fid: 390, name: "三门县", id: 3302, type: 3}, {
            fid: 390,
            name: "天台县",
            id: 3303,
            type: 3
        }, {fid: 390, name: "仙居县", id: 3304, type: 3}],
        name: "台州",
        id: 390,
        type: 2
    }, {
        fid: 31,
        children: [{fid: 391, name: "鹿城区", id: 3305, type: 3}, {fid: 391, name: "龙湾区", id: 3306, type: 3}, {fid: 391, name: "瓯海区", id: 3307, type: 3}, {
            fid: 391,
            name: "瑞安市",
            id: 3308,
            type: 3
        }, {fid: 391, name: "乐清市", id: 3309, type: 3}, {fid: 391, name: "洞头县", id: 3310, type: 3}, {fid: 391, name: "永嘉县", id: 3311, type: 3}, {
            fid: 391,
            name: "平阳县",
            id: 3312,
            type: 3
        }, {fid: 391, name: "苍南县", id: 3313, type: 3}, {fid: 391, name: "文成县", id: 3314, type: 3}, {fid: 391, name: "泰顺县", id: 3315, type: 3}],
        name: "温州",
        id: 391,
        type: 2
    }, {
        fid: 31,
        children: [{fid: 392, name: "定海区", id: 3316, type: 3}, {fid: 392, name: "普陀区", id: 3317, type: 3}, {fid: 392, name: "岱山县", id: 3318, type: 3}, {
            fid: 392,
            name: "嵊泗县",
            id: 3319,
            type: 3
        }],
        name: "舟山",
        id: 392,
        type: 2
    }, {
        fid: 31,
        children: [{fid: 393, name: "衢州市", id: 3320, type: 3}, {fid: 393, name: "江山市", id: 3321, type: 3}, {fid: 393, name: "常山县", id: 3322, type: 3}, {
            fid: 393,
            name: "开化县",
            id: 3323,
            type: 3
        }, {fid: 393, name: "龙游县", id: 3324, type: 3}],
        name: "衢州",
        id: 393,
        type: 2
    }],
    name: "浙江",
    id: 31,
    type: 1
}, {
    fid: 1, children: [{
        fid: 32,
        children: [{fid: 394, name: "合川区", id: 3325, type: 3}, {fid: 394, name: "江津区", id: 3326, type: 3}, {fid: 394, name: "南川区", id: 3327, type: 3}, {
            fid: 394,
            name: "永川区",
            id: 3328,
            type: 3
        }, {fid: 394, name: "南岸区", id: 3329, type: 3}, {fid: 394, name: "渝北区", id: 3330, type: 3}, {fid: 394, name: "万盛区", id: 3331, type: 3}, {
            fid: 394,
            name: "大渡口区",
            id: 3332,
            type: 3
        }, {fid: 394, name: "万州区", id: 3333, type: 3}, {fid: 394, name: "北碚区", id: 3334, type: 3}, {fid: 394, name: "沙坪坝区", id: 3335, type: 3}, {
            fid: 394,
            name: "巴南区",
            id: 3336,
            type: 3
        }, {fid: 394, name: "涪陵区", id: 3337, type: 3}, {fid: 394, name: "江北区", id: 3338, type: 3}, {fid: 394, name: "九龙坡区", id: 3339, type: 3}, {
            fid: 394,
            name: "渝中区",
            id: 3340,
            type: 3
        }, {fid: 394, name: "黔江开发区", id: 3341, type: 3}, {fid: 394, name: "长寿区", id: 3342, type: 3}, {fid: 394, name: "双桥区", id: 3343, type: 3}, {
            fid: 394,
            name: "綦江县",
            id: 3344,
            type: 3
        }, {fid: 394, name: "潼南县", id: 3345, type: 3}, {fid: 394, name: "铜梁县", id: 3346, type: 3}, {fid: 394, name: "大足县", id: 3347, type: 3}, {
            fid: 394,
            name: "荣昌县",
            id: 3348,
            type: 3
        }, {fid: 394, name: "璧山县", id: 3349, type: 3}, {fid: 394, name: "垫江县", id: 3350, type: 3}, {fid: 394, name: "武隆县", id: 3351, type: 3}, {
            fid: 394,
            name: "丰都县",
            id: 3352,
            type: 3
        }, {fid: 394, name: "城口县", id: 3353, type: 3}, {fid: 394, name: "梁平县", id: 3354, type: 3}, {fid: 394, name: "开县", id: 3355, type: 3}, {
            fid: 394,
            name: "巫溪县",
            id: 3356,
            type: 3
        }, {fid: 394, name: "巫山县", id: 3357, type: 3}, {fid: 394, name: "奉节县", id: 3358, type: 3}, {fid: 394, name: "云阳县", id: 3359, type: 3}, {
            fid: 394,
            name: "忠县",
            id: 3360,
            type: 3
        }, {fid: 394, name: "石柱", id: 3361, type: 3}, {fid: 394, name: "彭水", id: 3362, type: 3}, {fid: 394, name: "酉阳", id: 3363, type: 3}, {
            fid: 394,
            name: "秀山",
            id: 3364,
            type: 3
        }],
        name: "重庆",
        id: 394,
        type: 2
    }], name: "重庆", id: 32, type: 1
}, {
    fid: 1,
    children: [{
        fid: 33,
        children: [{fid: 395, name: "沙田区", id: 3365, type: 3}, {fid: 395, name: "东区", id: 3366, type: 3}, {fid: 395, name: "观塘区", id: 3367, type: 3}, {
            fid: 395,
            name: "黄大仙区",
            id: 3368,
            type: 3
        }, {fid: 395, name: "九龙城区", id: 3369, type: 3}, {fid: 395, name: "屯门区", id: 3370, type: 3}, {fid: 395, name: "葵青区", id: 3371, type: 3}, {
            fid: 395,
            name: "元朗区",
            id: 3372,
            type: 3
        }, {fid: 395, name: "深水埗区", id: 3373, type: 3}, {fid: 395, name: "西贡区", id: 3374, type: 3}, {fid: 395, name: "大埔区", id: 3375, type: 3}, {
            fid: 395,
            name: "湾仔区",
            id: 3376,
            type: 3
        }, {fid: 395, name: "油尖旺区", id: 3377, type: 3}, {fid: 395, name: "北区", id: 3378, type: 3}, {fid: 395, name: "南区", id: 3379, type: 3}, {
            fid: 395,
            name: "荃湾区",
            id: 3380,
            type: 3
        }, {fid: 395, name: "中西区", id: 3381, type: 3}, {fid: 395, name: "离岛区", id: 3382, type: 3}],
        name: "香港",
        id: 395,
        type: 2
    }],
    name: "香港",
    id: 33,
    type: 1
}, {fid: 1, children: [{fid: 34, children: [{fid: 396, name: "澳门", id: 3383, type: 3}], name: "澳门", id: 396, type: 2}], name: "澳门", id: 34, type: 1}, {
    fid: 1,
    children: [{
        fid: 35,
        children: [{fid: 397, name: "台北", id: 3384, type: 3}, {fid: 397, name: "高雄", id: 3385, type: 3}, {fid: 397, name: "基隆", id: 3386, type: 3}, {
            fid: 397,
            name: "台中",
            id: 3387,
            type: 3
        }, {fid: 397, name: "台南", id: 3388, type: 3}, {fid: 397, name: "新竹", id: 3389, type: 3}, {fid: 397, name: "嘉义", id: 3390, type: 3}, {
            fid: 397,
            name: "宜兰县",
            id: 3391,
            type: 3
        }, {fid: 397, name: "桃园县", id: 3392, type: 3}, {fid: 397, name: "苗栗县", id: 3393, type: 3}, {fid: 397, name: "彰化县", id: 3394, type: 3}, {
            fid: 397,
            name: "南投县",
            id: 3395,
            type: 3
        }, {fid: 397, name: "云林县", id: 3396, type: 3}, {fid: 397, name: "屏东县", id: 3397, type: 3}, {fid: 397, name: "台东县", id: 3398, type: 3}, {
            fid: 397,
            name: "花莲县",
            id: 3399,
            type: 3
        }, {fid: 397, name: "澎湖县", id: 3400, type: 3}],
        name: "台湾",
        id: 397,
        type: 2
    }],
    name: "台湾",
    id: 35,
    type: 1
}]

export default addressData;
